import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { useTranslation } from "react-i18next";
import { IAccessPermission, IPermissionContext } from "types/Auth/role";

export const usePurchaseReturnOption = (
  status?: string | null,
  disabled?: boolean,
  purchaseReturnPermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        purchaseReturnPermissions?.update && {
          value: "แก้ไข",
          disabled: !disabled || false,
        },
        purchaseReturnPermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
      ] as IMenuOption[],
    [disabled, purchaseReturnPermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "wait_returned":
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
    }
  }, [status]);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // ยกเลิก, คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, status]);

  return { selectModifyOptions, showOptions };
};

export const usePurchaseReturnCreate = (
  status?: string | null,
  isMaxQty?: boolean,
  permissions?: IPermissionContext | null
) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        permissions?.goods_receive?.create && {
          value: t("inventory.goods_issue.index"),
          disabled: isMaxQty,
        },
      ] as IMenuOption[],
    [isMaxQty, permissions, t]
  );

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "finished":
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
    }
  }, [status]);

  useEffect(() => {
    switch (status) {
      default:
        setOptions(defaultOptions.filter(Boolean));
    }
  }, [defaultOptions, status]);

  return {
    selectCreateOptions: options,
    showCreateOptions: showOptions,
  };
};
