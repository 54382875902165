import { Grid, IconButton, Typography, Box, InputLabel } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  Controller,
  UseFieldArrayReplace,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useState, useCallback, useEffect } from "react";
import { ISelectOption } from "types/global";
import { IGoodsIssue } from "types/Inventory/goodsIssue";
import { ITraceEntry } from "types/Inventory";
import ControlledSelect from "components/Controller/ControlledSelect";
import useInventoryControl from "hooks/Inventory/use-inventory-control";
import CustomizedComboBox from "components/Custom/CustomizedComboBox";
import { useParams } from "react-router-dom";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";
import LabelInput from "components/UI/LabelInput";
import { goodsIssueTypeValueFormatter } from "utils/Formatter/Inventory/GoodsIssue";
import {
  InventoryModelType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "generated/wms";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useConfirmation } from "hooks/use-confirmation";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { PURCHASE_RETURN_VIEW } from "services/AgGrid/PurchaseAgGrid";
import { formatPurchaseItemListToGoodsIssueItemList } from "utils/Formatter/Purchase/PurchaseReturn";
import CustomizedChips from "components/Custom/CustomizedChips";
import { useDisable } from "hooks/use-disable";

const typeOptions: ISelectOption[] = [
  {
    label: "แจ้งซ่อม",
    value: "work_order",
  },
  {
    label: "ส่งคืน",
    value: "return",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

type Props = {
  replace: UseFieldArrayReplace<IGoodsIssue, "trace_entry_list">;
};

const GoodsIssueInfo = ({ replace }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const [disabled] = useDisable();

  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");
  const graphqlClientPurchase = createGraphQLClientWithMiddleware("purchase");

  const [currentType, setCurrentType] = useState<string>("");
  const [currentWarehouse, setCurrentWarehouse] = useState<number>();
  const [tempType, setTempType] = useState<string>("");
  const [tempWarehouse, setTempWarehouse] = useState<number>();

  const watchType = useWatch({
    control,
    name: "type",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { warehousesOptions, renderWarehousesOptions, purchaseReturnOptions } =
    useInventoryControl(undefined, watchType);

  const onTypeChangeHandler = () => {
    replace([]);
    setValue("type", currentType);
    setValue("reference_unique_id", "");
  };

  const onWarehouseChangeHandler = () => {
    replace([]);
    setValue("source_warehouse_id", currentWarehouse);
  };

  const {
    confirmation: typeConfirmation,
    openConfirmationHandler: openTypeConfirmationHandler,
    closeConfirmationHandler: closeTypeConfirmationHandler,
    submitConfirmationHandler: submitTypeConfirmationHandler,
  } = useConfirmation(onTypeChangeHandler);

  const {
    confirmation: warehouseConfirmation,
    openConfirmationHandler: openWarehouseConfirmationHandler,
    closeConfirmationHandler: closeWarehouseConfirmationHandler,
    submitConfirmationHandler: submitWarehouseConfirmationHandler,
  } = useConfirmation(onWarehouseChangeHandler);

  const cancelTypeChangeHandler = () => {
    setCurrentType(tempType);
    setValue("type", tempType);
    closeTypeConfirmationHandler();
  };

  const cancelWarehouseChangeHandler = () => {
    setCurrentWarehouse(tempWarehouse);
    setValue("source_warehouse_id", tempWarehouse);
    closeWarehouseConfirmationHandler();
  };

  const updateSerialNo = useCallback(
    (fieldName: string, newValue: string | number) => {
      const traceEntryList = getValues("trace_entry_list");
      const warehouseId =
        fieldName === "source_warehouse_id"
          ? newValue
          : getValues("source_warehouse_id");
      const uniqueId =
        fieldName === "unique_id" ? newValue : getValues("unique_id");
      const updatedTraceEntryList = traceEntryList.map((trace: ITraceEntry) => {
        return {
          ...trace,
          serial_list: trace.serial_list?.map((serial, index) => {
            const padSn = String(index + 1).padStart(4, "0");
            return {
              ...serial,
              serial_no: `${warehouseId}#${uniqueId}#${padSn}`,
            };
          }),
        };
      });
      replace(updatedTraceEntryList);
    },
    [getValues, replace]
  );

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphqlClientWms,
      {
        modelType: InventoryModelType.GoodIssue,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
    updateSerialNo("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue, updateSerialNo]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinished = ["finished", "cancelled"].includes(status);

  const openRefDocument = async (unique_id: string) => {
    if (!unique_id) return;
    const { PurchaseReturnViewsAggrid } = await graphqlClientPurchase.request(
      PURCHASE_RETURN_VIEW,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            unique_id: {
              filterType: "text",
              type: "contains",
              filter: unique_id,
            },
          },
        },
      }
    );

    if (
      PurchaseReturnViewsAggrid &&
      PurchaseReturnViewsAggrid.results &&
      PurchaseReturnViewsAggrid.results.length > 0
    )
      window.open(
        `/purchase/return/${encodeURIComponent(
          PurchaseReturnViewsAggrid.results[0].id
        )}`,
        "_blank"
      );
  };

  const onReferenceChangeHandler = async (rs_unique_id: string) => {
    const { PurchaseReturnViewsAggrid } = await graphqlClientPurchase.request(
      PURCHASE_RETURN_VIEW,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            unique_id: {
              filterType: "text",
              type: "contains",
              filter: rs_unique_id,
            },
          },
        },
      }
    );

    setValue(
      "trace_entry_list",
      formatPurchaseItemListToGoodsIssueItemList(
        PurchaseReturnViewsAggrid.results[0]
      )
    );
  };

  return (
    <>
      <CustomizedBox margin={"0 0 1.5rem 0"}>
        <Typography fontWeight={"bold"} mb={2}>
          {t("sentence.detail")}
        </Typography>
        <Grid container spacing={1.5}>
          <Grid
            item
            xs={11}
            sm={11}
            md={id ? 4 : 3.4}
            lg={id ? 4 : 3.4}
            xl={id ? 4 : 3.4}
          >
            <ControlledTextField
              name="unique_id"
              control={control}
              label={t(`sentence.unique_id`)}
              error={Boolean(errors.unique_id)}
              helperText={errors.unique_id?.message?.toString()}
              onChange={(e, field) => {
                updateSerialNo("unique_id", e.target.value ?? "");
                return field.onChange(e.target.value ?? "");
              }}
              disabled={Boolean(id)}
              viewMode={disabled || isFinished}
              required
            />
          </Grid>
          {!id && (
            <Grid item xs={1} sm={1} md={0.6} lg={0.6} xl={0.6}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title={`เรียก${t(`sentence.unique_id`)}`}
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {disabled || isFinished ? (
              <LabelInput
                label={t("sentence.type")}
                value={goodsIssueTypeValueFormatter(getValues("type"))}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="type"
                label={t("sentence.type")}
                options={typeOptions}
                onChange={(e: any) => {
                  setTempType(getValues("type"));
                  setCurrentType(e.target.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openTypeConfirmationHandler();
                  }
                }}
                error={Boolean(errors.type)}
                helperText={errors.type?.message?.toString()}
                disabled={notDraft}
                required
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {disabled || isFinished ? (
              <Box>
                <InputLabel sx={{ fontSize: 11.11 }}>
                  {t("sentence.reference_unique_id")}
                </InputLabel>
                {getValues("reference_unique_id") ? (
                  <CustomizedChips
                    // key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                    onClick={() => {
                      const refIdList = getValues("reference_unique_id");
                      openRefDocument(refIdList);
                    }}
                    value={getValues("reference_unique_id") || "-"}
                    isRounded
                    size="small"
                  />
                ) : (
                  "-"
                )}
              </Box>
            ) : (
              <Controller
                control={control}
                name="reference_unique_id"
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("sentence.reference_unique_id")}
                    options={
                      watchType === "return" ? purchaseReturnOptions : []
                    }
                    disabled={!watchType || watchType === "other"}
                    required={watchType && watchType !== "other"}
                    onChange={async (_, newValue) => {
                      if (newValue) {
                        field.onChange(newValue.value);
                        onReferenceChangeHandler(newValue.value);
                      } else {
                        field.onChange("");
                        setValue("trace_entry_list", []);
                      }
                    }}
                    error={Boolean(errors.reference_unique_id)}
                    helperText={errors.reference_unique_id?.message?.toString()}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {disabled || isFinished ? (
              <LabelInput
                label={t("inventory.item.warehouse.index")}
                value={renderWarehousesOptions(
                  getValues("source_warehouse_id")
                )}
              />
            ) : (
              <Controller
                control={control}
                name="source_warehouse_id"
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("inventory.item.warehouse.index")}
                    options={warehousesOptions}
                    disabled={notDraft || warehousesOptions.length === 0}
                    onChange={(_, newValue) => {
                      updateSerialNo(
                        "source_warehouse_id",
                        newValue?.value ?? ""
                      );
                      setTempWarehouse(getValues("source_warehouse_id"));
                      setCurrentWarehouse(newValue?.value);
                      const currentTrace = getValues("trace_entry_list");
                      if (currentTrace.length > 0 && watchType === "other") {
                        openWarehouseConfirmationHandler();
                      }
                      field.onChange(newValue?.value ?? "");
                    }}
                    error={Boolean(errors.source_warehouse_id)}
                    helperText={errors.source_warehouse_id?.message?.toString()}
                    value={renderWarehousesOptions(field.value)}
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <ControlledTextField
              control={control}
              name="remark"
              label={t("sentence.remark")}
              viewMode={disabled || isFinished}
              error={Boolean(errors.remark)}
              helperText={errors.remark?.message?.toString()}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <ConfirmationModal
        title="ยืนยันการเปลี่ยนประเภท"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนประเภท"}</Typography>
            <Typography>{"รายการสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"}</Typography>
          </Box>
        }
        open={typeConfirmation}
        handleClose={cancelTypeChangeHandler}
        action={submitTypeConfirmationHandler}
        noDivider
      />
      <ConfirmationModal
        title="ยืนยันการเปลี่ยนคลัง"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนคลัง"}</Typography>
            <Typography>{"รายการสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"}</Typography>
          </Box>
        }
        open={warehouseConfirmation}
        handleClose={cancelWarehouseChangeHandler}
        action={submitWarehouseConfirmationHandler}
        noDivider
      />
    </>
  );
};

export default GoodsIssueInfo;
