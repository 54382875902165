import { Box, DialogContentText } from "@mui/material";
import ModalUI from "./ModalUI";
import CustomizedButton from "../../Custom/CustomizedButton";

interface Props {
  open: boolean;
  logout: () => void;
}

const ExpiredConfirmation = ({ open, logout }: Props) => {
  return (
    <ModalUI
      title="เซสชันหมดอายุ"
      open={open}
      handleClose={undefined}
      maxWidth="xs"
      fullWidth
      action={
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton title="ตกลง" variant="contained" onClick={logout} />
        </Box>
      }
    >
      <DialogContentText>โปรดเข้าสู่ระบบอีกครั้ง</DialogContentText>
    </ModalUI>
  );
};

export default ExpiredConfirmation;
