import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { IAccessPermission } from "types/Auth/role";

export const useMaintenanceOption = (
  status?: string | null,
  isEditing?: boolean,
  options?: any,
  maintenancePermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        maintenancePermissions?.update && {
          value: "แก้ไข",
          disabled: isEditing,
        },
        maintenancePermissions?.create && {
          value: "คัดลอก",
          disabled: false,
        },
        maintenancePermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
      ] as IMenuOption[],
    [isEditing, maintenancePermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions(
          [defaultOptions[1], defaultOptions[2]].filter(Boolean)
        ); // ยกเลิก, คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // คัดลอก
        break;
      case "finished":
        if (
          options &&
          options.type === "CLAIM" &&
          !options.is_insurance_fully_paid
        ) {
          setSelectedModifyOptions(
            [
              {
                value: "ได้รับเงินเคลมครบ",
              },
              defaultOptions[1],
            ].filter(Boolean)
          ); // ได้รับเงินเคลมครบ,คัดลอก
          break;
        }
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, status, options]);

  return selectModifyOptions;
};
