import { Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import BillingNoteByItemReportTable from "components/Table/Sales/Reports/BillingNoteByItemReport";
import HeaderLayout from "components/UI/HeaderLayout";
import { BillingNoteReportType } from "generated/sales";
import { useBillingNoteReport } from "hooks/Sales/BillingNote/use-billing-note-report";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";

const BillingNoteByItemReport = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isLoading } = useBillingNoteReport(
    gridRef,
    BillingNoteReportType.BillingNoteByItem
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("report"),
      to: "/logistic/report",
    },
    {
      name: t("reports.delivery_trip_income_by_item"),
    },
  ];

  if (permissions && !permissions.logistic_export?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth={true}>
        <Typography variant="h5">
          {t("reports.delivery_trip_income_by_item")}
        </Typography>
        {permissions?.logistic_export?.export && (
          <CustomizedButton
            title={`${t("button.export")}`}
            variant="contained"
            onClick={onBtnExport}
            disabled={isLoading}
          />
        )}
      </HeaderLayout>
      <BillingNoteByItemReportTable gridRef={gridRef} />
    </>
  );
};

export default BillingNoteByItemReport;
