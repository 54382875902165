import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AgGrid from "../../UI/AgGrid";
import {
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useUserColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { USER_VIEW_AGGRID } from "../../../services/AgGrid/UserAgGrid";
import { useStateContext } from "../../../contexts/auth-context";
import { dateFilterModel } from "utils/AgGridFilter";

const UserTable = () => {
  const gridRef: any = useRef();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        status,
        issue_date,
        created_date,
        license_expire_date,
        ...filter
      } = filterModel;
      const formatFilter = {
        ...filter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        license_expire_date: dateFilterModel(license_expire_date),
        created_date: dateFilterModel(created_date),
      };
      try {
        const { UserViewsAggrid } = await graphQLClient.request(
          USER_VIEW_AGGRID,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: UserViewsAggrid.results as any[],
          rowCount: UserViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceStatus = params.api.getFilterInstance("status");
      switch (isFilter) {
        case "in_active":
          instanceStatus?.setModel({ values: ["0"] });
          break;
        default:
          instanceStatus?.setModel({ values: ["1"] });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    let path = "account";
    if (authUser?.id === id) path = "setting";

    navigate(`/user/${path}/${id}?subtab=inventory`);
  };

  const columnDefs = useUserColumnDefs(t);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      path={"/user/account"}
      onGridReady={onGridReady}
    />
  );
};

export default UserTable;
