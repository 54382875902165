import { useRef } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Typography } from "@mui/material";

import ModalUI from "components/UI/Modal/ModalUI";
import ButtonLayout from "components/UI/ButtonLayout";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedChips from "components/Custom/CustomizedChips";
import CustomizedButton from "components/Custom/CustomizedButton";
import TireList from "components/Table/Logistic/Vehicle/TireList";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import TireTableModal from "components/UI/Modal/CheckedModal/TireTableModal";
import TireSwapPositionModal from "components/UI/Modal/CheckedModal/TireSwapPositionModal";

import { IVehicle } from "types/Logistic/vehicle";
import { vehicleTireSchema, vehicleTireValidation } from "./schema";
import { useModal } from "hooks/use-modal";
import { useDisable } from "hooks/use-disable";
import { TireActionType } from "generated/sales";
import { formatNumber } from "utils/Global";

const TireInfo = () => {
  const { id } = useParams();
  const ref = useRef<any>();
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();

  const { control, watch } = useFormContext<IVehicle>();

  const vehicleType = watch("type");
  const unique_id = watch("unique_id");
  const register_id = watch("register_id");
  const current_kilometer = watch("current_kilometer");

  const tireCountByVehicleType: Record<string, number> = {
    four_wheel: 4,
    six_wheel: 6,
    ten_wheel: 10,
    head: 12,
    trailer: 12,
  };

  const tireCount = tireCountByVehicleType[vehicleType || "four_wheel"];

  const {
    control: modalControl,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: vehicleTireSchema,
    resolver: yupResolver<any>(vehicleTireValidation),
  });

  // Modal hooks
  const tireModal = useModal();
  const addTireModal = useModal();
  const removeTireModal = useModal();
  const swapPositionTireModal = useModal();

  // Tire fields
  const mainTire = useFieldArray({ control, name: "main_tire", keyName: "id" });
  const spareTire = useFieldArray({
    control,
    name: "spare_tire",
    keyName: "id",
  });
  const uninstallTire = useFieldArray({
    control,
    name: "uninstall_tire",
    keyName: "id",
  });

  const allTires = [...mainTire.fields, ...spareTire.fields];

  const usedTireIds =
    allTires
      .map((tire) => tire.tire_id)
      .filter((id): id is number => id !== undefined) || [];

  const usedPositions = mainTire.fields.map((tire) => tire.position);

  const tireOptions = Array.from({ length: tireCount + 1 }, (_, i) => ({
    label: i === tireCount ? "สำรอง" : `${i + 1}`,
    value: i === tireCount ? "spare" : `${i + 1}`,
    disabled:
      usedPositions.includes(`${i + 1}`) ||
      (i === tireCount && spareTire.fields.length === tireCount),
  }));

  const handleModalSubmit = (data: any) => {
    try {
      if (data.position === "spare") spareTire.append(data);
      else mainTire.append(data);
      enqueueSnackbar("ใส่ยางสำเร็จ", {
        variant: "success",
      });
      closeAllModals("all");
    } catch (error) {
      enqueueSnackbar("ใส่ยางไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const closeAllModals = (type: string) => {
    const modalHandlers = {
      add: addTireModal.closeModalHandler,
      remove: removeTireModal.closeModalHandler,
      tire: tireModal.closeModalHandler,
    };

    Object.entries(modalHandlers).forEach(([key, handler]) => {
      if (type === "all" || type === key) {
        handler();
      }
    });

    reset(vehicleTireSchema);
  };

  const renderTireInfoGrid = (isRemove = false) => [
    {
      label: t("logistic.tire.serial"),
      value: getValues("serial"),
    },
    { label: t("logistic.vehicle.unique_id"), value: unique_id },
    { label: t("logistic.vehicle.register_id"), value: register_id },
    {
      label: t("logistic.vehicle.tire_info.vehicle_kilometer"),
      value: formatNumber(current_kilometer, true),
    },
    {
      label: t("logistic.vehicle.tire_info.position"),
      value: isRemove ? (
        getValues("position") === "spare" ? (
          "สำรอง"
        ) : (
          getValues("position")
        )
      ) : (
        <ControlledSelect
          control={modalControl}
          name="position"
          options={tireOptions}
          error={Boolean(errors?.position)}
          helperText={errors?.position && errors?.position.message?.toString()}
          endAdorment={
            <Typography color="red" position="absolute" right="2rem">
              *
            </Typography>
          }
          // sx={{ mt: Boolean(errors?.position) ? -2 : undefined }}
        />
      ),
    },
    {
      label: t(
        `logistic.vehicle.tire_info.${isRemove ? "remove_" : ""}action_date`
      ),
      value: (
        <ControlledDatePicker
          control={modalControl}
          name="action_date"
          required
        />
      ),
    },
    {
      label: t("sentence.remark"),
      value: <ControlledTextField control={modalControl} name="remark" />,
    },
  ];

  return (
    <CustomizedBox>
      {/* Main Tires Section */}
      <SectionHeader
        title={t("logistic.vehicle.tire_info.main")}
        chipValue={`${mainTire.fields.length}/${tireCount}`}
        onClick={swapPositionTireModal.openModalHandler}
        disabledButton={!id || disabled}
        changeButton={!id || Boolean(id && !disabled)}
      />
      <TireList
        fields={mainTire.fields}
        handlerRemoveModal={removeTireModal.openModalHandler}
        reset={reset}
      />
      {!disabled && (
        <AddTireButton
          onClick={tireModal.openModalHandler}
          disabled={
            mainTire.fields.length === tireCount ||
            typeof current_kilometer !== "number"
          }
        />
      )}

      {/* Spare Tires Section */}
      <SectionHeader
        title={t("logistic.vehicle.tire_info.spare")}
        chipValue={`${spareTire.fields.length}/${tireCount}`}
      />
      <TireList
        fields={spareTire.fields}
        handlerRemoveModal={removeTireModal.openModalHandler}
        reset={reset}
      />
      {!disabled && (
        <AddTireButton
          onClick={() => {
            setValue("position", "spare");
            tireModal.openModalHandler();
          }}
          disabled={
            spareTire.fields.length === tireCount ||
            typeof current_kilometer !== "number"
          }
        />
      )}

      {/* Modals */}
      <TireTableModal
        ref={ref}
        showSelectTire={tireModal.modal}
        closeTireTable={tireModal.closeModalHandler}
        finishTiresSelect={() => {
          const selectedItems = ref?.current?.api.getSelectedRows();
          if (selectedItems && selectedItems.length > 0) {
            setValue("tire_id", parseInt(selectedItems[0].id));
            setValue("serial", selectedItems[0].serial);
            setValue("band", selectedItems[0].band);
            setValue("model", selectedItems[0].model);
            setValue("current_distance", selectedItems[0].current_distance);
            setValue("due_distance", selectedItems[0].due_distance);
            setValue("action_type", TireActionType.Install);
            addTireModal.openModalHandler();
          }
        }}
        rowSelection="single"
        usedTireId={usedTireIds}
      />
      <ModalUI
        maxWidth="xs"
        open={addTireModal.modal}
        title={t("logistic.vehicle.tire_info.selected_header")}
        handleClose={() => closeAllModals("add")}
        action={
          <ModalActions
            closeHandler={() => closeAllModals("add")}
            submitHandler={handleSubmit(handleModalSubmit)}
          />
        }
      >
        <Grid container>{renderTireInfoGrid().map(renderGridItem)}</Grid>
      </ModalUI>
      <ModalUI
        maxWidth="xs"
        open={removeTireModal.modal}
        title={t("logistic.vehicle.tire_info.remove_header")}
        handleClose={() => closeAllModals("remove")}
        action={
          <ModalActions
            closeHandler={() => closeAllModals("remove")}
            submitHandler={handleSubmit((data) => {
              try {
                data?.position === "spare"
                  ? spareTire.remove(data.index)
                  : mainTire.remove(data.index);
                if (!data?.action_type)
                  uninstallTire.append({
                    ...data,
                    action_type: TireActionType.Uninstall,
                  });
                enqueueSnackbar("ถอดยางสำเร็จ", {
                  variant: "success",
                });
                closeAllModals("all");
              } catch (error) {
                enqueueSnackbar("ถอดยางไม่สำเร็จ", {
                  variant: "error",
                });
              }
            })}
          />
        }
      >
        <Grid container>{renderTireInfoGrid(true).map(renderGridItem)}</Grid>
      </ModalUI>
      <TireSwapPositionModal
        showTable={swapPositionTireModal.modal}
        closeTableHandler={swapPositionTireModal.closeModalHandler}
        fields={allTires}
        tireOptions={tireOptions}
        tireCount={tireCount}
      />
    </CustomizedBox>
  );
};

const SectionHeader = ({
  title,
  chipValue,
  changeButton = false,
  disabledButton = false,
  onClick = () => {},
}: {
  title: string;
  chipValue: string;
  changeButton?: boolean;
  disabledButton?: boolean;
  onClick?: () => void;
}) => (
  <Box display="flex" justifyContent="space-between">
    <Box display="flex" gap={1} alignItems="center">
      <Typography fontWeight={600}>{title}</Typography>
      <CustomizedChips
        value={chipValue}
        variant="filled"
        sx={{ backgroundColor: "#ECF6FD", color: "#333333" }}
      />
    </Box>
    {changeButton && (
      <CustomizedButton
        title="กำหนดตำแหน่งยาง"
        variant="outlined"
        onClick={onClick}
        disabled={disabledButton}
      />
    )}
  </Box>
);

const AddTireButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => (
  <CustomizedButton
    variant="outlined"
    title="เพิ่มยาง"
    onClick={onClick}
    disabled={disabled}
    startAddIcon
    sx={{ marginY: 2 }}
  />
);

const ModalActions = ({
  closeHandler,
  submitHandler,
}: {
  closeHandler: () => void;
  submitHandler: () => void;
}) => (
  <ButtonLayout>
    <CustomizedButton
      title="ยกเลิก"
      variant="outlined"
      onClick={closeHandler}
    />
    <CustomizedButton
      title="ยืนยัน"
      variant="contained"
      onClick={submitHandler}
    />
  </ButtonLayout>
);

const renderGridItem = ({
  label,
  value,
}: {
  label: string;
  value: JSX.Element | string;
}) => (
  <>
    <Grid item xs={4} minHeight="53px" alignContent="center">
      <Typography fontWeight="bold">{label}</Typography>
    </Grid>
    <Grid item xs={8} minHeight="53px" alignContent="center">
      {value}
    </Grid>
  </>
);

export default TireInfo;
