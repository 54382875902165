import { useTranslation } from "react-i18next";
import { Navigate } from 'react-router-dom';
import { IBreadcrumbsAndMenu } from "types/global";
import { useStateContext } from "contexts/auth-context";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";

const InventoryReport = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.itemStock.index"),
      to: "stock",
    },
    {
      name: t("inventory.noEntry.index"),
      to: "no-entry",
    },
    {
      name: t("inventory.traceEntry.index"),
      to: "trace-entry",
    },
    {
      name: t("inventory.itemCurrentStockSerialNumber.index"),
      to: "current-stock-by-sn",
    },
  ];

  if(permissions && !permissions.inventory_export?.view){
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default InventoryReport;
