import { gql } from "graphql-request";

export const GOODS_RECEIVE_VIEW = gql`
  query GoodsReceivesViewAggrid($aggridInput: AnyAggridInput!) {
    GoodsReceivesViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        posted_date
        created_date
        reference_unique_id_list
        source_warehouse_name
        type
        created_by
        created_by_object {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        remark
        aggrid_status
      }
    }
  }
`;
