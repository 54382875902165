import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledRadioGroup from "components/Controller/ControlledRadioGroup";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";
import CheckedTrailerModal from "components/UI/Modal/CheckedModal/CheckedTrailerModal";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { VehicleStatus } from "generated/vehicle";
import { IVehicle } from "types/Logistic/vehicle";

import { useDisable } from "hooks/use-disable";
import { useVehicleDescription } from "hooks/Logistic/use-vehicle-description";

const VehicleInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disable] = useDisable();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IVehicle>();

  const type = useWatch({
    control,
    name: "type",
  });

  const status = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    setValue("status", VehicleStatus.Active);
  }, [setValue]);

  const {
    openTrailerTable,
    closeTrailerTableHandler,
    trailerId,
    setTrailerId,
    trailerIdSnapshot,
    setTrailerIdSnapshot,
    finishTrailerSelect,
    openTrailerTableHandler,
  } = useVehicleDescription();

  const notTrailer = type !== "trailer";

  return (
    <>
      <CustomizedBox>
        <Grid container spacing={2.3}>
          <Grid item xs={12}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <Typography fontWeight={600}>รายละเอียด</Typography>
              </Grid>
              <Grid item xs={12} marginTop={1}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} md={4}>
                    <ControlledNumberTextField
                      name="current_kilometer"
                      control={control}
                      label={t("logistic.vehicle.current_kilometer")}
                      textAlign="right"
                      error={Boolean(errors?.current_kilometer)}
                      helperText={
                        errors?.current_kilometer &&
                        errors?.current_kilometer.message?.toString()
                      }
                      fullWidth
                      decimalScale={0}
                      viewMode={Boolean(id) && disable}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledNumberTextField
                      name="due_distance_kilometer"
                      control={control}
                      label={t("logistic.vehicle.due_distance_kilometer")}
                      textAlign="right"
                      error={Boolean(errors?.due_distance_kilometer)}
                      helperText={
                        errors?.due_distance_kilometer &&
                        errors?.due_distance_kilometer.message?.toString()
                      }
                      fullWidth
                      decimalScale={0}
                      viewMode={Boolean(id) && disable}
                      required
                    />
                  </Grid>
                  {notTrailer && (
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CustomizedTooltip
                                title={t(
                                  "logistic.vehicle.sentence.choose_trailer"
                                )}
                              >
                                <IconButton
                                  onClick={openTrailerTableHandler}
                                  color="primary"
                                  size="small"
                                >
                                  <EditIcon sx={{ fontSize: 16 }} />
                                </IconButton>
                              </CustomizedTooltip>
                            </InputAdornment>
                          ),
                        }}
                        name="trailer_unique_id"
                        control={control}
                        label={t("logistic.vehicle.tail")}
                        error={Boolean(errors?.trailer_unique_id)}
                        helperText={
                          errors?.trailer_unique_id &&
                          errors?.trailer_unique_id.message?.toString()
                        }
                        viewMode={Boolean(id) && disable}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <ControlledSSCreatable
                  name="brand"
                  control={control}
                  label={t("logistic.vehicle.brand")}
                  documentType="vehicle"
                  error={Boolean(errors?.brand)}
                  helperText={
                    errors?.brand && errors?.brand.message?.toString()
                  }
                  defaultOptions={[]}
                  setValue={setValue}
                  viewMode={Boolean(id) && disable}
                  endArrowIcon
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ControlledSSCreatable
                  name="model"
                  control={control}
                  label={t("logistic.vehicle.model")}
                  documentType="vehicle"
                  error={Boolean(errors?.model)}
                  helperText={
                    errors?.model && errors?.model.message?.toString()
                  }
                  defaultOptions={[]}
                  setValue={setValue}
                  viewMode={Boolean(id) && disable}
                  endArrowIcon
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <ControlledTextField
                  name="detail"
                  control={control}
                  label={t("logistic.vehicle.detail")}
                  error={Boolean(errors?.detail)}
                  helperText={
                    errors?.detail && errors?.detail.message?.toString()
                  }
                  viewMode={Boolean(id) && disable}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2.3}>
              <Grid item xs={12}>
                <Typography fontWeight={600}>รายละเอียดทะเบียน</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      name="act_id"
                      control={control}
                      label={t("logistic.vehicle.act_id")}
                      error={Boolean(errors?.act_id)}
                      helperText={
                        errors?.act_id && errors?.act_id.message?.toString()
                      }
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledDatePicker
                      name="act_end_date"
                      control={control}
                      label={t("logistic.vehicle.act_end_date")}
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography fontWeight={600}>สถานะ</Typography>
              </Grid>
              <Grid item xs={12}>
                <ControlledRadioGroup
                  control={control}
                  name="status"
                  disabled={disable}
                  row
                  radioLists={[
                    {
                      label: "ใช้งาน",
                      value: VehicleStatus.Active,
                    },
                    {
                      label: "หยุดใช้งาน",
                      value: VehicleStatus.InActive,
                    },
                  ]}
                />
              </Grid>
              {status === VehicleStatus.InActive && (
                <Grid item xs={12} md={4}>
                  <ControlledTextField
                    name="status_note"
                    control={control}
                    label={t("logistic.vehicle.status_note")}
                    error={Boolean(errors?.status_note)}
                    helperText={
                      errors?.status_note &&
                      errors?.status_note.message?.toString()
                    }
                    viewMode={disable}
                    required
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedTrailerModal
        showSelectTrailer={openTrailerTable}
        closeTrailerTable={closeTrailerTableHandler}
        trailerId={trailerId}
        setTrailerId={setTrailerId}
        trailerIdSnapshot={trailerIdSnapshot}
        setTrailerIdSnapshot={setTrailerIdSnapshot}
        finishTrailerSelect={finishTrailerSelect}
        trailerOnly={false}
      />
    </>
  );
};

export default VehicleInfo;
