import { Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedTab from "components/Custom/CustomizedTab";
import DeliveryTripTable from "components/Table/Logistic/DeliveryTrip";
import HeaderLayout from "components/UI/HeaderLayout";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu, ITab } from "types/global";

const DeliveryTrip = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get("filter");
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.delivery_trip.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.wait_deliver"),
      path: `${pathname}?filter=wait_deliver`,
    },
    {
      label: t("status.delivering"),
      path: `${pathname}?filter=delivering`,
    },
    {
      label: t("status.late"),
      path: `${pathname}?filter=late`,
      errors: true,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (statusFilter ? `?filter=${statusFilter}` : "");

  if (permissions && !permissions.delivery_trip?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">
          {t("logistic.delivery_trip.index")}
        </Typography>
        {permissions?.delivery_trip?.create && (
          <CustomizedButton
            startAddIcon
            title={`${t("button.create")}${t("logistic.delivery_trip.index")}`}
            variant="contained"
            onClick={() => navigate(`/logistic/delivery-trip/add`)}
          />
        )}
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <DeliveryTripTable status={statusFilter} />
    </>
  );
};

export default DeliveryTrip;
