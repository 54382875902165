import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu, ITab } from "../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { IContact } from "../../types/Contact/contact";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  contactSchema,
  contactValidation,
} from "../../components/Form/Contact/schema";
import {
  Box,
  CircularProgress,
  IconButton,
  // IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import BottomNavbar from "../../components/UI/Navbar/BottomNavbar";
import { CustomizedBox } from "../../components/Custom/CustomizedBox";
import ContactInfo from "../../components/Form/Contact/ContactInfo";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import ContactRemark from "../../components/Form/Contact/ContactRemark";
import { useDisable } from "../../hooks/use-disable";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import ContactAddress from "../../components/Form/Contact/Address";
import ContactPersonList from "../../components/Form/Contact/ContactPersonList";
import ContactFinance from "../../components/Form/Contact/ContactFinance";
import SalesPersonList from "../../components/Form/Contact/SalesPersonList";
import ContactSpecificInfo from "../../components/Form/Contact/ContactSpecificInfo";
import ContactAttachment from "../../components/Form/Contact/ContactAttachment";
import { errorMessageFormatter } from "../../utils/Global";
import { useSnackbar } from "notistack";
import { useModal } from "../../hooks/use-modal";
import CustomizedMenuOptions from "../../components/Custom/CustomizedMenuOptions";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  ContactFindUniqueQuery,
  useContactCreateMutation,
  useContactDeleteMutation,
  useContactFindUniqueQuery,
  useContactUpdateMutation,
} from "../../generated/crm";
import {
  contactCreateFormatter,
  contactQueryFotmatter,
  contactUpdateFormatter,
} from "../../utils/Formatter/Contact";
import CustomizedChips from "../../components/Custom/CustomizedChips";
import { formatDate } from "../../utils/Date";
import DeleteConfirmation from "../../components/UI/Modal/DeleteConfirmation";
import { ActivityLogDocumentType, ActivityType } from "../../generated/general";
import { useActivityLog } from "../../hooks/use-activity-log";
import { CustomizedTooltip } from "../../components/Custom/CustomizedTooltip";
import { ActivityLog } from "../../components/UI/ActivityLog";
import { useStateContext } from "contexts/auth-context";

const ContactContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled, setDisabled] = useDisable();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [prevStatus, setPrevStatus] = useState<number | null>();
  const [prevIsCustomer, setPrevIsCustomer] = useState<boolean | null>(null);
  const [prevIsSupplier, setprevIsSupplier] = useState<boolean | null>(null);

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const {
    createActivityLog,
    openActivityLog,
    onActivityLogOpen,
    onActivityLogClose,
  } = useActivityLog();

  const tabs: ITab[] = [
    {
      label: t("contact.tab.address"),
      path: `${pathname}?tab=address`,
    },
    {
      label: t("contact.tab.contact_person"),
      path: `${pathname}?tab=contact_person`,
    },
    {
      label: t("contact.tab.financial"),
      path: `${pathname}?tab=financial`,
    },
    {
      label: t("contact.tab.specific_info"),
      path: `${pathname}?tab=specific_info`,
    },
    {
      label: t("contact.tab.attachment"),
      path: `${pathname}?tab=attachment`,
    },
    {
      label: t("contact.tab.sales_person"),
      path: `${pathname}?tab=sales_person`,
    },
  ];

  const methods = useForm<IContact>({
    defaultValues: contactSchema,
    resolver: yupResolver<any>(contactValidation),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }

    return () => setDisabled(false);
  }, [id, setDisabled]);

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const graphqlClientCrm = createGraphQLClientWithMiddleware("crm");

  const { data, isSuccess, isLoading } =
    useContactFindUniqueQuery<ContactFindUniqueQuery>(
      graphqlClientCrm,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.all"),
      to: "/contact/all",
    },
    {
      name:
        id && Boolean(id)
          ? data && data.ContactFindUnique?.unique_id
            ? data.ContactFindUnique.unique_id
            : ""
          : t(`${t("button.create")}${t("contact.index")}`),
    },
  ];

  const { mutateAsync: create, isLoading: isCreating } =
    useContactCreateMutation<Error>(graphqlClientCrm);

  const { mutateAsync: update, isLoading: isUpdating } =
    useContactUpdateMutation<Error>(graphqlClientCrm);

  const { mutateAsync: deleteContact, isLoading: isDeleting } =
    useContactDeleteMutation<Error>(graphqlClientCrm);

  const editCancelHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      const { ContactFindUnique } = data;
      setPrevStatus(ContactFindUnique?.status);
      const customerData = contactQueryFotmatter(ContactFindUnique);
      reset(customerData);
      setPrevIsCustomer(customerData.is_customer);
      setprevIsSupplier(customerData.is_supplier);
    }
  }, [data, isSuccess, reset]);

  const contactCreateHandler = async (data: IContact) => {
    try {
      const formatData = await contactCreateFormatter(data, id, authUser);
      const { ContactCreate } = await create({
        createInput: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.Contact,
        reference_id: ContactCreate?.id || 0,
        activity_detail: {},
      });
      if (ContactCreate?.status === 0) {
        await createActivityLog({
          activity_type: ActivityType.InActive,
          document_type: ActivityLogDocumentType.Contact,
          reference_id: ContactCreate?.id || 0,
          activity_detail: {},
        });
      }
      const customerData = contactQueryFotmatter(ContactCreate);
      reset(customerData);
      setPrevStatus(customerData.status);
      enqueueSnackbar(`สร้าง${t("contact.index")}สำเร็จ`, {
        variant: "success",
      });
      navigate(`/contact/all/${ContactCreate?.id}?tab=address`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || `สร้าง${t("contact.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const contactUpdateHandler = async (data: IContact) => {
    try {
      const { id } = data;
      const formatData = await contactUpdateFormatter(data, authUser);
      const { ContactUpdate } = await update({
        uniqueInput: {
          id: id,
        },
        updateInput: formatData,
      });
      if (isDirty) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.Contact,
          reference_id: ContactUpdate?.id || 0,
          activity_detail: {},
        });
        if (prevStatus === 1) {
          if (ContactUpdate?.status === 0) {
            await createActivityLog({
              activity_type: ActivityType.InActive,
              document_type: ActivityLogDocumentType.Contact,
              reference_id: ContactUpdate?.id || 0,
              activity_detail: {},
            });
          }
        } else {
          if (ContactUpdate?.status === 1) {
            await createActivityLog({
              activity_type: ActivityType.Active,
              document_type: ActivityLogDocumentType.Contact,
              reference_id: ContactUpdate?.id || 0,
              activity_detail: {},
            });
          }
        }
      }

      const customerData = contactQueryFotmatter(ContactUpdate);
      reset(customerData);
      setPrevStatus(customerData.status);
      setDisabled(true);
      setIsEdit(false);
      enqueueSnackbar(`แก้ไข${t("contact.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || `แก้ไข${t("contact.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const contactDeleteHandler = async () => {
    try {
      await deleteContact({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      // await createActivityLog({
      //   activity_type: ActivityType.Delete,
      //   document_type: ActivityLogDocumentType.Contact,
      //   reference_id: ContactDelete?.id,
      //   activity_detail: {},
      // });
      enqueueSnackbar(`ลบ${t("contact.index")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/contact/all");
    } catch (err) {
      enqueueSnackbar(`ลบ${t("contact.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(contactDeleteHandler);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "address":
        return (
          <ContactAddress
            addressList={data?.ContactFindUnique?.address_list || []}
          />
        );
      case "contact_person":
        return <ContactPersonList />;
      case "financial":
        return <ContactFinance />;
      case "specific_info":
        return <ContactSpecificInfo />;
      case "attachment":
        return <ContactAttachment />;
      case "sales_person":
        return <SalesPersonList />;
      default:
        return null;
    }
  };

  const contactIsBoth = prevIsCustomer && prevIsSupplier;

  const customerNotPermitted =
    id && prevIsCustomer && permissions && !permissions.customer?.view;

  const supplierNotPermitted =
    id && prevIsSupplier && permissions && !permissions.supplier?.view;

  const contactNotPermitted = permissions && !permissions.contact?.view;

  const canEdit =
    (id && prevIsCustomer && permissions && permissions.customer?.update) ||
    (id && prevIsSupplier && permissions && permissions.supplier?.update);

  const canDelete =
    (id && prevIsCustomer && permissions && permissions.customer?.delete) ||
    (id && prevIsSupplier && permissions && permissions.supplier?.delete);

  const selectModifyOptions = [
    canEdit && {
      value: "แก้ไข",
      disabled: !!isEdit,
    },
    canDelete && {
      value: "ลบ",
      disabled: false,
    },
  ].filter(Boolean) as { value: string; disabled: boolean }[];

  if (
    (!contactIsBoth && (customerNotPermitted || supplierNotPermitted)) ||
    contactNotPermitted
  ) {
    return <Navigate replace to="/unauthorized" />;
  }

  if (id && (isLoading || isCreating || isUpdating || isDeleting)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={1040}>
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={ActivityLogDocumentType.Contact}
        />
      )}
      <Box display="flex">
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <Box marginLeft="auto">
            <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
              <IconButton
                onClick={onActivityLogOpen}
                sx={{
                  padding: 0,
                  marginLeft: "4px",
                  color: "primary.main",
                }}
              >
                <RestoreOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="h5">{t("contact.index")}</Typography>
        {(canEdit || canDelete) && (
          <CustomizedMenuOptions
            size="small"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "แก้ไข":
                  setIsEdit(true);
                  setDisabled(false);
                  break;
                case "ลบ":
                  openConfirmationHandler();
                  break;

                default:
                  break;
              }
            }}
            disabled={!id}
          />
        )}
      </Box>
      <FormProvider {...methods}>
        {id && (
          <Box mt={1}>
            <CustomizedChips
              variant="filled"
              noneColor
              label={
                <Box display="flex" gap={1}>
                  <Typography fontWeight="bold">
                    {t("date.created_date")}
                  </Typography>
                  <Typography>
                    {formatDate(data?.ContactFindUnique?.created_date)}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )}
        <ContactInfo />
        <CustomizedBox margin={0}>
          <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
          {renderTab(tab)}
        </CustomizedBox>
        <ContactRemark />
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => {
                  if (id) {
                    editCancelHandler();
                  } else {
                    navigate("/contact/all");
                  }
                }}
                disabled={isCreating || isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.confirm")}
                onClick={handleSubmit(
                  id ? contactUpdateHandler : contactCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <DeleteConfirmation
        title={`ลบข้อมูล${t("contact.index")}`}
        message={
          <Box>
            <Typography>ยืนยันการลบข้อมูล{t("contact.index")}</Typography>
            <Typography fontWeight="bold">
              {data?.ContactFindUnique?.name +
                " " +
                data?.ContactFindUnique?.last_name}
            </Typography>
          </Box>
        }
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </Box>
  );
};

export default ContactContainer;
