import { useEffect } from "react";
// import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useStateContext } from "contexts/auth-context";

// import CustomizedTab from "components/Custom/CustomizedTab";
import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import { IBreadcrumbsAndMenu } from "types/global";

import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useActivityLog } from "hooks/use-activity-log";
import {
  ActivityLogDocumentType,
  BillingNoteFindUniqueQuery,
  useBillingNoteFindUniqueQuery,
} from "generated/sales";
import { ActivityLog } from "components/UI/ActivityLog";

import { IBillingNote } from "types/Sales/billing-note";
import {
  billingNoteSchema,
  billingNoteValidation,
} from "components/Form/Sales/BillingNote/schema";
import LoadingUI from "components/UI/LoadingUI";

import DocumentInfoTab from "./DocumentInfoTab";

const BillingNoteContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  // const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IBillingNote>({
    defaultValues: { ...billingNoteSchema },
    resolver: yupResolver<any>(billingNoteValidation),
  });

  const {
    formState: { errors },
  } = methods;

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const { item_list, ...otherErrors } = errors;

      if (Object.keys(otherErrors).length > 0) {
        enqueueSnackbar("กรุณาระบุข้อมูลที่จำเป็น", {
          variant: "error",
        });
      }

      if (item_list) {
        if (Array.isArray(item_list)) {
          item_list.forEach((item) => {
            if (item) {
              enqueueSnackbar("กรุณาระบุข้อมูลใบจัดส่งให้ถูกต้อง", {
                variant: "error",
              });
            }
          });
        } else {
          if (item_list.root) {
            enqueueSnackbar(item_list.root.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(item_list.message, {
              variant: "error",
            });
          }
        }
      }
    }
  }, [enqueueSnackbar, errors]);

  const { data, isLoading } =
    useBillingNoteFindUniqueQuery<BillingNoteFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  // const { control } = methods;

  // const status = useWatch({
  //   control,
  //   name: "aggrid_status",
  // });

  // const tabs: ITab[] = [
  //   {
  //     label: t("sales.document_info"),
  //     path: `${pathname}`,
  //   },
  //   status === "finished" && {
  //     label: t("logistic.delivery_trip.index"),
  //     path: `${pathname}?tab=delivery-trip`,
  //   },
  // ].filter(Boolean) as ITab[];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      default:
        return <DocumentInfoTab />;
    }
  };

  // const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.billing_note.index"),
      to: "/sales/billing-note",
    },
    {
      name: id
        ? data?.BillingNoteFindUnique.unique_id || "-"
        : t("button.create") + t("sales.billing_note.index"),
    },
  ];

  if (permissions && !permissions.billing_note?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (id && isLoading) {
    return <LoadingUI />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <BreadcrumbsLayout
          breadcrumbs={breadcrumbs}
          onActivityLogOpen={onActivityLogOpen}
        />
        {renderTab(tab)}
        {id && (
          <ActivityLog
            open={openActivityLog}
            onClose={onActivityLogClose}
            documentId={id!}
            documentType={ActivityLogDocumentType.BillingNote}
          />
        )}
      </FormProvider>
    </>
  );
};

export default BillingNoteContainer;
