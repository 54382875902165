import { Controller, Control } from "react-hook-form";
import { InputAdornment, InputAdornmentProps, Typography } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers";
import LabelInput from "../UI/LabelInput";
import { formatDate } from "../../utils/Date";

type ExtendedDatePickerProps = DesktopDatePickerProps<Date> & {
  name: string;
  control: Control<any>;
  required?: boolean;
  viewMode?: boolean | null;
  viewModeNoLabel?: boolean | null;
  placeholder?: string;
  onCusomChange?: () => void;
};

const ControlledDatePicker = ({
  name,
  control,
  label,
  disabled,
  disablePast,
  required,
  viewMode,
  viewModeNoLabel,
  placeholder,
  onCusomChange,
}: ExtendedDatePickerProps) => {
  const CustomInputAdornment = (
    props: InputAdornmentProps & { hasError?: boolean }
  ) => {
    const { hasError, children, sx, ...other } = props;
    return (
      <InputAdornment {...other}>
        {required && (
          <Typography color="error" component="span">
            *
          </Typography>
        )}
        {children}
      </InputAdornment>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <>
          {viewMode ? (
            <LabelInput
              label={!viewModeNoLabel && label}
              value={value ? formatDate(value.toDate()) : ""}
            />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                format="DD/MM/YYYY"
                label={label}
                onChange={(value) => {
                  if (onCusomChange) {
                    onChange(value);
                    onCusomChange();
                  } else {
                    onChange(value);
                  }
                }}
                value={value}
                ref={ref}
                disablePast={disablePast}
                disabled={disabled}
                slots={{
                  inputAdornment:
                    !label && required ? CustomInputAdornment : undefined,
                }}
                slotProps={{
                  textField: {
                    required: required,
                    fullWidth: true,
                    size: "small",
                    error: !!error,
                    helperText: error?.message,
                    placeholder: placeholder,
                  },
                }}
              />
            </LocalizationProvider>
          )}
        </>
      )}
    />
  );
};

export default ControlledDatePicker;
