import { IRole } from "types/Auth/role";

export const roleUpdateFormatter = (data: IRole) => {
  const { description, user_list } = data;

  let user_id_list: number[] = [];

  if (user_list?.length) {
    user_id_list = user_list.map((user) => user.id!);
  }

  return {
    description,
    user_id_list,
  };
};
