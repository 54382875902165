import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu } from "types/global";

import RoleTable from "components/Table/User/Role";
import HeaderLayout from "components/UI/HeaderLayout";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";

const Role = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
    },
  ];

  if (permissions && !permissions.role?.view) {
    return <Navigate replace to="/unauthorized" />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("user.rbac.index")}</Typography>
      </HeaderLayout>
      <RoleTable />
    </>
  );
};

export default Role;
