import { Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import QuotationReportTable from "components/Table/Sales/Reports/QuotationReport";
import HeaderLayout from "components/UI/HeaderLayout";
import { QuotationReportType } from "generated/sales";
import { useQuotationReport } from "hooks/Sales/Quotation/use-quotation-report";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";

const QuotationReport = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isLoading } = useQuotationReport(
    gridRef,
    QuotationReportType.Quotation
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
      to: "/sales/report",
    },
    {
      name: t("sales.quotation.index"),
    },
  ];

  if (permissions && !permissions.sales_export?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth={true}>
        <Typography variant="h5">{t("sales.quotation.index")}</Typography>
        {permissions?.sales_export?.export && (
          <CustomizedButton
            title={`${t("button.export")}`}
            variant="contained"
            onClick={onBtnExport}
            disabled={isLoading}
          />
        )}
      </HeaderLayout>
      <QuotationReportTable gridRef={gridRef} />
    </>
  );
};

export default QuotationReport;
