import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { statusValueFormatter } from "utils/Formatter/Global";

import CustomizedStatus from "components/Custom/CustomizedStatus";

import { formatNumber } from "utils/Global";
import { typeStatusValueFormatter } from "utils/Formatter/Logistic/Vehicle";

export const useTireColumnDefs = (isModal?: boolean) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setColumnDefs([
      {
        field: "serial",
        headerName: t("logistic.tire.serial"),
        filter: "agTextColumnFilter",
        checkboxSelection: isModal,
      },
      {
        field: "current_distance",
        headerName: t("logistic.tire.current_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "due_distance",
        headerName: t("logistic.tire.due_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "price_per_unit",
        headerName: t("logistic.tire.price_per_unit"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatNumber(params.value || 0),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "maintenance_status",
        headerName: t("logistic.tire.status"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },

        filterParams: {
          values: ["normal", "approaching", "overdue"],
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
        },
      },
      {
        field: "status",
        headerName: t("status.index"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        filterParams: {
          values: isModal ? ["stock"] : ["stock", "active", "in_active"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  return columnDefs;
};
