import { useStateContext } from "contexts/auth-context";
import {
  ItemImportMode,
  useImportServiceItemsMutation,
  useValidateItemImportQuery,
  ValidateItemImportQuery,
} from "generated/wms";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IImporterError } from "types/global";
import { formatFloat, formatString } from "utils/Global";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
  findNoneBoolean,
  findNoneStatus,
  findNoneVatType,
  mapNestedData,
} from "utils/Importer";

const columns = [
  "unique_id",
  "name",
  "seller_sku",
  "description",
  "is_purchasable",
  "is_saleable",
  "is_active",
  "remark_status",
  "cate_1",
  "cate_2",
  "cate_3",
  "sales_uom",
  "purchase_uom",
  "purchase_price",
  "min_purchase_request_qty",
  "purchase_vat_type",
  "sale_price",
  "stock_uom",
  "special_price",
  "sale_vat_type",
];

const count_limit = 5000;
export const useServiceItemImporter = (
  type: ItemImportMode,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();
  const requiredKeys = [
    {
      label: "รหัสสินค้า",
      value: "unique_id",
    },
    {
      label: "ชื่อสินค้า",
      value: "name",
    },
    {
      label: "ซื้อได้",
      value: "is_purchasable",
    },
    {
      label: "ขายได้",
      value: "is_saleable",
    },
    {
      label: "สถานะ",
      value: "is_active",
    },
    {
      label: "หมวดหมู่ 1",
      value: "cate_1",
    },
    {
      label: "หน่วยมาตรฐาน",
      value: "stock_uom",
    },
  ];

  const [validateInput, setValidateInput] = useState<{
    import_mode: ItemImportMode;
    item_unique_id_list: string[];
    uom_id_list: string[];
    item_cate_1_id_list: string[];
    item_cate_2_id_list: string[];
    item_cate_3_id_list: string[];
    stock_uom_id_list: string[];
    sales_uom_id_list: string[];
    purchase_uom_id_list: string[];
  }>({
    import_mode: ItemImportMode.Create,
    item_unique_id_list: [],
    uom_id_list: [],
    item_cate_1_id_list: [],
    item_cate_2_id_list: [],
    item_cate_3_id_list: [],
    stock_uom_id_list: [],
    sales_uom_id_list: [],
    purchase_uom_id_list: [],
  });

  // const [itemUniqueIdList, setItemUniqueIdList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery<ValidateItemImportQuery>(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportServiceItemsMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));
      if (missingCols.length > 0) {
        throw new Error("template");
      }
      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];
      const groupDataErrors: any[] = [];
      const invalidTypeErrors: any[] = [];
      const formattedDataSnapshot = [...data];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
        const status = formattedDataSnapshot[i].is_active;
        if (
          findNoneStatus([status]).length === 0 &&
          status !== "ใช้งาน" &&
          !formattedDataSnapshot[i].remark_status
        ) {
          groupDataErrors.push({
            unique_id: formattedDataSnapshot[i].unique_id,
            type: "invalid",
            field: "หมายเหตุสถานะ",
          });
        }
        const validateIdField = [
          { field: "รหัสสินค้า", value: formattedDataSnapshot[i].unique_id },
        ];
        const validateNumberField = [
          {
            field: "ราคาซื้อต่อหน่วย (บาท)",
            value: formattedDataSnapshot[i].purchase_price,
          },
          {
            field: "สั่งซื้อขั้นต่ำ",
            value: formattedDataSnapshot[i].min_purchase_request_qty,
          },
          {
            field: "ราคาขาย (บาท)",
            value: formattedDataSnapshot[i].sale_price,
          },
          {
            field: "ราคาขายพิเศษ (บาท)",
            value: formattedDataSnapshot[i].special_price,
          },
        ];
        const validateMaxLength = [
          { field: "ชื่อสินค้า", value: formattedDataSnapshot[i].name },
          { field: "Seller SKU", value: formattedDataSnapshot[i].seller_sku },
          {
            field: "รายละเอียดสินค้า",
            value: formattedDataSnapshot[i].description,
          },
          {
            field: "หมายเหตุสถานะ",
            value: formattedDataSnapshot[i].remark_status,
          },
        ];

        validateMaxLength.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (value.length > 200) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });

        validateNumberField.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (!Number(value) || value < 0 || value.length > 200) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });

        validateIdField.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (value.length > 15) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็น",
            error.field,
            [""]
          );
        });
      }

      if (invalidTypeErrors.length > 0) {
        invalidTypeErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "รูปแบบข้อมูลไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      if (groupDataErrors.length > 0) {
        groupDataErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "ข้อมูลชุดไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      let formattedData: any[] = [];

      const uniqueIdList: string[] = [];
      const activeList: string[] = [];
      const purchasableList: string[] = [];
      const saleableList: string[] = [];
      const purchaseVatTypeList: string[] = [];
      const saleVatTypeList: string[] = [];

      data.forEach((item: any) => {
        const {
          unique_id,
          is_active,
          is_purchasable,
          is_saleable,
          purchase_vat_type,
          sale_vat_type,
        } = item;

        if (unique_id) {
          uniqueIdList.push(unique_id);
        }
        if (is_active) {
          activeList.push(is_active);
        }
        if (is_purchasable) {
          purchasableList.push(is_purchasable);
        }
        if (is_saleable) {
          saleableList.push(is_saleable);
        }
        if (purchase_vat_type) {
          purchaseVatTypeList.push(purchase_vat_type);
        }
        if (sale_vat_type) {
          saleVatTypeList.push(sale_vat_type);
        }

        const created_by = {
          user_id: authUser?.id,
          user_unique_id: authUser?.unique_id,
          email: authUser?.email,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
        };

        const mappedData = {
          unique_id: formatString(item.unique_id),
          name: formatString(item.name),
          seller_sku: formatString(item.seller_sku) || "",
          description: formatString(item.description),
          is_purchasable: item.is_purchasable === "ใช่" ? true : false,
          is_saleable: item.is_saleable === "ใช่" ? true : false,
          is_active: item.is_active === "ใช้งาน" ? 1 : 0,
          remark_status: formatString(item.remark_status),
          cate_1: formatString(item.cate_1),
          cate_2: formatString(item.cate_2),
          cate_3: formatString(item.cate_3),
          sales_uom: formatString(item.sales_uom),
          purchase_uom: formatString(item.purchase_uom),
          purchase_price: formatFloat(item.purchase_price),
          min_purchase_request_qty: formatFloat(item.min_purchase_request_qty),
          purchase_vat_type: formatString(item.purchase_vat_type),
          sale_price: formatFloat(item.sale_price),
          stock_uom: formatString(item.stock_uom),
          special_price: formatFloat(item.special_price),
          sale_vat_type: formatString(item.sale_vat_type),
          type: "service",
          barcode: formatString(item.unique_id),
          created_by,
        };
        formattedData.push(mappedData);
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const noneStatus = findNoneStatus(activeList);
      const nonePurchasable = findNoneBoolean(purchasableList);
      const noneSaleable = findNoneBoolean(saleableList);
      const nonePurchaseVatType = findNoneVatType(purchaseVatTypeList);
      const noneSaleVatType = findNoneVatType(saleVatTypeList);

      const allError = [
        duplicateUniqueId,
        noneStatus,
        nonePurchasable,
        noneSaleable,
        nonePurchaseVatType,
        noneSaleVatType,
        requiredFieldErrors,
        invalidTypeErrors,
        groupDataErrors,
      ];

      addImporterErrorHandler(
        errorData,
        `ข้อมูลในไฟล์ซ้ำกัน`,
        `${t(`inventory.item.unique_id`)}`,
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ซื้อได้",
        nonePurchasable
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ขายได้",
        noneSaleable
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทภาษีซื้อ (%)",
        nonePurchaseVatType
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทภาษีขาย (%)",
        noneSaleVatType
      );

      setErrorData(errorData);
      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (
        sumErrorLength ||
        requiredFieldErrors.length > 0 ||
        groupDataErrors.length > 0 ||
        invalidTypeErrors.length > 0
      ) {
        openModalHandler();
        return [];
      }
      // setItemUniqueIdList(Array.from(new Set(uniqueIdList)));
      // setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (data.length === 0) {
        message = "กรุณาระบุข้อมุล";
      }
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      const validateInput = {
        import_mode: type,
        item_unique_id_list: formattedData
          .map((data) => data.unique_id)
          .filter((unique_id) => unique_id !== null && unique_id !== undefined),
        uom_id_list: Array.from(
          new Set([
            ...formattedData.map((data) => data.stock_uom),
            ...formattedData.map((data) => data.sales_uom),
            ...formattedData.map((data) => data.purchase_uom),
          ])
        ).filter((id) => id !== null && id),
        item_cate_1_id_list: formattedData
          .map((data) => data.cate_1)
          .filter((cate_1) => cate_1 !== null && cate_1 !== undefined),
        item_cate_2_id_list: formattedData
          .map((data) => data.cate_2)
          .filter((cate_2) => cate_2 !== null && cate_2 !== undefined),
        item_cate_3_id_list: formattedData
          .map((data) => data.cate_3)
          .filter((cate_3) => cate_3 !== null && cate_3 !== undefined),
        stock_uom_id_list: formattedData
          .map((data) => data.stock_uom)
          .filter((stock_uom) => stock_uom !== null && stock_uom !== undefined),
        sales_uom_id_list: formattedData
          .map((data) => data.sales_uom)
          .filter((sales_uom) => sales_uom !== null && sales_uom !== undefined),
        purchase_uom_id_list: formattedData
          .map((data) => data.purchase_uom)
          .filter(
            (purchase_uom) =>
              purchase_uom !== null && purchase_uom !== undefined
          ),
      };

      setValidateInput(validateInput);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else if (tempDoc.length > 0) {
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
      }
      // else {
      //     enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
      //         variant: "error",
      //     });
      // }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();
      const existingItemUniqueIds =
        data?.ValidateItemImport.existing_item_unique_id_list || [];
      const missingItemUniqueId =
        data?.ValidateItemImport.missing_item_unique_id_list || [];
      // const missingUomUniqueId =
      //     data?.ValidateItemImport.missing_uom_id_list || [];
      const missingCate1UniqueId =
        data?.ValidateItemImport.missing_cate_1_id_list || [];
      const missingCate2UniqueId =
        data?.ValidateItemImport.missing_cate_2_id_list || [];
      const missingCate3UniqueId =
        data?.ValidateItemImport.missing_cate_3_id_list || [];
      const missingStockUom =
        data?.ValidateItemImport.missing_stock_uom_id_list || [];
      const missingSalesUom =
        data?.ValidateItemImport.missing_sales_uom_id_list || [];
      const missingPurchaseUom =
        data?.ValidateItemImport.missing_purchase_uom_id_list || [];
      const arrays = [
        existingItemUniqueIds,
        missingItemUniqueId,
        // missingUomUniqueId,
        missingCate1UniqueId,
        missingCate2UniqueId,
        missingCate3UniqueId,
        missingStockUom,
        missingSalesUom,
        missingPurchaseUom,
      ];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        "ข้อมูลซ้ำกับในระบบ",
        `${t(`inventory.item.unique_id`)}`,
        existingItemUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `${t(`inventory.item.unique_id`)}`,
        missingItemUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        `${t(`inventory.item.general.cate_1`)}`,
        missingCate1UniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        `${t(`inventory.item.general.cate_2`)}`,
        missingCate2UniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        `${t(`inventory.item.general.cate_3`)}`,
        missingCate3UniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        `${t(`inventory.item.warehouse.uom.stock_uom`)}`,
        missingStockUom
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        `${t(`inventory.item.warehouse.uom.sales_uom`)}`,
        missingSalesUom
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        `${t(`inventory.item.warehouse.uom.purchase_uom`)}`,
        missingPurchaseUom
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไฟล์สินค้าไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await createOrUpdate({
            importInput: {
              import_mode: type,
              data: rowData,
              // priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าไฟล์สินค้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไฟล์สินค้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไฟล์สินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
  return {
    rowData,
    isLoading: isValidating || isCreatingOrUpdating,
    validateHandler,
    importHandler,
  };
};
export default useServiceItemImporter;
