import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormContext } from "react-hook-form";

import { useTireListSwapPositionHeader } from "hooks/Logistic/use-tire-list-table-header";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ModalUI from "../ModalUI";
import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledTextField from "components/Controller/ControlledTextField";

import { formatNumber } from "utils/Global";
import { IVehicle, IVehicleTire } from "types/Logistic/vehicle";
import { ISelectOption } from "types/global";
import { useSnackbar } from "notistack";

type CheckedTireModalProps = {
  showTable: boolean;
  closeTableHandler: () => void;
  fields?: IVehicleTire[];
  tireOptions: ISelectOption[];
  tireCount: number;
};

const TireSwapPositionModal = ({
  showTable,
  closeTableHandler,
  fields,
  tireOptions,
  tireCount,
}: CheckedTireModalProps) => {
  const { t } = useTranslation();
  const headers = useTireListSwapPositionHeader();
  const { enqueueSnackbar } = useSnackbar();

  const { setValue } = useFormContext<IVehicle>();

  const {
    control: modalControl,
    getValues: getValuesModal,
    setValue: setValueModel,
    handleSubmit,
    reset,
    watch,
  } = useForm<{ tire: IVehicleTire[] }>({
    defaultValues: { tire: fields },
  });

  const watchedTires = watch("tire"); // Watch the entire array

  useEffect(() => {
    if (fields) {
      reset({ tire: fields });
    }
  }, [fields, reset]);

  const submitHandler = () => {
    try {
      const currentValues = getValuesModal("tire"); // Get the current values of the "tire" array

      const seenPositions = new Set();
      const duplicates = currentValues.filter((tire) => {
        if (!tire.position) return false; // Ignore empty positions
        if (seenPositions.has(tire.position) && tire.position !== "spare")
          return true;
        seenPositions.add(tire.position);
        return false;
      });

      if (duplicates.length > 0)
        return enqueueSnackbar("ตำแหน่งยางไม่สามารถซ้ำกันได้", {
          variant: "error",
        });

      if (
        currentValues.filter((tire) => tire.position === "spare").length >
        tireCount
      )
        return enqueueSnackbar("จำนวนยางสำรองเกินที่กำหนด", {
          variant: "error",
        });

      // Add `is_change_position` field to changed tires
      const updatedTires = currentValues.map((currentTire) => {
        if (currentTire.action_type) return currentTire;
        const originalTire = fields?.find(
          (tire) => tire.tire_id === currentTire.tire_id
        );

        return {
          ...currentTire,
          previous_position: originalTire?.position,
          is_change_position:
            originalTire?.position !== currentTire.position ? true : undefined,
        };
      });

      // Separate into `main_tire` and `spare_tire`
      const mainTires = updatedTires.filter(
        (tire) => tire.position !== "spare"
      );
      const spareTires = updatedTires.filter(
        (tire) => tire.position === "spare"
      );

      // Update the form with the segregated values
      setValue("main_tire", mainTires);
      setValue("spare_tire", spareTires);

      closeTableHandler();
      enqueueSnackbar("เปลี่ยนตำแหน่งสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("เปลี่ยนตำแหน่งไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const checkDuplicate = (
    initialPosition: string = "0",
    tire_id: number = 0
  ) => {
    return watchedTires.some(
      (tire) =>
        tire.position === initialPosition &&
        tire.tire_id !== tire_id &&
        tire.position !== "spare"
    );
  };

  const disabledRemark = (
    initialPosition: string = "0",
    tire_id: number = 0
  ) => {
    const watchedTire = fields?.find((t) => t.tire_id === tire_id);
    return watchedTire?.position === initialPosition;
  };

  return (
    <ModalUI
      open={showTable}
      handleClose={closeTableHandler}
      title={t("logistic.vehicle.tire_info.swap_position")}
      maxWidth="lg"
      action={
        <ButtonLayout>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={closeTableHandler}
          />
          <CustomizedButton
            title={t("button.confirm")}
            onClick={handleSubmit(submitHandler)}
            variant="contained"
            size="medium"
          />
        </ButtonLayout>
      }
    >
      <Box sx={{ breakAfter: "auto" }} maxWidth={1650} my={2}>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      px: 1,
                      py: 1.5,
                      backgroundColor: "secondary.main",
                    }}
                    width={header.width}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        width: header.width,
                        justifyContent: header.align || "center",
                        padding: "none",
                      }}
                    >
                      <Typography fontSize={14} fontWeight={600}>
                        {header.label}
                      </Typography>
                      {header.required && (
                        <Typography
                          fontSize={14}
                          color={"error.main"}
                          fontWeight={600}
                        >
                          *
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {watchedTires?.map((field, index) => (
                <TableRow sx={{ height: "4rem" }}>
                  <TableCell align="left">{field.serial}</TableCell>
                  <TableCell align="left">{field.band}</TableCell>
                  <TableCell align="left">{field.model}</TableCell>
                  <TableCell align="left">
                    <ControlledSelect
                      control={modalControl}
                      name={`tire.${index}.position`}
                      options={tireOptions.map(({ disabled, ...data }) => ({
                        ...data,
                      }))}
                      onChange={(e: any) => {
                        const newValue = e.target.value; // Update the value
                        if (disabledRemark(newValue, field.tire_id)) {
                          setValueModel(`tire.${index}.remark`, "");
                        }
                      }}
                      error={checkDuplicate(field.position, field.tire_id)}
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ paddingRight: "1rem !important" }}
                  >
                    {formatNumber(field.current_distance, true)}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ paddingRight: "1rem !important" }}
                  >
                    {formatNumber(field.due_distance, true)}
                  </TableCell>
                  <TableCell align="left">
                    <ControlledTextField
                      control={modalControl}
                      name={`tire.${index}.remark`}
                      placeholder="หมายเหตุ"
                      disabled={disabledRemark(field.position, field.tire_id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ModalUI>
  );
};

export default TireSwapPositionModal;
