import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu } from "types/global";
import { Fragment } from "react";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import { Navigate } from "react-router-dom";

const ContactLanding = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    permissions?.customer?.view &&
      permissions?.supplier?.view && {
        name: t("contact.contact_type.all"),
        to: "all",
      },
    permissions?.customer?.view && {
      name: t("contact.contact_type.customer"),
      to: "customer",
    },
    permissions?.supplier?.view && {
      name: t("contact.contact_type.supplier"),
      to: "supplier",
    },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  if (permissions && !permissions.contact?.view) {
    return <Navigate replace to="/unauthorized" />;
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default ContactLanding;
