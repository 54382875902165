import { useTranslation } from "react-i18next";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";

import { IBreadcrumbsAndMenu } from "types/global";

const Sales = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    permissions?.quotation?.view && {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
    },
    permissions?.billing_note?.view && {
      name: t("sales.billing_note.index"),
      to: "/sales/billing-note",
    },
    permissions?.sales_export?.view && {
      name: t("report"),
      to: "/sales/report",
    },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  if (permissions && !permissions.sales?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Sales;
