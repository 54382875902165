import { Box, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <img
        src="/static/logo.jpg"
        alt="logo"
        style={{
          height: 90,
        }}
      />
      <img
        src="/static/unauthorized.png"
        alt="unauthorized"
        style={{
          marginTop: "-1rem",
          height: "200px",
          width: "200px",
        }}
      />
      <Box>
        <Typography variant="h5" align="center">
          ขออภัย การเข้าถึงถูกปฏิเสธ
        </Typography>
        <Typography
          align="center"
          sx={{
            color: "#737373",
          }}
        >
          คุณไม่มีสิทธิ์เข้าถึงข้อมูลนี้
        </Typography>
      </Box>
      <CustomizedButton
        variant="contained"
        title="กลับหน้าหลัก"
        onClick={() => navigate("/landing")}
        size="medium"
      />
    </Box>
  );
};

export default Unauthorized;
