import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "hooks/use-disable";
import { useConfirmation } from "hooks/use-confirmation";
import { useQuotationOption } from "hooks/Sales/Quotation/use-quotation-option";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import CustomizedButton from "components/Custom/CustomizedButton";
import { createDeliveryTripFromQuotation } from "utils/Formatter/Sales/Quotation";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import EmployeeListForm from "../../EmployeeList";
import Confirmation from "../../../UI/Confirmation";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import GreenCheckIcon from "components/UI/GreenCheckIcon";

import { IMenuOption } from "../../../../types/global";
import { IQuotation } from "types/Sales/quotation";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "../../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { copyQuotationFormatter } from "../../../../utils/Formatter/Sales/Quotation";

import CancelConfirmation from "components/UI/CancelConfirmation";

type Props = {
  editClickHandler: () => void;
  cancelHandler?: () => Promise<void>;
  notAcceptHandler: () => Promise<void>;
};

const QuotationHeader = ({
  cancelHandler,
  editClickHandler,
  notAcceptHandler,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled] = useDisable();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IQuotation>();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("sales.quotation.index")}`,
      disabled: !id,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.Quotation,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useQuotationOption(
    status,
    disabled,
    permissions?.quotation
  );

  const copyDocumentHandler = async () => {
    const order = getValues();

    navigate("/sales/quotation/add", {
      state: await copyQuotationFormatter(order, authUser),
    });
  };

  const createDeliveryTripHandler = () => {
    const quotation = getValues();
    navigate("/logistic/delivery-trip/add", {
      state: createDeliveryTripFromQuotation(quotation),
    });
  };

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  const {
    confirmation: notAcceptConfirmation,
    openConfirmationHandler: openNotAcceptConfirmation,
    closeConfirmationHandler: closeNotAcceptConfirmation,
    submitConfirmationHandler: submitNotAcceptConfirmation,
  } = useConfirmation(notAcceptHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("sales.quotation.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid
              container
              spacing={1.5}
              justifyContent="end"
              alignItems="center"
            >
              {permissions?.quotation?.view &&
                status !== "cancelled" &&
                status !== "not_accepted" && (
                  <Grid item sm={5.5} md={4}>
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={"พิมพ์เอกสาร"}
                      options={printOptions}
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        if (
                          value.innerText ===
                          `พิมพ์${t("sales.quotation.index")}`
                        ) {
                          navigate(`/sales/quotation/${id}/pdf`);
                        }
                      }}
                      disabled={!id}
                    />
                  </Grid>
                )}
              {selectModifyOptions?.length > 0 && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "คัดลอก":
                          openCopyConfirmation();
                          break;
                        case "ยกเลิก":
                          openCancelConfirmation();
                          break;
                        case "ไม่ตอบรับใบเสนอราคา":
                          openNotAcceptConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                </Grid>
              )}
              {permissions?.delivery_trip?.update &&
                (status === "accepted" || status === "finished") && (
                  <Grid item sm={5.5} md={4}>
                    <CustomizedButton
                      title={t("button.create") + t("ใบจัดส่ง")}
                      variant="contained"
                      fullWidth
                      onClick={createDeliveryTripHandler}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={3}>
              <Typography fontWeight={600}>{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("sales.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id)}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Typography fontWeight={600}>
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box>
                <EmployeeListForm />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled || status === "wait_accept"}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                label={t("date.to_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                name="credit_day"
                control={control}
                label={t("sales.credit_day")}
                disabled={disabled}
                viewMode={disabled}
                decimalScale={0}
                textAlign="left"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CancelConfirmation
        uniqueId={getValues("unique_id") || ""}
        confirmation={cancelConfirmation}
        closeConfirmationHandler={closeCancelConfirmation}
        submitConfirmationHandler={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <ConfirmationModal
        title={"ยืนยันการปรับสถานะ"}
        titleSize={"24px"}
        open={notAcceptConfirmation}
        handleClose={closeNotAcceptConfirmation}
        action={submitNotAcceptConfirmation}
        noDivider
        message={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box mb={1}>
              <GreenCheckIcon size={64} />
            </Box>
            <Box>
              <Typography>กรุณายืนยืนการปรับสถานะใบเสนอราคา</Typography>
              <Typography>{getValues("unique_id")}</Typography>
              <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                <Typography>จาก</Typography>
                <Typography fontWeight={600}>รอตอบรับ</Typography>
                <Typography>เป็น</Typography>
                <Typography fontWeight={600}>ไม่ตอบรับ</Typography>
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default QuotationHeader;
