import * as Yup from "yup";
import { Box, IconButton, Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModal } from "hooks/use-modal";
import { useInventoryLocation } from "hooks/Inventory/use-inventory-location";
import LocationModal from "components/UI/Modal/LocationModal";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomizedTreeItem from "components/Custom/CustomizedTreeItem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { stringMaxInput } from "utils/Validate";

interface IDefault {
  unique_id?: string;
  value: string;
}

const LocationSetting = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any[]>();
  const [title, setTitle] = useState<string>("");
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.location.index"),
    },
  ];

  const validation = Yup.object().shape({
    unique_id:
      title === "addWarehouse"
        ? stringMaxInput(15, undefined, true)
        : Yup.string(),
    value: stringMaxInput(200, undefined, true),
  });

  const methods = useForm<IDefault>({
    defaultValues: {
      value: "",
      unique_id: "",
    },
    resolver: yupResolver<any>(validation),
  });

  const { reset, setValue } = methods;

  const handleToggle = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpanded(itemIds);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const handleOpen = ({
    title,
    location,
  }: {
    title: string;
    location?: any;
  }) => {
    if (title === "addLocation" || title === "addWarehouse") {
      setValue("value", "");
    } else {
      setValue("value", location[location.length - 1].name);
    }
    if (location) {
      setSelectedLocation(location);
      setTitle(title);
    } else {
      setTitle("addWarehouse");
    }

    openModalHandler();
  };

  const handleClose = async () => {
    await refetch();
    closeModalHandler();
    setTimeout(() => {
      setTitle("");
      setSelectedLocation(undefined);
    }, 150);

    reset();
  };

  const {
    warehouses,
    allWarehouseIds,
    refetch,
    handleSubmitCreate,
    handleSubmitUpdate,
    handleSubmitDelete,
    refetchUniqueId,
  } = useInventoryLocation(selectedLocation, handleClose);

  const onSubmit = (data: IDefault) => {
    switch (title) {
      case "addLocation":
      case "addWarehouse":
        handleSubmitCreate(data);
        break;
      case "editWarehouse":
      case "editLocation":
        handleSubmitUpdate(data);
        break;
      case "deleteWarehouse":
      case "deleteLocation":
        handleSubmitDelete();
        break;
      default:
        break;
    }
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? allWarehouseIds : []
    );
  };

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate);
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (modal && !selectedLocation) {
      generateUniqueId();
    }
  }, [generateUniqueId, modal, selectedLocation]);

  if (permissions && !permissions.inventory_setting?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        my={2}
        alignItems={"center"}
        maxWidth={1040}
      >
        <Typography variant="h5">
          {t("setting.inventory.location.index")}
        </Typography>
        {permissions?.inventory_setting?.create && (
          <CustomizedButton
            title={t("button.add") + t("setting.inventory.warehouse.index")}
            startAddIcon
            variant="contained"
            onClick={() => handleOpen({ title: "addLocation" })}
          />
        )}
      </Box>
      {warehouses && warehouses.length > 0 && (
        <Box maxWidth={1040} display={"flex"} justifyContent={"flex-end"}>
          <Box
            onClick={handleExpandClick}
            sx={{
              display: "flex",
              cursor: "pointer",
              width: "fit-content",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {expanded.length === 0 ? (
              <KeyboardArrowRightIcon sx={{ fontSize: 16 }} color="primary" />
            ) : (
              <KeyboardArrowDownIcon sx={{ fontSize: 16 }} color="primary" />
            )}
            <Typography color={"primary.main"}>
              {expanded.length === 0 ? "เปิดทั้งหมด" : "ซ่อนทั้งหมด"}
            </Typography>
          </Box>
        </Box>
      )}
      <SimpleTreeView
        expandedItems={expanded}
        onExpandedItemsChange={handleToggle}
        disableSelection
      >
        {warehouses &&
          warehouses.length > 0 &&
          warehouses.map((warehouse) => (
            <CustomizedBox width={"100%"} key={warehouse.unique_id}>
              <CustomizedTreeItem
                itemId={`${warehouse.unique_id}`}
                label={
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography fontWeight={600}>{warehouse.name}</Typography>
                    <Typography color={"gray.font"}>
                      รหัส {warehouse.unique_id}
                    </Typography>
                    <Box>
                      {permissions?.inventory_setting?.update && (
                        <IconButton
                          onClick={() =>
                            handleOpen({
                              title: "editWarehouse",
                              location: [warehouse],
                            })
                          }
                          color={"primary"}
                        >
                          <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                      )}
                      {permissions?.inventory_setting?.delete && (
                        <IconButton
                          onClick={() =>
                            handleOpen({
                              title: "deleteWarehouse",
                              location: [warehouse],
                            })
                          }
                          color={"primary"}
                          disabled={Boolean(
                            warehouse.warehouse_level_1_list &&
                              warehouse.warehouse_level_1_list.length > 0
                          )}
                        >
                          <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                }
              >
                {permissions?.inventory_setting?.create && (
                  <CustomizedButton
                    startAddIcon
                    title={t(`setting.inventory.location.addLocation`)}
                    onClick={() =>
                      handleOpen({
                        title: "addLocation",
                        location: [warehouse],
                      })
                    }
                  />
                )}
                {warehouse.warehouse_level_1_list &&
                  warehouse.warehouse_level_1_list.length > 0 &&
                  warehouse.warehouse_level_1_list.map((locationLv1) => (
                    <CustomizedTreeItem
                      key={`${warehouse.unique_id + locationLv1.id}`}
                      itemId={`${warehouse.unique_id + locationLv1.id}`}
                      label={
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                          <Typography fontWeight={600}>
                            {locationLv1.name}
                          </Typography>
                          <Box>
                            {permissions?.inventory_setting?.update && (
                              <IconButton
                                onClick={() =>
                                  handleOpen({
                                    title: "editLocation",
                                    location: [warehouse, locationLv1],
                                  })
                                }
                                color={"primary"}
                              >
                                <EditIcon sx={{ fontSize: 16 }} />
                              </IconButton>
                            )}
                            {permissions?.inventory_setting?.delete && (
                              <IconButton
                                onClick={() =>
                                  handleOpen({
                                    title: "deleteLocation",
                                    location: [warehouse, locationLv1],
                                  })
                                }
                                color={"primary"}
                                disabled={Boolean(
                                  locationLv1.warehouse_level_2_list &&
                                    locationLv1.warehouse_level_2_list.length >
                                      0
                                )}
                              >
                                <DeleteForeverIcon sx={{ fontSize: 16 }} />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      }
                    >
                      {permissions?.inventory_setting?.create && (
                        <CustomizedButton
                          startAddIcon
                          title={t(`setting.inventory.location.addLocation`)}
                          onClick={() =>
                            handleOpen({
                              title: "addLocation",
                              location: [warehouse, locationLv1],
                            })
                          }
                        />
                      )}
                      {locationLv1.warehouse_level_2_list &&
                        locationLv1.warehouse_level_2_list.length > 0 &&
                        locationLv1.warehouse_level_2_list.map(
                          (locationLv2) => (
                            <CustomizedTreeItem
                              key={`${
                                warehouse.unique_id +
                                locationLv1.id +
                                locationLv2.id
                              }`}
                              itemId={`${
                                warehouse.unique_id +
                                locationLv1.id +
                                locationLv2.id
                              }`}
                              label={
                                <Box
                                  display={"flex"}
                                  gap={1}
                                  alignItems={"center"}
                                >
                                  <Typography fontWeight={600}>
                                    {locationLv2.name}
                                  </Typography>
                                  <Box>
                                    {permissions?.inventory_setting?.update && (
                                      <IconButton
                                        onClick={() =>
                                          handleOpen({
                                            title: "editLocation",
                                            location: [
                                              warehouse,
                                              locationLv1,
                                              locationLv2,
                                            ],
                                          })
                                        }
                                        color={"primary"}
                                      >
                                        <EditIcon sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    )}
                                    {permissions?.inventory_setting?.delete && (
                                      <IconButton
                                        onClick={() =>
                                          handleOpen({
                                            title: "deleteLocation",
                                            location: [
                                              warehouse,
                                              locationLv1,
                                              locationLv2,
                                            ],
                                          })
                                        }
                                        color={"primary"}
                                        disabled={Boolean(
                                          locationLv2.warehouse_level_3_list &&
                                            locationLv2.warehouse_level_3_list
                                              .length > 0
                                        )}
                                      >
                                        <DeleteForeverIcon
                                          sx={{ fontSize: 16 }}
                                        />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                              }
                            >
                              {permissions?.inventory_setting?.create && (
                                <CustomizedButton
                                  startAddIcon
                                  title={t(
                                    `setting.inventory.location.addLocation`
                                  )}
                                  onClick={() =>
                                    handleOpen({
                                      title: "addLocation",
                                      location: [
                                        warehouse,
                                        locationLv1,
                                        locationLv2,
                                      ],
                                    })
                                  }
                                />
                              )}

                              {locationLv2.warehouse_level_3_list &&
                                locationLv2.warehouse_level_3_list.length > 0 &&
                                locationLv2.warehouse_level_3_list.map(
                                  (locationLv3) => (
                                    <CustomizedTreeItem
                                      key={`${
                                        warehouse.unique_id +
                                        locationLv1.id +
                                        locationLv2.id +
                                        locationLv3.id
                                      }`}
                                      itemId={`${
                                        warehouse.unique_id +
                                        locationLv1.id +
                                        locationLv2.id +
                                        locationLv3.id
                                      }`}
                                      label={
                                        <Box
                                          display={"flex"}
                                          gap={1}
                                          alignItems={"center"}
                                        >
                                          <Typography fontWeight={600}>
                                            {locationLv3.name}
                                          </Typography>
                                          <Box>
                                            {permissions?.inventory_setting
                                              ?.update && (
                                              <IconButton
                                                onClick={() =>
                                                  handleOpen({
                                                    title: "editLocation",
                                                    location: [
                                                      warehouse,
                                                      locationLv1,
                                                      locationLv2,
                                                      locationLv3,
                                                    ],
                                                  })
                                                }
                                                color={"primary"}
                                              >
                                                <EditIcon
                                                  sx={{ fontSize: 16 }}
                                                />
                                              </IconButton>
                                            )}
                                            {permissions?.inventory_setting
                                              ?.delete && (
                                              <IconButton
                                                onClick={() =>
                                                  handleOpen({
                                                    title: "deleteLocation",
                                                    location: [
                                                      warehouse,
                                                      locationLv1,
                                                      locationLv2,
                                                      locationLv3,
                                                    ],
                                                  })
                                                }
                                                color={"primary"}
                                                disabled={Boolean(
                                                  locationLv3.bin_location_list &&
                                                    locationLv3
                                                      .bin_location_list
                                                      .length > 0
                                                )}
                                              >
                                                <DeleteForeverIcon
                                                  sx={{ fontSize: 16 }}
                                                />
                                              </IconButton>
                                            )}
                                          </Box>
                                        </Box>
                                      }
                                    >
                                      {permissions?.inventory_setting
                                        ?.create && (
                                        <CustomizedButton
                                          startAddIcon
                                          title={t(
                                            `setting.inventory.location.addLocation`
                                          )}
                                          onClick={() =>
                                            handleOpen({
                                              title: "addLocation",
                                              location: [
                                                warehouse,
                                                locationLv1,
                                                locationLv2,
                                                locationLv3,
                                              ],
                                            })
                                          }
                                        />
                                      )}

                                      {locationLv3.bin_location_list &&
                                        locationLv3.bin_location_list.length >
                                          0 &&
                                        locationLv3.bin_location_list.map(
                                          (binLocation) => (
                                            <CustomizedTreeItem
                                              key={binLocation.name}
                                              itemId={`${binLocation.name}`}
                                              label={
                                                <Box
                                                  display={"flex"}
                                                  gap={1}
                                                  alignItems={"center"}
                                                >
                                                  <Typography fontWeight={600}>
                                                    {binLocation.name}
                                                  </Typography>
                                                  <Box>
                                                    {permissions
                                                      ?.inventory_setting
                                                      ?.update && (
                                                      <IconButton
                                                        onClick={() =>
                                                          handleOpen({
                                                            title:
                                                              "editLocation",
                                                            location: [
                                                              warehouse,
                                                              locationLv1,
                                                              locationLv2,
                                                              locationLv3,
                                                              binLocation,
                                                            ],
                                                          })
                                                        }
                                                        color={"primary"}
                                                      >
                                                        <EditIcon
                                                          sx={{ fontSize: 16 }}
                                                        />
                                                      </IconButton>
                                                    )}
                                                    {permissions
                                                      ?.inventory_setting
                                                      ?.delete && (
                                                      <IconButton
                                                        onClick={() =>
                                                          handleOpen({
                                                            title:
                                                              "deleteLocation",
                                                            location: [
                                                              warehouse,
                                                              locationLv1,
                                                              locationLv2,
                                                              locationLv3,
                                                              binLocation,
                                                            ],
                                                          })
                                                        }
                                                        color={"primary"}
                                                      >
                                                        <DeleteForeverIcon
                                                          sx={{ fontSize: 16 }}
                                                        />
                                                      </IconButton>
                                                    )}
                                                  </Box>
                                                </Box>
                                              }
                                            />
                                          )
                                        )}
                                    </CustomizedTreeItem>
                                  )
                                )}
                            </CustomizedTreeItem>
                          )
                        )}
                    </CustomizedTreeItem>
                  ))}
              </CustomizedTreeItem>
            </CustomizedBox>
          ))}
      </SimpleTreeView>
      <FormProvider {...methods}>
        <LocationModal
          title={title}
          open={modal}
          closeModalHandler={handleClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </>
  );
};

export default LocationSetting;
