import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { USERS_AGGRID } from "../../../../services/AgGrid/UserAgGrid";
import { RoleFindUniqueQuery, useRoleFindUniqueQuery } from "generated/general";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import CheckboxAggridModal from "../CheckboxAggridModal";
import { useUserColumnDefs } from "../../../Table/User/columnDefs";

type CheckedUserModalProps = {
  showSelectUser: boolean;
  closeUserTable: () => void;
  finishUsersSelect: (data: any) => void;
  userIds: string[];
  setUserIds: Dispatch<SetStateAction<string[]>>;
  userIdsSnapshot: string[];
  setUserIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  lockRows?: (string | undefined)[];
  rowSelection: "single" | "multiple";
  isDriver?: boolean;
  billingNote?: boolean;
  maxSelectionLimit?: number;
  isRole?: boolean;
  roleId?: number;
};

const CheckedUserModal = ({
  showSelectUser,
  closeUserTable,
  finishUsersSelect,
  userIds,
  setUserIds,
  userIdsSnapshot,
  setUserIdsSnapshot,
  rowSelection,
  lockRows,
  isDriver,
  maxSelectionLimit,
  billingNote,
  isRole,
  roleId,
}: CheckedUserModalProps) => {
  const { t } = useTranslation();
  const selectUserGridRef = useRef();

  const columnDefs = useUserColumnDefs(
    undefined,
    isDriver,
    billingNote,
    isRole
  );

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: noPermissionRole } =
    useRoleFindUniqueQuery<RoleFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          name: "ไม่มีสิทธิ์",
        },
      },
      {
        enabled: typeof roleId !== "undefined" && roleId !== null,
        cacheTime: 0,
      }
    );

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, ...filter } = filterModel;
      const formatFilter = {
        ...filter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        _or: roleId
          ? {
              _orArray: [
                {
                  role_id_list: {
                    type: "hasSome",
                    filterType: "array",
                    values: [
                      roleId,
                      noPermissionRole?.RoleFindUnique?.id,
                    ].filter((v) => typeof v !== "undefined"),
                  },
                },
                {
                  role_id_list: {
                    filterType: "array",
                    type: "isEmpty",
                    values: true,
                  },
                },
              ],
            }
          : undefined,
      };

      try {
        const { UsersFindManyAggrid } = await graphQLClientGeneral.request(
          USERS_AGGRID,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: UsersFindManyAggrid.results as any[],
          rowCount: UsersFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instanceStatus = params.api.getFilterInstance("status");
    instanceStatus?.setModel({
      values: ["1"],
    });
    if (isDriver) {
      params.api.setFilterModel({
        position: {
          type: "set",
          values: ["พนักงานขนส่ง"],
        },
        status: {
          type: "set",
          values: ["1"],
        },
      });
    }
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={isDriver ? "พนักงานขนส่ง" : "ผู้เกี่ยวข้อง"}
      isCancelable={isDriver}
      btnTitle={t("button.confirm")}
      gridRef={selectUserGridRef}
      height={649}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishUsersSelect}
      modalIsOpen={showSelectUser}
      getRowId={getRowId}
      closeModal={closeUserTable}
      selectedIds={userIds}
      setSelectedIds={setUserIds}
      idsSnapshot={userIdsSnapshot}
      setIdsSnapshot={setUserIdsSnapshot}
      onGridReady={onGridReady}
      lockRows={lockRows}
      maxSelectionLimit={maxSelectionLimit}
    />
  );
};

export default CheckedUserModal;
