import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "types/global";

const Inventory = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    permissions?.item?.view && {
      name: t("inventory.item.index"),
      to: "/inventory/item",
    },
    permissions?.goods_receive?.view && {
      name: t("inventory.goods_receive.index"),
      to: "/inventory/goods-receive",
    },
    permissions?.goods_issue?.view && {
      name: t("inventory.goods_issue.index"),
      to: "/inventory/goods-issue",
    },
    permissions?.goods_transfer?.view && {
      name: t("inventory.goods_transfer.index"),
      to: "/inventory/goods-transfer",
    },
    permissions?.goods_adjust?.view && {
      name: t("inventory.goods_adjust.index"),
      to: "/inventory/goods-adjust",
    },
    permissions?.inventory_export?.view && {
      name: t("inventory.item.report"),
      to: "/inventory/report",
    },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  if (permissions && !permissions?.inventory?.view) {
    return <Navigate replace to="/unauthorized" />;
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Inventory;
