import { Typography, Stack } from "@mui/material";
import LoadingAnimation from "components/Animation/Loading";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import ItemImportUploader from "components/Form/Inventory/Item/ImportUploader";
import ItemImporterTable from "components/Table/Inventory/Item/ImporterTable";
import ImporterErrorModal from "components/UI/Modal/ImporterErrorModal";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import { ItemImportType } from "generated/wms";
import { useNormalItemImporter } from "hooks/Importer/Inventory/use-normal-item-importer";
import useServiceItemImporter from "hooks/Importer/Inventory/use-service-item-importer";
import { useModal } from "hooks/use-modal";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "types/global";
import { useStateContext } from "contexts/auth-context";

const ItemImporter = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.item.index"),
      to: "/inventory/item",
    },
    {
      name: "นำเข้าไฟล์สินค้า",
      to: "/inventory/item/import",
    },
  ];
  const navigate = useNavigate();

  const [errorData, setErrorData] = useState<any[]>([]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const methods = useForm<any>({
    defaultValues: {
      import_type: ItemImportType.Normal,
      type: "create",
      file: {},
      step: 0,
      errors: [],
    },
  });

  const { watch, getValues, setValue } = methods;
  const activeStep = watch("step");
  const importType = watch("import_type");
  const watchType = watch("type");

  // const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const {
    importHandler: importNormal,
    isLoading: isLoadingNormal,
    rowData: normalItemData,
    validateHandler: validateNormal,
  } = useNormalItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    importHandler: importService,
    isLoading: isLoadingService,
    rowData: serviceItemData,
    validateHandler: validateService,
  } = useServiceItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const rowDataCondition = () => {
    if (importType === "normal") return normalItemData;
    else if (importType === "service") return serviceItemData;
    else return [];
  };

  const validateCondition = () => {
    if (importType === "normal") return validateNormal();
    else if (importType === "service") return validateService();
  };

  const importCondition = () => {
    if (importType === "normal") return importNormal();
    else if (importType === "service") return importService();
  };

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <ItemImportUploader />;
    } else {
      return (
        <>
          {/* {step === 3 && (
                        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
                    )} */}
          <ItemImporterTable data={rowDataCondition()} />
        </>
      );
    }
  };
  const renderButton = (step: number, importType: string) => {
    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateCondition}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importCondition}
            />
          </>
        );
      case 3:
        const title =
          importType === "normal" ? "ดูสินค้าทั้งหมด" : "ดูบริการทั้งหมด";
        const filter = importType === "normal" ? "normal" : "service";
        return (
          <CustomizedButton
            title={title}
            variant="contained"
            onClick={() => navigate(`/inventory/item?filter=${filter}`)}
          />
        );
      default:
        return null;
    }
  };

  if (isLoadingNormal || isLoadingService) {
    return <LoadingAnimation />;
  }

  if (permissions && !permissions.item?.create) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {"นำเข้าไฟล์สินค้า"}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>{renderContent(activeStep)}</CustomizedBox>
      </FormProvider>
      <BottomNavbar>
        <Stack direction={"row"} gap={1}>
          {renderButton(activeStep, importType)}
        </Stack>
      </BottomNavbar>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default ItemImporter;
