import { gql } from "graphql-request";

export const USERS_AGGRID = gql`
  query UsersFindManyAggrid($aggridInput: AnyAggridInput) {
    UsersFindManyAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        email
        img_url
        title_name
        first_name
        last_name
        nick_name
        gender
        phone
        department
        position
        status
        remark_status
        inactive_date
        created_date
        created_by
        last_updated_date
        last_updated_by
        delivery_position_details {
          dob
          identity_no
          license_type
          license_no
          license_expire_date
        }
        role_list {
          id
          name
          description
          created_date
          created_by
          last_updated_date
          last_updated_by
          role_permission
        }
        last_login_date
      }
      count
    }
  }
`;

export const USER_VIEW_AGGRID = gql`
  query UserViewsAggrid($aggridInput: AnyAggridInput) {
    UserViewsAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        email
        img_url
        first_name
        last_name
        department
        position
        delivery_position_details
        license_expire_date
        status
        created_date
      }
      count
    }
  }
`;
