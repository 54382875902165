import dayjs from "dayjs";
import { IExpensesList, IIncomeList } from "types/Logistic";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { stringMaxInput } from "utils/Validate";

const incomeListData: IIncomeList[] = [
  {
    unique_id: uuidv4(),
    item_id: 157,
    item_unique_id: "SP00001",
    item_name: "ค่าขนส่ง",
    item_sku_desc: "ใช้สำหรับคิดค่าขนส่งสินค้าประเภท Chilled",
    qty: 1,
    uom_id: 170,
    uom_name: "เที่ยว",
    uom_conversion_list: [],
    discount: 0,
    pre_vat_amount: 0,
    price_per_unit: 0,
    withholding_tax_type: "ยังไม่ระบุ",
    withholding_tax_value: 0,
    vat_percentage: "ไม่มี",
    reference_unique_id: "",
  },
  {
    unique_id: uuidv4(),
    item_id: 159,
    item_unique_id: "SP00003",
    item_name: "ค่าแรง",
    item_sku_desc: "ใช้สำหรับคิดค่าแรงพนักงานขนส่ง",
    qty: 1,
    uom_id: 170,
    uom_name: "เที่ยว",
    uom_conversion_list: [],
    discount: 0,
    pre_vat_amount: 0,
    price_per_unit: 0,
    withholding_tax_type: "ยังไม่ระบุ",
    withholding_tax_value: 0,
    vat_percentage: "ไม่มี",
  },
];

export const expensesListData: IExpensesList[] = [
  {
    unique_id: uuidv4(),
    item_id: 160,
    item_unique_id: "SP00004",
    item_name: "ค่าน้ำมัน APPA (จริง)",
    item_sku_desc: "ใช้สำหรับคิดค่าน้ำมัน APPA",
    qty: 1,
    uom_id: 171,
    uom_name: "ลิตร",
    uom_conversion_list: [],
    discount: 0,
    pre_vat_amount: 0,
    price_per_unit: 0,
    withholding_tax_type: "3",
    withholding_tax_value: 0,
    vat_percentage: "ไม่มี",
  },
  {
    unique_id: uuidv4(),
    item_id: 161,
    item_unique_id: "SP00005",
    item_name: "ค่าเบี้ยเลี้ยงคนขับ",
    item_sku_desc: "ใช้สำหรับคิดค่าเบี้ยเลี้ยงคนขับ",
    qty: 1,
    uom_id: 170,
    uom_name: "เที่ยว",
    uom_conversion_list: [],
    discount: 0,
    pre_vat_amount: 0,
    price_per_unit: 0,
    withholding_tax_type: "3",
    withholding_tax_value: 0,
    vat_percentage: "ไม่มี",
  },
  {
    unique_id: uuidv4(),
    item_id: 162,
    item_unique_id: "SP00006",
    item_name: "ค่า Drop",
    item_sku_desc: "ใช้สำหรับคิดค่า Drop",
    qty: 1,
    uom_id: 172,
    uom_name: "จุด",
    uom_conversion_list: [],
    discount: 0,
    pre_vat_amount: 0,
    price_per_unit: 0,
    withholding_tax_type: "3",
    withholding_tax_value: 0,
    vat_percentage: "ไม่มี",
  },
];

export const deliveryTripSchema: IDeliveryTrip = {
  unique_id: "",
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  job_no: "",
  related_user_list: [],
  created_by: null,
  created_date: dayjs(),
  last_updated_by: undefined,
  last_updated_date: undefined,
  issue_date: dayjs(),
  vehicle_id: undefined,
  vehicle: {
    type: "",
    vehicle_no: "",
    license_plate: "",
  },
  trailer_id: undefined,
  trailer: {
    trailer_no: "",
    license_plate: "",
  },
  primary_driver: undefined,
  secondary_driver: undefined,
  customer_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      type: "",
      contact_name: "",
      contact_phone: "",
      fax: "",
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  container_type: "",
  item_type: "",
  item_amount: undefined,
  is_frozen: false,
  packaging_location: "",
  expected_temperature: undefined,
  plan_start_date: dayjs(),
  plan_end_date: dayjs(),
  actual_start_date: undefined,
  actual_end_date: undefined,
  start_kilometer: undefined,
  end_kilometer: undefined,
  total_kilometer_distance: undefined,
  temperature_start_hours: undefined,
  temperature_end_hours: undefined,
  total_temperature_hours: undefined,
  fuel: {
    appa: undefined,
    actual_fleet: undefined,
    other: undefined,
    total_quota: undefined,
    total_usage: undefined,
  },
  work_list: [],
  work_total_amount: 0,
  income_list: incomeListData,
  income_post_discount_amount: 0,
  income_vat_exempted_amount: 0,
  income_vat_0_percent_amount: 0,
  income_vat_7_percent_amount: 0,
  income_vat_amount: 0,
  income_net_amount: 0,
  income_withholding_tax_amount: 0,
  income_total_amount: 0,
  expenses_list: expensesListData,
  expenses_post_discount_amount: 0,
  expenses_vat_exempted_amount: 0,
  expenses_vat_0_percent_amount: 0,
  expenses_vat_7_percent_amount: 0,
  expenses_vat_amount: 0,
  expenses_net_amount: 0,
  expenses_withholding_tax_amount: 0,
  expenses_total_amount: 0,
  attachment_list: [],
  remark: "",
  reference_document_list: [],
};

const yupNumber = Yup.number().transform((value) =>
  isNaN(value) || value === null || value === undefined ? 0 : value
);

const workListValidation = Yup.object().shape({
  type: Yup.string().required("กรุณาระบุ"),
  coordinator_name: Yup.string().required("กรุณาระบุ"),
  coordinator_phone: Yup.string().required("กรุณาระบุ"),
  special_request: stringMaxInput(100, undefined, false),
  reference_code: stringMaxInput(200, undefined, false),
  seal_no: stringMaxInput(15, undefined, false),
  shipping_cost: yupNumber,
});

const incomeListValidation = Yup.object().shape({
  qty: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
  price_per_unit: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
});

const expensesListValidation = Yup.object().shape({
  qty: yupNumber.required("กรุณาระบุ"),
  price_per_unit: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
});

export const deliveryTripValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true),
  job_no: stringMaxInput(200, undefined, false),
  item_type: stringMaxInput(200, undefined, false),
  item_amount: stringMaxInput(200, undefined, false),
  packaging_location: stringMaxInput(200, undefined, false),
  expected_temperature: stringMaxInput(200, undefined, false),
  issue_date: Yup.date().required("กรุณาระบุ"),
  vehicle: Yup.object().shape({
    type: Yup.string().required("กรุณาระบุ"),
    vehicle_no: Yup.string().required("กรุณาระบุ"),
    license_plate: Yup.string().required("กรุณาระบุ"),
  }),
  main_status: Yup.string(),
  aggrid_status: Yup.string(),
  customer_unique_id: Yup.string().required("กรุณาระบุ"),
  plan_start_date: Yup.date().required("กรุณาระบุ"),
  plan_end_date: Yup.date()
    .required("กรุณาระบุ")
    .min(Yup.ref("plan_start_date"), "กรุณาระบุข้อมูลให้ถูกต้อง"),
  actual_start_date: Yup.date().when("main_status", {
    is: "delivering",
    then: (schema) => schema.required("กรุณาระบุ"),
  }),
  actual_end_date: Yup.date().when("main_status", {
    is: "delivering",
    then: (schema) =>
      schema
        .required("กรุณาระบุ")
        .min(Yup.ref("actual_start_date"), "กรุณาระบุข้อมูลให้ถูกต้อง"),
  }),
  start_kilometer: yupNumber.required("กรุณาระบุ"),
  end_kilometer: yupNumber.when("main_status", {
    is: "delivering",
    then: (schema) => schema.positive("กรุณาระบุ").required("กรุณาระบุ"),
  }),
  total_kilometer_distance: yupNumber.when("main_status", {
    is: "delivering",
    then: (schema) =>
      schema.min(0, "กรุณาระบุข้อมูลให้ถูกต้อง").required("กรุณาระบุ"),
  }),
  temperature_start_hours: yupNumber,
  temperature_end_hours: yupNumber,
  total_temperature_hours: yupNumber,
  work_list: Yup.array()
    .of(workListValidation)
    .min(1, "กรุณาเพิ่มสถานที่อย่างน้อย 1 รายการ"),
  income_list: Yup.array().when("main_status", {
    is: "finished",
    then: (schema) => schema.of(incomeListValidation),
  }),
  expenses_list: Yup.array().when("main_status", {
    is: "delivering",
    then: (schema) => schema.of(expensesListValidation),
  }),
  fuel: Yup.object().when("main_status", {
    is: "delivering",
    then: (schema) =>
      schema.shape({
        appa: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
        total_quota: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
        total_usage: yupNumber.required("กรุณาระบุ"),
      }),
    otherwise: (schema) =>
      schema.shape({
        total_quota: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
      }),
  }),
  primary_driver: Yup.object().shape({
    name: Yup.string().required("กรุณาระบุ"),
  }),
});
