import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { useFormContext, useWatch } from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  useRoleSelectOptionsQuery,
  RoleSelectOptionsQuery,
} from "generated/general";
import { useStateContext } from "contexts/auth-context";

import { Box, Grid, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ControlledSelect from "components/Controller/ControlledSelect";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";

const UserPermission = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [disabled] = useDisable();
  const {
    state: { permissions },
  } = useStateContext();

  const watchRoleId = useWatch({
    control,
    name: "role_id",
  });

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data } = useRoleSelectOptionsQuery<RoleSelectOptionsQuery>(
    graphQLClientWithHeaderSetting
  );

  const currentRole = data?.RoleSelectOptions?.find(
    (role) => role.value === watchRoleId
  )?.label;

  const viewRolePermissionHandler = () => {
    const filePath = `/static/role-permission-info/${currentRole}.pdf`;
    window.open(filePath, "_blank");
  };

  return (
    <CustomizedBox>
      <Typography fontWeight="bold" mb={1}>
        {t("user.account.permission")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledSelect
            name={"role_id"}
            control={control}
            label={t("user.rbac.permission")}
            options={data?.RoleSelectOptions ?? []}
            disabled={!permissions?.role?.update || disabled}
          />
        </Grid>
        {watchRoleId && currentRole !== "ไม่มีสิทธิ์" && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              onClick={viewRolePermissionHandler}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                color: "primary.main",
                cursor: "pointer",
              }}
            >
              <LaunchIcon
                sx={{
                  fontSize: 18,
                }}
              />
              <Typography
                color="primary.main"
                ml={1}
              >{`ดูรายละเอียดสิทธิ์ ${currentRole}`}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default UserPermission;
