import { useTranslation } from "react-i18next";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { IconButton, Typography } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { IRole } from "types/Auth/role";
import CheckedUserModal from "components/UI/Modal/CheckedModal/CheckedUserModal";
import { useEffect, useRef, useState } from "react";
import { useModal } from "hooks/use-modal";
import { v4 as uuidv4 } from "uuid";
import CustomizedButton from "components/Custom/CustomizedButton";
import AgGrid from "components/UI/AgGrid";
import useUserColumnDefs from "components/Table/User/useUserColumnDefs";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import CloseIcon from "@mui/icons-material/Close";
import { useDisable } from "hooks/use-disable";

const RoleEmployee = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<IRole>();
  const gridRef = useRef();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUserIdsSnapshot] = useState<string[]>([]);

  const [disabled] = useDisable();

  const { modal, openModalHandler, closeModalHandler } = useModal();
  const { fields, replace, remove } = useFieldArray({
    control,
    name: "user_list",
    keyName: "genId",
  });

  const watchRoleId = useWatch({ control, name: "id" });

  const finishUsersSelect = (data: any[]) => {
    // filter out users not selected in current modal session
    let filteredUsers = fields.filter((user: any) =>
      userIds.includes(user.unique_id)
    );

    // get current users id to prevent duplication when appending newly selected users
    const filteredUsersId = filteredUsers.map((user: any) => user.unique_id);

    data.forEach((user) => {
      if (!filteredUsersId.includes(user.unique_id)) {
        filteredUsers.push({ ...user, genId: uuidv4() });
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredUsers = filteredUsers.sort((a: any, b: any) => {
      return userIds.indexOf(a.unique_id) - userIds.indexOf(b.unique_id);
    });

    const formatUser: any[] = filteredUsers.map((user: any) => ({
      id: user.id,
      unique_id: user.unique_id,
      title_name: user.title_name,
      first_name: user.first_name,
      img_url: user.img_url,
      last_name: user.last_name,
      nick_name: user.nick_name,
      position: user.position,
      department: user.department,
      email: user.email,
      status: user.status,
    }));
    replace(formatUser);
    closeModalHandler();
  };

  useEffect(() => {
    const userUniqueId = fields.map((field) => field.unique_id);
    setUserIds(userUniqueId);
    setUserIdsSnapshot(userUniqueId);
  }, [fields]);

  const columnDefs = useUserColumnDefs();

  const formatColumnDefs: ColDef[] = [
    ...columnDefs,
    ...(disabled
      ? []
      : [
          {
            field: "",
            cellRenderer: (params: ICellRendererParams) => (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                onClick={() => remove(params.node.rowIndex || 0)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
            width: 50,
            cellStyle: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            filter: false,
            sortable: false,
          },
        ]),
  ];

  return (
    <>
      <CustomizedBox>
        <Typography fontSize={16} fontWeight={600} mb={1}>
          {t("user.rbac.employee")}
        </Typography>
        <AgGrid
          ref={gridRef}
          columnDefs={formatColumnDefs}
          rowData={fields}
          height={665}
          disabledSidebar
        />
        {!disabled && (
          <CustomizedButton
            title={t("user.rbac.add_employee")}
            variant="outlined"
            onClick={openModalHandler}
            startAddIcon
          />
        )}
      </CustomizedBox>
      <CheckedUserModal
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
        showSelectUser={modal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        rowSelection="multiple"
        isRole
        roleId={watchRoleId}
      />
    </>
  );
};

export default RoleEmployee;
