import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "types/global";

const Setting = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    // {
    //   name: t("setting.zone.index"),
    //   to: "/setting/zone",
    // },
    permissions?.company?.view && {
      name: t("setting.company.index"),
      to: "/setting/company",
    },
    // {
    //   name: t("setting.approval.index"),
    //   to: "/setting/approval",
    // },
    // {
    //   name: t("setting.contact.index"),
    //   to: "/setting/contact",
    // },
    permissions?.inventory_setting?.view && {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    // {
    //   name: t("setting.sales.index"),
    //   to: "/setting/sales",
    // },
    // {
    //   name: t("setting.employee.index"),
    //   to: "/setting/employee",
    // },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  if (permissions && !permissions.setting?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Setting;
