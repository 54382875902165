import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { statusValueFormatter } from "utils/Formatter/Global";
import {
  useWarehousesFindAllQuery,
  WarehousesFindAllQuery,
} from "generated/general";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "components/Custom/CustomizedLetterAvatar";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

export const useGoodsAdjustColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useWarehousesFindAllQuery<WarehousesFindAllQuery>(
    graphQLClient,
    {},
    {
      enabled: false,
    }
  );

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("inventory.goods_adjust.table.column.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "source_warehouse_name",
        headerName: t("inventory.goods_adjust.table.column.warehouse_name"),
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map((warehouse) => warehouse.name);
            params.success(formatValues);
          },
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_date",
        headerName: t("inventory.goods_adjust.table.column.created_date"),
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,

        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "posted_date",
        headerName: t("inventory.goods_adjust.table.column.posted_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_by",
        headerName: t("inventory.goods_adjust.table.column.created_by"),
        filter: "agTextColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          if (params.value) {
            return (
              <CustomizedAvatar
                avatars={[
                  {
                    unique_id: "",
                    first_name: params.value,
                    last_name: "",
                    img_url: params.data?.created_by_object?.img_url,
                  },
                ]}
              />
            );
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "aggrid_status",
        headerName: t("status.index"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        minWidth: 200,
        flex: 1,
        filterParams: {
          values: ["draft", "finished", "cancelled"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
      {
        field: "remark",
        headerName: t("inventory.goods_adjust.table.column.remark"),
        filter: "agTextColumnFilter",
      },
    ]);
  }, [t, refetch]);
  return columnDefs;
};
