import { Box, Toolbar, Typography } from "@mui/material";
import LoadingAnimation from "components/Animation/Loading";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import CustomizedTab from "components/Custom/CustomizedTab";
import LocationTable from "components/Table/Logistic/Location";
import DashboardBottom from "components/UI/DashboardLayout/DashboardBottom";
import HeaderLayout from "components/UI/HeaderLayout";
import DeleteConfirmation from "components/UI/Modal/DeleteConfirmation";
import {
  GenerateLocationReportQuery,
  LocationReportType,
  ReportLocationGenerateInput,
  useGenerateLocationReportQuery,
} from "generated/crm";
import { GraphQLClient } from "graphql-request";
import { useModal } from "hooks/use-modal";
import { useNavbar } from "hooks/use-navbar";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { LOCATION_DELETE } from "services/AgGrid/LocationAgGrid";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { theme } from "theme";
import useStylesRadioCheckBoxTable from "theme/radio-check-box-table";
import {
  IBreadcrumbsAndMenu,
  IExporter,
  IMenuOption,
  ITab,
} from "types/global";

const Location = () => {
  const [selectionRow, setSelectionRow] = useState<any>();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const navbar = useNavbar();

  const style = useStylesRadioCheckBoxTable(theme);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const statusFilterTab = searchParams.get("filter");
  const {
    state: { permissions },
  } = useStateContext();

  const { modal, openModalHandler, closeModalHandler } =
    useModal(DeleteConfirmation);

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  // const {
  //   modal: exportModal,
  //   openModalHandler: openExportModalHandler,
  //   closeModalHandler: closeExportModalHandler,
  // } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.location.index"),
    },
  ];
  const tabs: ITab[] = [
    {
      label: t("status.active"),
      path: `${pathname}?filter=active`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const options: IMenuOption[] = [
    {
      value: t("purchase.export"),
    },
  ];

  const handleDeleteConfirmationModal = () => {
    openModalHandler();
  };

  // const handleExportConfirmationModal = () => {
  //   // openExportModalHandler();
  // };

  const deleteConfirm = async () => {
    try {
      await deleteLocationById();
      setSelectionRow(undefined);
      setRefreshTrigger((prev) => prev + 1);
      closeModalHandler();
    } catch (error) {
      closeModalHandler();
    }
  };

  const deleteLocationById = async () => {
    try {
      await graphQLClientWithHeader.request(LOCATION_DELETE, {
        uniqueInput: {
          id: selectionRow.id,
        },
      });

      enqueueSnackbar(`${t("button.delete")}สถานที่สำเร็จ`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `${t("button.delete")}สถานที่ไม่สำเร็จเนื่องจากมีการใช้งานในเอกสาร`,
        {
          variant: "error",
        }
      );

      throw error;
    }
  };

  useEffect(() => {
    navigate(pathname + "?filter=active", { replace: true });
  }, [navigate, pathname]);

  const currentTab =
    pathname + (statusFilterTab ? `?filter=${statusFilterTab}` : "");

  const [exportInput, setExportInput] =
    useState<ReportLocationGenerateInput | null>(null);

  const { refetch: refetchData, isLoading: isExporting } =
    useGenerateLocationReportQuery<GenerateLocationReportQuery>(
      graphQLClientWithHeader,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { type } = data;
      setExportInput({
        report_type: type as LocationReportType,
      });
    } else {
      enqueueSnackbar("Export data is required", { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        if (exportInput) {
          const { data: exportData } = await refetchData();
          if (exportData?.GenerateReportLocationNoQueue) {
            const url = exportData.GenerateReportLocationNoQueue;
            window.open(url, "_blank");
            enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
              variant: "success",
            });
          } else {
            throw new Error("No data received");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [exportInput, refetchData, t]);

  if (permissions && !permissions.location?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (exportInput && isExporting) {
    return <LoadingAnimation />;
  }
  return (
    <Box sx={style.checkboxTable}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5" marginY={"auto"}>
          {t("logistic.location.index")}
        </Typography>

        <Box display="flex">
          <Box ml="auto">
            {permissions?.location?.create && (
              <CustomizedButton
                title={`${t("button.add")}${t("logistic.location.index")}`}
                variant="contained"
                onClick={() => navigate(`${pathname}/import`)}
                startAddIcon
              />
            )}
            {permissions?.location?.export && (
              <CustomizedMenuOptionsMeatball
                isIcon
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("logistic.export")}`:
                      // handleExportConfirmationModal();
                      exportHandler({
                        type: LocationReportType.Location,
                        export_type: "all",
                        start_date: null,
                        end_date: null,
                      });
                      break;
                    default:
                  }
                }}
                sx={{
                  width: "46px",
                  minWidth: "46px",
                }}
                disableRipple={true}
              />
            )}
          </Box>
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <LocationTable
        setSelectionRow={setSelectionRow}
        isFilter={statusFilterTab}
        refreshTrigger={refreshTrigger}
      />
      {selectionRow && (
        <DashboardBottom
          position="fixed"
          color="inherit"
          theme={theme}
          open={navbar?.sidebar.isSidebarOpen}
        >
          <Toolbar
            disableGutters
            sx={{
              minHeight: 64,
              left: 0,
              px: 2,
            }}
          >
            <Box display="flex" justifyContent={"flex-end"} flexGrow={1}>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                paddingRight={"14px"}
              >
                {permissions?.location?.delete && (
                  <CustomizedButton
                    title={`${t("button.delete")}${t(
                      "logistic.location.button.delete"
                    )}`}
                    variant="contained"
                    onClick={handleDeleteConfirmationModal}
                  />
                )}
              </Box>
            </Box>
          </Toolbar>
        </DashboardBottom>
      )}
      <DeleteConfirmation
        open={modal}
        handleClose={closeModalHandler}
        title={`${t("button.delete")}${t("logistic.location.index")}`}
        message={
          <Box>
            <Typography>ยืนยันการลบ{t("logistic.location.index")}</Typography>
            <Typography fontWeight="bold">{selectionRow?.name}</Typography>
          </Box>
        }
        action={deleteConfirm}
        noDivider
      />

      {/* <ExporterLocationModal
        open={exportModal}
        closeModalHandler={closeExportModalHandler}
        exportHandler={exportHandler}
        message={
          <Box
            my={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <GreenCheckIcon size={60} />
            <Typography sx={{ marginTop: 1 }}>ยืนยันการนำออกข้อมูล</Typography>
          </Box>
        }
      /> */}
    </Box>
  );
};

export default Location;
