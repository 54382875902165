import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AgGrid from "components/UI/AgGrid";
import {
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useRoleColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { ROLE_VIEW } from "services/AgGrid/RoleAggrid";
import { useStateContext } from "contexts/auth-context";

const RoleTable = () => {
  const gridRef: any = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      try {
        const { RoleViewsAggrid } = await graphQLClient.request(ROLE_VIEW, {
          aggridInput: {
            startRow,
            endRow,
            filterModel,
            sortModel,
          },
        });
        params.success({
          rowData: RoleViewsAggrid.results as any[],
          rowCount: RoleViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback((params: any) => {
    params.api.onFilterChanged();
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    let path = "role";
    if (authUser?.id === id) path = "setting";

    navigate(`/user/${path}/${id}`);
  };

  const columnDefs = useRoleColumnDefs(t);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      path={"/user/role"}
      onGridReady={onGridReady}
    />
  );
};

export default RoleTable;
