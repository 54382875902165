import { GraphQLClient } from "graphql-request";
import { useStateContext } from "contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserPermissionQuery, useUserPermissionQuery } from "generated/general";

export const usePermission = () => {
  const location = useLocation();

  const {
    state: { authUser },
    dispatch,
  } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { isLoading, refetch } = useUserPermissionQuery<UserPermissionQuery>(
    graphQLClient,
    {
      userId: authUser?.id ?? -1,
    },
    {
      enabled: Boolean(authUser?.id),
    }
  );

  useEffect(() => {
    if (authUser) {
      const getAccess = async () => {
        const { data } = await refetch();
        const permissions = data?.UserPermissionFindUnique || {}; 
        dispatch({
          type: "SET_PERMISSION",
          payload: permissions,
        });
      };
      getAccess();
    }
  }, [authUser, dispatch, refetch, location]);

  return authUser && isLoading;
};
