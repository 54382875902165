import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "types/global";

const Purchase = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    permissions?.purchase_order?.view && {
      name: t("purchase.order.index"),
      to: "/purchase/order",
    },
    permissions?.purchase_return?.view && {
      name: t("purchase.return.index"),
      to: "/purchase/return",
    },
    permissions?.purchase_export?.view && {
      name: t("report"),
      to: "/purchase/report",
    },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  if (permissions && !permissions.purchase?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Purchase;
