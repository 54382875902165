import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu } from "../../types/global";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";

const User = () => {
  const { t } = useTranslation();
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    permissions?.user_account?.view && {
      name: t("user.account.index"),
      to: "account",
    },
    permissions?.role?.view && {
      name: t("user.rbac.index"),
      to: "role",
    },
    {
      name: t("user.setting.index"),
      to: "setting/" + authUser?.id,
    },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default User;
