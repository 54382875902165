import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

import { Box, Typography } from "@mui/material";

import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import BillingNoteTable from "components/Table/Sales/BillingNote";

import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "../../../types/global";
import HeaderLayout from "components/UI/HeaderLayout";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import { useModal } from "hooks/use-modal";
import ExporterBillingNoteModal from "components/UI/Modal/ExporterBillingNoteModal";
import { BillingNoteReportType } from "generated/sales";

const BillingNote = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const {
    state: { permissions },
  } = useStateContext();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.billing_note.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_payment"),
      path: `${pathname}?filter=wait_payment`,
    },
    {
      label: t("status.fully_payment"),
      path: `${pathname}?filter=fully_payment`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const options: IMenuOption[] = [
    {
      value: t("sales.export"),
    },
  ];

  if (permissions && !permissions.billing_note?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5" marginY={"auto"}>
          {t("sales.billing_note.index")}
        </Typography>
        <Box display="flex">
          <Box ml="auto">
            {permissions?.billing_note?.create && (
              <CustomizedButton
                title={`${t("button.create")}${t("sales.billing_note.index")}`}
                variant="contained"
                onClick={() => navigate(`${pathname}/add`)}
                startAddIcon
              />
            )}
            {permissions?.billing_note?.export && (
              <CustomizedMenuOptionsMeatball
                isIcon
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("sales.export")}`:
                      openModalHandler();
                      break;
                    default:
                  }
                }}
                sx={{
                  width: "46px",
                  minWidth: "46px",
                }}
                disableRipple={true}
              />
            )}
          </Box>
        </Box>
      </HeaderLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <BillingNoteTable isFilter={isFilter} />
      <ExporterBillingNoteModal
        open={modal}
        closeModalHandler={closeModalHandler}
        documentType={BillingNoteReportType.BillingNote}
      />
    </>
  );
};

export default BillingNote;
