import { useTranslation } from "react-i18next";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import DropzoneUI from "../DropzoneUI";
import {
  contactPersonSchema,
  contactPersonValidation,
} from "../../Form/Contact/schema";
import { IContact, IContactPerson } from "../../../types/Contact/contact";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { contactChannelOptions } from "../../../utils/Global";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../LabelInput";
import { contactChannelFormatter } from "../../../utils/Formatter/Global";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  isEdit?: boolean;
  isCreate?: boolean;
  setIsEdit?: Dispatch<SetStateAction<boolean>>;
  onAddHandler?: (data: IContactPerson) => void;
  onUpdateHandler?: (data: IContactPerson) => void;
  data?: FieldArrayWithId<IContact, "contact_person_list", "id">;
};

const PersonModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  isEdit,
  setIsEdit,
  isCreate,
  data,
}: Props) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm<IContactPerson>({
    defaultValues: contactPersonSchema,
    resolver: yupResolver<any>(contactPersonValidation),
    mode: "onChange",
  });

  const [disabled] = useDisable();

  useEffect(() => {
    if (open) {
      if (isCreate) {
        reset(contactPersonSchema);
      } else {
        if (data) {
          if (data.id) {
            const { id, ...other } = data;
            reset(other);
          } else {
            reset(data);
          }
        }
      }
    }
  }, [data, isCreate, open, reset]);

  const { t } = useTranslation();

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_channel_list",
  });

  const addContactChannel = () => {
    append({
      contact_channel_type: "",
      contact_channel_info: "",
    });
  };

  const removeContactChannel = (index: number) => {
    remove(index);
  };

  const onClose = () => {
    if (setIsEdit) {
      setIsEdit(false);
    }

    closeModalHandler();
  };

  return (
    <ModalUI
      title={t("contact.contact_person.index")}
      open={open}
      handleClose={onClose}
      width={900}
      maxWidth="md"
      action={
        <>
          {!disabled ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              {isCreate ? (
                <>
                  <CustomizedButton
                    title={t("button.cancel")}
                    variant="outlined"
                    onClick={onClose}
                  />
                  <CustomizedButton
                    title={t("button.confirm")}
                    variant="contained"
                    onClick={() => {
                      if (onAddHandler) {
                        handleSubmit(onAddHandler)();
                        if (isValid) {
                          onClose();
                        }
                      }
                    }}
                  />
                </>
              ) : isEdit ? (
                <>
                  <CustomizedButton
                    title={t("button.cancel")}
                    variant="outlined"
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <CustomizedButton
                    title={t("button.confirm")}
                    variant="contained"
                    onClick={() => {
                      if (onUpdateHandler) {
                        handleSubmit(onUpdateHandler)();
                        if (isValid) {
                          onClose();
                        }
                      }
                    }}
                  />
                </>
              ) : undefined}
            </Box>
          ) : undefined}
        </>
      }
    >
      <Grid container spacing={1.5} mt={0}>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={!isCreate && !isEdit}
            isNotRevoke
            height={134}
            width={134}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {!isCreate && !isEdit ? (
                <LabelInput
                  label={t("contact.contact_person.prefix.index")}
                  value={getValues("title_name")}
                />
              ) : (
                <ControlledSelect
                  name="title_name"
                  control={control}
                  label={t("contact.contact_person.prefix.index")}
                  error={Boolean(errors.title_name)}
                  helperText={errors.title_name?.message?.toString()}
                  options={prefixes}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="nick_name"
                control={control}
                label={t(`contact.contact_person.name.nick_name`)}
                error={Boolean(errors.nick_name)}
                helperText={errors.nick_name?.message?.toString()}
                viewMode={!isCreate && !isEdit}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="first_name"
                control={control}
                label={t(`contact.contact_person.name.first_name`)}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message?.toString()}
                viewMode={!isCreate && !isEdit}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="last_name"
                control={control}
                label={t(`contact.contact_person.name.last_name`)}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message?.toString()}
                viewMode={!isCreate && !isEdit}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="position"
                control={control}
                label={t(`contact.contact_person.position`)}
                error={Boolean(errors.position)}
                helperText={errors.position?.message?.toString()}
                viewMode={!isCreate && !isEdit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography fontWeight={600} my={2}>
        {t("contact.contact_channel.index")}
      </Typography>
      <Grid container spacing={1.5}>
        {fields.length > 0 ? (
          fields.map((channel, index) => (
            <Fragment key={channel.id}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {!isCreate && !isEdit ? (
                  <LabelInput
                    label={t("contact.contact_channel.type")}
                    value={contactChannelFormatter(
                      getValues(
                        `contact_channel_list.${index}.contact_channel_type`
                      )
                    )}
                  />
                ) : (
                  <ControlledSelect
                    name={`contact_channel_list.${index}.contact_channel_type`}
                    control={control}
                    label={t("contact.contact_channel.type")}
                    options={contactChannelOptions}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <ControlledTextField
                    name={`contact_channel_list.${index}.contact_channel_info`}
                    control={control}
                    label={t("contact.contact_channel.info")}
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_channel_info
                    )}
                    helperText={
                      errors.contact_channel_list &&
                      errors.contact_channel_list?.[index]
                        ?.contact_channel_info &&
                      errors.contact_channel_list?.[index]?.contact_channel_info
                        ?.message
                    }
                    viewMode={!isCreate && !isEdit}
                  />
                  {index !== 0 && (isCreate || isEdit) && (
                    <IconButton onClick={() => removeContactChannel(index)}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Fragment>
          ))
        ) : !isCreate && !isEdit ? (
          <Grid item xs={12}>
            -
          </Grid>
        ) : undefined}
      </Grid>
      {(isCreate || isEdit) && fields.length < 10 && (
        <CustomizedButton
          sx={{
            mt: 1,
          }}
          title={t("button.add") + t("contact.contact_channel.index")}
          startAddIcon
          onClick={addContactChannel}
        />
      )}
      <Typography fontWeight={600} my={2}>
        {t("sentence.remark")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            placeholder={t("sentence.remark")}
            control={control}
            name="remark"
            error={Boolean(errors?.remark)}
            helperText={errors?.remark && errors.remark.message?.toString()}
            multiline
            rows={3}
            viewMode={!isCreate && !isEdit}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default PersonModal;
