import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FormProvider,
  useForm,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import { useStateContext } from "contexts/auth-context";
import CustomizedButton from "components/Custom/CustomizedButton";
import {
  goodsIssueSchema,
  goodsIssueValidation,
} from "components/Form/Inventory/GoodsIssue/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { CircularProgress, Box, Stack } from "@mui/material";
import { ActivityLogDocumentType, ActivityType } from "generated/general";
import {
  useGoodsIssueCreateMutation,
  useGoodsIssueUpdateMutation,
  useGoodsIssueFindUniqueQuery,
  GoodsIssueFindUniqueQuery,
  GoodsIssueCreateInput,
  GoodsIssueUpdateInput,
  useInventoryDocumentCancelMutation,
  InventoryControlDocumentType,
  useItemSkuQtysQuery,
  ItemSkuQtysQuery,
} from "generated/wms";
import { useActivityLog } from "hooks/use-activity-log";
import { useDisable } from "hooks/use-disable";
import { IGoodsIssue } from "types/Inventory/goodsIssue";
import GoodsIssueHeaderBreadcrumbs from "components/Form/Inventory/InventoryControl/HeaderBreadcrumbs";
import GoodsIssueHeader from "components/Form/Inventory/GoodsIssue/Header";
import GoodsIssueInfo from "components/Form/Inventory/GoodsIssue/Info";
import GoodsIssueItemList from "components/Form/Inventory/GoodsIssue/ItemList";
import {
  goodsIssueCreateFormatter,
  goodsIssueQueryFormatter,
  goodsIssueUpdateFormatter,
} from "utils/Formatter/Inventory/GoodsIssue";
import { errorMessageFormatter } from "utils/Global";

const GoodsIssueContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDisabled] = useDisable();
  const { createActivityLog } = useActivityLog();
  const [barcodeList, setBarcodeList] = useState<any[]>([]);
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IGoodsIssue>({
    defaultValues: goodsIssueSchema,
    resolver: yupResolver<any>(goodsIssueValidation),
  });

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = methods;

  const {
    fields,
    append,
    remove,
    update: updateFieldArray,
    replace,
  } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const status = useWatch({ control, name: "aggrid_status" });

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading, isSuccess, refetch } =
    useGoodsIssueFindUniqueQuery<GoodsIssueFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      { enabled: !!id }
    );

  const { data: allSkuQtys, isSuccess: isSkuSuccess } =
    useItemSkuQtysQuery<ItemSkuQtysQuery>(
      graphQLClient,
      {
        findManyInput: {
          where: {
            barcode: {
              in: barcodeList,
            },
          },
        },
      },
      {
        enabled: barcodeList.length > 0,
      }
    );

  useEffect(() => {
    if (id && isSuccess) {
      const { GoodsIssueFindUnique: GoodsIssue } = data;
      const traceEntryList = GoodsIssue?.trace_entry_list;
      const allBarcode = traceEntryList?.map((trace) => trace?.barcode) || [];
      setBarcodeList(allBarcode);

      const goodsIssueType = GoodsIssue as IGoodsIssue;

      const formatGoodsIssue = goodsIssueQueryFormatter(
        goodsIssueType,
        allSkuQtys
      );

      reset(formatGoodsIssue);
    }
  }, [data, id, isSuccess, reset, isSkuSuccess, allSkuQtys]);

  useEffect(() => {
    if (
      id &&
      status &&
      (status === "finished" ||
        status === "cancelled" ||
        !permissions?.goods_issue?.update)
    ) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status, permissions]);

  const { mutateAsync: create, isLoading: isCreating } =
    useGoodsIssueCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useGoodsIssueUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancel, isLoading: isCancelling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const formatGI = useCallback(
    (goodsIssueType: any) => {
      const formattedGoodsIssue = goodsIssueQueryFormatter(goodsIssueType);
      reset(formattedGoodsIssue);
      setDisabled(true);
    },
    [reset, setDisabled]
  );

  useEffect(() => {
    if (state) {
      reset({
        ...state,
        created_date: dayjs(),
        posted_date: dayjs(),
      });
    }
  }, [reset, state]);

  const draftHandler = async (data: IGoodsIssue) => {
    if (!validateQty()) return;

    try {
      const formatData = id
        ? goodsIssueUpdateFormatter(data, "draft")
        : goodsIssueCreateFormatter(data, "draft");

      const result: any = id
        ? await update({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsIssueUpdateInput,
          })
        : await create({ createInput: formatData as GoodsIssueCreateInput });

      const isDirty = Object.keys(dirtyFields)?.length > 0;

      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: result.GoodsIssueCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } else {
        if (isDirty) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.GoodsIssue,
            reference_id: result.GoodsIssueUpdate?.id,
            activity_detail: {},
          });
        }
      }

      if (!id) {
        navigate(`/inventory/goods-issue/${result.GoodsIssueCreate.id}`);
        enqueueSnackbar(
          `${t("button.create")}ใบ${t("inventory.goods_issue.index")}สำเร็จ`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `${t("button.edit")}ใบ${t("inventory.goods_issue.index")}สำเร็จ`,
          {
            variant: "success",
          }
        );
        await refetch();
      }
    } catch (e: any) {
      if (e.message?.includes("unique id has already existed")) {
        enqueueSnackbar("เลขที่เอกสารนี้มีอยู่ในระบบแล้ว ", {
          variant: "error",
        });
        return;
      }
      if (!id) {
        enqueueSnackbar(
          `${t("button.create")}ใบ${t("inventory.goods_issue.index")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(
          `${t("button.edit")}ใบ${t("inventory.goods_issue.index")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  const issueHandler = async (data: IGoodsIssue) => {
    if (!validateQty()) return;

    try {
      const formatData = id
        ? goodsIssueUpdateFormatter(data, "finished")
        : goodsIssueCreateFormatter(data, "finished");

      const result: any = id
        ? await update({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsIssueUpdateInput,
          })
        : await create({ createInput: formatData as GoodsIssueCreateInput });

      const formattedDirtyFields = Object.keys(dirtyFields);
      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: result.GoodsIssueCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "finished",
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: result?.GoodsIssueUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.main_status,
            curr_status: "finished",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      }

      if (!id)
        navigate(`/inventory/goods-issue/${result?.GoodsIssueCreate.id}`);
      else {
        const goodsIssueType = result?.GoodsIssueUpdate as IGoodsIssue;
        formatGI(goodsIssueType);
      }

      enqueueSnackbar(`${t("inventory.goods_issue.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const message = errorMessageFormatter(err);
      if (message?.split(",").length > 0) {
        message.split(",").forEach((unique_id: string) =>
          enqueueSnackbar(`${unique_id} เกินจำนวนส่งคืน`, {
            variant: "error",
          })
        );
      } else
        enqueueSnackbar(`${t("inventory.goods_issue.index")}ไม่สำเร็จ`, {
          variant: "error",
        });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancel({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryControlDocumentType.GoodIssue,
      });
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsIssue,
        reference_id: parseInt(id!),
        activity_detail: {
          prev_status: status,
          curr_status: "cancelled",
        },
      });
      await refetch();
      enqueueSnackbar(
        `${t("status.cancelled")}${t("inventory.goods_issue.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      enqueueSnackbar(
        `${t("status.cancelled")}${t("inventory.goods_issue.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const validateQty = () => {
    const checkBoolean: { item_unique_id: string; status: boolean }[] = [];
    const type = getValues("type");
    const goodsIssue = getValues();
    const formatData = goodsIssueCreateFormatter(goodsIssue);

    if (type === "other") return true;

    formatData.trace_entry_list.forEach((list) => {
      if (list.qty > (list.document_item_qty || 0) - (list.posted_qty || 0))
        return checkBoolean.push({
          item_unique_id: list.item_unique_id,
          status: false,
        });
    });

    if (!checkBoolean.every((boolean) => boolean.status)) {
      checkBoolean.forEach((list) =>
        enqueueSnackbar(`${list.item_unique_id} เกินจำนวนใบส่งคืน`, {
          variant: "error",
        })
      );
    }

    return checkBoolean.every((boolean) => boolean.status);
  };

  const errorHandler = (error: any) => {
    let emptyTraceError = false;
    let exceedStockQtyError = false;
    let noQtyError = false;

    if (error?.type?.message === "กรุณาระบุประเภท") {
      enqueueSnackbar("กรุณาระบุประเภท", { variant: "error" });
    }
    if (error?.source_warehouse_id?.message === "กรุณาเลือกคลัง") {
      enqueueSnackbar("กรุณาเลือกคลัง", { variant: "error" });
    }
    if (
      error?.trace_entry_list?.message === "กรุณาเพิ่มสินค้า" ||
      error?.trace_entry_list?.root?.message === "กรุณาเพิ่มสินค้า"
    ) {
      emptyTraceError = true;
    }
    if (error?.trace_entry_list && Array.isArray(error.trace_entry_list)) {
      error.trace_entry_list.forEach((trace: any) => {
        if (trace.qty?.message === "กรุณาระบุจำนวนที่นำออก") {
          noQtyError = true;
        }
        if (trace.qty?.message === "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง") {
          exceedStockQtyError = true;
        }
        if (
          !Array.isArray(trace.serial_list) &&
          trace.serial_list?.message === "กรุณาเพิ่ม SN ในรายการสินค้า"
        ) {
          emptyTraceError = true;
        }
        if (Array.isArray(trace.serial_list)) {
          trace.serial_list.forEach((serial: any) => {
            if (serial.qty?.message === "กรุณาระบุจำนวนที่นำออก") {
              noQtyError = true;
            }
            if (
              serial.qty?.message === "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง"
            ) {
              exceedStockQtyError = true;
            }
          });
        }
      });
    }

    if (emptyTraceError) {
      enqueueSnackbar("กรุณาเพิ่มสินค้า", { variant: "error" });
    }
    if (exceedStockQtyError) {
      enqueueSnackbar("จำนวนนำออกมากกว่าจำนวนคงคลัง", { variant: "error" });
    }
    if (noQtyError)
      enqueueSnackbar("กรุณาระบุจำนวนมากกว่า 0", { variant: "error" });
  };

  if (id && (isLoading || isCreating || isUpdating || isCancelling)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (permissions && !permissions.goods_issue?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <FormProvider {...methods}>
      <GoodsIssueHeaderBreadcrumbs
        type={InventoryControlDocumentType.GoodIssue}
      />
      <GoodsIssueHeader cancelHandler={cancelHandler} />
      <GoodsIssueInfo replace={replace} />
      <GoodsIssueItemList
        fields={fields}
        append={append}
        remove={remove}
        update={updateFieldArray}
        replace={replace}
      />
      <BottomNavbar>
        {((!status && permissions?.goods_issue?.create) ||
          (status === "draft" && permissions?.goods_issue?.update)) && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              sx={{ width: "100px" }}
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(draftHandler, errorHandler)}
            />
            <CustomizedButton
              sx={{ width: "100px" }}
              variant="contained"
              title={t("inventory.goods_issue.index")}
              onClick={() => {
                handleSubmit(issueHandler, errorHandler)();
              }}
            />
          </Stack>
        )}
      </BottomNavbar>
    </FormProvider>
  );
};

export default GoodsIssueContainer;
