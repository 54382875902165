import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { statusValueFormatter } from "utils/Formatter/Global";

import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";

import {
  typeStatusValueFormatter,
  typeValueFormatter,
} from "utils/Formatter/Logistic/Vehicle";
import { usePurchaseAgGrid } from "hooks/Purchase/use-purchase-aggrid";
import { formatNumber } from "utils/Global";
// import { Box, styled, Typography } from "@mui/material";
// import LockIcon from "@mui/icons-material/Lock";

// const OverlayText = styled(Typography)(() => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   color: "#fff",
//   zIndex: 1,
//   fontSize: "14px",
//   pointerEvents: "none",
// }));

export const useVehicleColumnDefs = (
  isModal?: boolean,
  trailerOnly?: boolean
) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { purchaseUserFilterParams } = usePurchaseAgGrid("order");

  const { t } = useTranslation();
  useEffect(() => {
    if (!isModal) {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("logistic.vehicle.unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "register_id",
          headerName: t("logistic.vehicle.register_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "type",
          headerName: t("logistic.vehicle.type"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeValueFormatter(params.value),
            values: trailerOnly
              ? ["trailer"]
              : ["four_wheel", "six_wheel", "ten_wheel", "head", "trailer"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeValueFormatter(params.value),
        },
        {
          field: "trailer_unique_id",
          headerName: t("logistic.vehicle.trailer_unqiue_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "maintenance_status",
          headerName: t("logistic.vehicle.notification_status.maintenance"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeStatusValueFormatter(params.value),
            values: ["normal", "approaching", "overdue", "none"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "act_status",
          headerName: t("logistic.vehicle.notification_status.act"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeStatusValueFormatter(params.value),
            values: ["normal", "approaching", "overdue", "none"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_vehicle_status",
          headerName: t(
            "logistic.vehicle.notification_status.vehicle_insurance"
          ),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeStatusValueFormatter(params.value),
            values: ["normal", "approaching", "overdue", "none"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_product_status",
          headerName: t(
            "logistic.vehicle.notification_status.product_insurance"
          ),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeStatusValueFormatter(params.value),
            values: ["normal", "approaching", "overdue", "none"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "tire_maintenance_status",
          headerName: t("logistic.vehicle.notification_status.wheel"),
          // TODO: when finish wheels
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeStatusValueFormatter(params.value),
            values: ["normal", "approaching", "overdue", "none"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
          cellRenderer: (params: ICellRendererParams<any, string>) => {
            return <CustomizedStatus status={params.value || "none"} />;
          },
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "act_id",
          hide: true,
          headerName: t("logistic.vehicle.columns.act_id"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "current_kilometer",
          headerName: t("logistic.vehicle.current_kilometer"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) =>
            params.value.toLocaleString(),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "due_distance_kilometer",
          headerName: t("logistic.vehicle.due_distance_kilometer"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) =>
            params.value.toLocaleString(),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "act_end_date",
          headerName: t("logistic.vehicle.columns.act_end_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_vehicle_tier",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.tier") + "(ประกันรถ)",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_vehicle_policy_id",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.policy_id") + "(ประกันรถ)",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_vehicle_protection_value",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.protection_value") +
            "(ประกันรถ)",
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) =>
            formatNumber(params.value, false),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_vehicle_start_date",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.start_date") + "(ประกันรถ)",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_vehicle_end_date",
          headerName:
            t("logistic.vehicle.insurance_detail.end_date") + "(ประกันรถ)",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_product_tier",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.tier") + "(ประกันสินค้า)",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_product_policy_id",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.policy_id") + "(ประกันสินค้า)",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_product_protection_value",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.protection_value") +
            "(ประกันสินค้า)",
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) =>
            formatNumber(params.value, false),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_product_start_date",
          hide: true,
          headerName:
            t("logistic.vehicle.insurance_detail.start_date") +
            "(ประกันสินค้า)",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "insurance_product_end_date",
          headerName:
            t("logistic.vehicle.insurance_detail.end_date") + "(ประกันสินค้า)",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
          flex: 1,
          minWidth: 200,
        },
        {
          field: "detail",
          headerName: t("logistic.vehicle.detail"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "driver_object_list",
          headerName: t("logistic.vehicle.driver"),
          filter: "agSetColumnFilter",
          filterParams: purchaseUserFilterParams,
          cellRenderer: (params: ICellRendererParams<any, any>) => {
            if (params.value && params.value.length > 0) {
              return <CustomizedAvatar avatars={params.value} />;
            }
          },
          minWidth: 200,
        },
        {
          field: "created_by_object",
          headerName: t("logistic.vehicle.created_by"),
          filter: "agSetColumnFilter",
          hide: true,
          filterParams: purchaseUserFilterParams,
          cellRenderer: (params: ICellRendererParams<any, any>) => {
            if (params.value) {
              return <CustomizedAvatar avatars={[params.value]} />;
            }
          },
          minWidth: 150,
        },
        {
          field: "created_date",
          headerName: t("logistic.vehicle.created_date"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,

          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "status",
          headerName: t("logistic.vehicle.status"),
          filter: "agSetColumnFilter",
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          filterParams: {
            values: ["active", "in_active"],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
        {
          field: "has_approaching",
          hide: true,
          filter: "agNumberColumnFilter",
          suppressColumnsToolPanel: true,
        },
        {
          field: "has_overdue",
          hide: true,
          filter: "agNumberColumnFilter",
          suppressColumnsToolPanel: true,
        },
      ]);
    } else if (trailerOnly) {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("logistic.vehicle.unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "register_id",
          headerName: t("logistic.vehicle.register_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "detail",
          headerName: t("logistic.vehicle.detail"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "status",
          headerName: t("logistic.vehicle.status"),
          filter: "agSetColumnFilter",
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          filterParams: {
            values: ["active", "in_active"],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
      ]);
    } else {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("logistic.vehicle.unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "register_id",
          headerName: t("logistic.vehicle.register_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "type",
          headerName: t("logistic.vehicle.type"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              typeValueFormatter(params.value),
            values: ["four_wheel", "six_wheel", "ten_wheel", "head"],
          },
          valueFormatter: (params: ValueFormatterParams) =>
            typeValueFormatter(params.value),
        },
        {
          field: "trailer_unique_id",
          headerName: t("logistic.vehicle.trailer_unqiue_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "detail",
          headerName: t("logistic.vehicle.detail"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "driver_object_list",
          headerName: t("logistic.vehicle.driver"),
          filter: "agSetColumnFilter",
          filterParams: purchaseUserFilterParams,
          cellRenderer: (params: ICellRendererParams<any, any>) => {
            if (params.value && params.value.length > 0) {
              return <CustomizedAvatar avatars={params.value} />;
            }
          },
          minWidth: 200,
        },
        {
          field: "status",
          headerName: t("logistic.vehicle.status"),
          filter: "agSetColumnFilter",
          cellRenderer: (params: ICellRendererParams<any, string>) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          filterParams: {
            values: ["active", "in_active"],
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModal, t]);
  return columnDefs;
};
