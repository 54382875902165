import { ColDef, ICellRendererParams } from "ag-grid-community";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "utils/Formatter/Global";
import { IAccessPermission } from "types/Auth/role";

export const useLocationColumnDefs = (
  isModal?: boolean,
  locationPermissions?: IAccessPermission
) => {
  const { t } = useTranslation();
  const [columnDef, setColumnDef] = useState<ColDef[]>();

  useEffect(() => {
    if (!isModal) {
      setColumnDef(
        [
          locationPermissions?.delete && {
            checkboxSelection: true,
            filter: false,
            sortable: false,
            width: 16,
          },
          {
            field: "unique_id",
            headerName: t("logistic.location.unique_id"),
            filter: "agTextColumnFilter",
            sort: "asc",
            minWidth: 200,
          },
          {
            field: "name",
            headerName: t("logistic.location.name"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "province",
            headerName: t("logistic.location.province"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "region",
            headerName: t("logistic.location.region"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: (params: any) => {
                const uniqueValues = new Set();
                params.api.forEachNode((node: any) => {
                  if (node.data && node.data.region) {
                    uniqueValues.add(node.data.region);
                  }
                });
                params.success(Array.from(uniqueValues));
              },
            },
            minWidth: 200,
          },
          {
            field: "distance",
            headerName: t("logistic.location.km"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "contact_unique_id",
            headerName: t("logistic.location.customer_number"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "contact_full_name",
            headerName: t("logistic.location.contact_name"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "contact_phone",
            headerName: t("logistic.location.contact_tel"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "coordinator_name",
            headerName: t("logistic.location.connection_name"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "coordinator_phone",
            headerName: t("logistic.location.connection_tel"),
            filter: "agTextColumnFilter",
            minWidth: 200,
          },
          {
            field: "google_map_url",
            headerName: t("logistic.location.google_map_url"),
            filter: false,
            sortable: false,
            minWidth: 200,
          },
          {
            field: "latitude",
            headerName: t("logistic.location.latitude"),
            filter: false,
            sortable: false,
            minWidth: 200,
          },
          {
            field: "longitude",
            headerName: t("logistic.location.longitude"),
            filter: false,
            sortable: false,
            minWidth: 200,
          },
          {
            field: "four_wheel_chilled",
            headerName: t("logistic.location.chilled_4"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "four_wheel_frozen",
            headerName: t("logistic.location.frozen_4"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "six_wheel_chilled",
            headerName: t("logistic.location.chilled_6"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "six_wheel_frozen",
            headerName: t("logistic.location.frozen_6"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "ten_wheel_chilled",
            headerName: t("logistic.location.chilled_10"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "ten_wheel_frozen",
            headerName: t("logistic.location.frozen_10"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "head_chilled",
            headerName: t("logistic.location.chilled"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "head_frozen",
            headerName: t("logistic.location.frozen"),
            filter: false,
            sortable: false,
            minWidth: 200,
            valueFormatter: (params: any) => numberFormatter(params.value ?? 0),
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "secondary_driver_cost",
            headerName: t("logistic.location.send_cost"),
            filter: false,
            sortable: false,
            minWidth: 200,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "drop_cost",
            headerName: t("logistic.location.drop_cost"),
            filter: false,
            sortable: false,
            minWidth: 200,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
          },
          {
            field: "status",
            headerName: t("logistic.location.status"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [1, 0],
              valueFormatter: ({ value }: { value: number }) =>
                value ? "ใช้งาน" : "หยุดใช้งาน",
            },
            cellRenderer: (params: ICellRendererParams<any, string>) => (
              <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
              display: "flex",
              justifycontent: "center",
              alignItems: "center",
            },
            minWidth: 200,
          },
        ].filter(Boolean) as ColDef[]
      );
    } else {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("logistic.location.unique_id"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          checkboxSelection: true,
        },
        {
          field: "name",
          headerName: t("logistic.location.name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "province",
          headerName: t("logistic.location.province"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "region",
          headerName: t("logistic.location.region"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params: any) => {
              const uniqueValues = new Set();
              params.api.forEachNode((node: any) => {
                if (node.data && node.data.region) {
                  uniqueValues.add(node.data.region);
                }
              });
              params.success(Array.from(uniqueValues));
            },
          },
          minWidth: 200,
        },
        {
          field: "contact_unique_id",
          headerName: t("logistic.location.customer_number"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "contact_full_name",
          headerName: "ชื่อลูกค้า",
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "coordinator_name",
          headerName: t("logistic.location.connection_name"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "coordinator_phone",
          headerName: t("logistic.location.connection_tel"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "distance",
          headerName: "ระยะทาง",
          filter: "agNumberColumnFilter",
          minWidth: 200,
        },
      ]);
    }
  }, [isModal, t, locationPermissions]);

  return columnDef;
};
