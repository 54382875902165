import { ColDef } from "ag-grid-community";
import { TFunction } from "i18next";

import { useEffect, useState } from "react";
import {
  CreatableFieldsQuery,
  useCreatableFieldsQuery,
  CreatableFieldDocumentType,
} from "generated/general";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { GraphQLClient } from "graphql-request";

interface Props {
  t: TFunction;
}

export const useRoleColumnDefs = (t: Props["t"]): ColDef[] => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useCreatableFieldsQuery<CreatableFieldsQuery>(
    graphqlClient,
    {
      findManyInput: {
        document_type: CreatableFieldDocumentType.Employee,
        creatable_fields_name: "position",
      },
    }
  );

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("user.rbac.role_id"),
        filter: "agTextColumnFilter",
        width: 215,
      },
      {
        field: "name",
        headerName: t("user.rbac.name"),
        filter: "agTextColumnFilter",
        width: 375,
        sort: "asc",
      },
      {
        field: "description",
        headerName: t("user.rbac.description"),
        filter: "agTextColumnFilter",
        width: 375,
      },
      {
        field: "total_users",
        headerName: t("user.rbac.total_user"),
        filter: "agNumberColumnFilter",
      },
    ]);
  }, [t, refetch]);

  return columnDefs;
};

export const userDefaultFilter = () => {
  const defaultFilterModel = {
    position: {
      type: "equals",
      filter: "พนักงานขนส่ง",
    },
    status: {
      type: "set",
      values: ["1"],
    },
  };

  return defaultFilterModel;
};
