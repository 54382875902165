import { GraphQLClient } from "graphql-request";
import { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { TIRE_VIEW } from "services/AgGrid/VehicleAgGrid";
import { dateFilterModel } from "utils/AgGridFilter";
import { useTireColumnDefs } from "components/Table/Logistic/Tire/columnDefs";
import AgGrid from "components/UI/AgGrid";
import ModalUI from "../ModalUI";
import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";
import { Box } from "@mui/material";
import useStylesRadioCheckBoxTable from "theme/radio-check-box-table";

type CheckedTireModalProps = {
  showSelectTire: boolean;
  closeTireTable: () => void;
  finishTiresSelect: (data: any) => void;
  rowSelection?: "single" | "multiple";
  usedTireId?: number[];
};

const CheckedTireModal = forwardRef<any, CheckedTireModalProps>(
  (
    {
      usedTireId,
      rowSelection,
      showSelectTire,
      closeTireTable,
      finishTiresSelect,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const columnDefs = useTireColumnDefs(true);

    const graphQLClient: GraphQLClient =
      createGraphQLClientWithMiddleware("sales");

    const datasource = {
      async getRows(params: IServerSideGetRowsParams) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const { created_date, created_by_object, ...otherFilter } = filterModel;

        const formatFilter = {
          ...otherFilter,
          status: {
            filterType: "set",
            values: ["stock"],
          },
          created_by: created_by_object,
          created_date: dateFilterModel(created_date),
        };

        try {
          const { TireFindViewAggrid } = await graphQLClient.request(
            TIRE_VIEW,
            {
              aggridInput: {
                startRow: startRow,
                endRow: endRow,
                filterModel: formatFilter,
                sortModel: sortModel,
              },
            }
          );

          const filterUsed = TireFindViewAggrid.results.filter(
            (result: any) => !usedTireId?.includes(parseInt(result.id))
          );

          params.success({
            rowData: filterUsed as any[],
            rowCount: filterUsed.length as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      params.api.setServerSideDatasource(datasource);
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
      return params.data.id;
    }, []);

    const style = useStylesRadioCheckBoxTable();

    return (
      <ModalUI
        open={showSelectTire}
        handleClose={closeTireTable}
        title={t("logistic.tire.index")}
        maxWidth="lg"
        action={
          <ButtonLayout>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              size="medium"
              onClick={closeTireTable}
            />
            <CustomizedButton
              title={t("button.confirm")}
              onClick={finishTiresSelect}
              variant="contained"
              size="medium"
            />
          </ButtonLayout>
        }
      >
        <Box sx={rowSelection === "single" ? style.checkboxTable : undefined}>
          <AgGrid
            ref={ref}
            columnDefs={columnDefs}
            height={450}
            onGridReady={onGridReady}
            rowSelection={rowSelection}
            getRowId={getRowId}
          />
        </Box>
      </ModalUI>
    );
  }
);

export default CheckedTireModal;
