// import { type } from "@testing-library/user-event/dist/type";
// import { getValue } from "@testing-library/user-event/dist/utils";
import { useStateContext } from "contexts/auth-context";
// import dayjs from "dayjs";
import {
  useImportContactMutation,
  useValidateContactImportQuery,
  ValidateContactImportQuery,
} from "generated/crm";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IAddress, IContactChannel, IImporterError } from "types/global";
import {
  countryFormatter,
  businessTypeFormatter,
  companyTypeFormatter,
} from "utils/Formatter/Global";
import { formatFloat, formatString, formatStringToArray } from "utils/Global";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
  findNoneBusinessType,
  findNoneBusinessTypeDetail,
  findNoneTitleName,
  findNoneStatus,
  mapNestedData,
  findNoneBusinessTypeDetailForJuristicPerson,
  findNoneBusinessTypeDetailForIndividual,
  findNoneBoolean,
  findNoneSource,
  findNoneContactChannelList,
} from "utils/Importer";
// import { phoneRegExp } from '../../utils/Global';
// import { ContactImportInput } from '../../generated/crm';
import { sourceFormatter } from "utils/Formatter/Contact";

const column = [
  "unique_id",
  "img_url",
  "is_customer",
  "is_supplier",
  "business_type",
  "business_type_detail",
  "title_name",
  "name",
  "last_name",
  "registered_capital",
  "taxpayer_id",
  "source",
  "contact_channel_list",
  "contact_channel_info",
  "status",
  "remark_status",
  "address_list",
  "sub_district",
  "district",
  "provice",
  "postal_code",
  "country",
  "is_cheque",
  "is_transfer",
  "is_cash",
  "payment_duration",
  "payment_contact_person",
  "payment_contact_phone",
  "billing_note_duration",
  "billing_note_contact_person",
  "billing_note_contact_phone",
  "credit_request",
  "credit_request_duration",
  "account_debtor",
  "account_creditor",
  "purchase_amount_per_month",
  "remark",
];

const count_limit = 5000;
const useContactImporter = (
  // mode: ContactImportMode,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();

  // require field checking from xlsx
  // last_name and remark_status require with condition
  const requiredKeys = [
    {
      label: "รหัสผู้ติดต่อ",
      value: "unique_id",
    },
    {
      label: "ลูกค้า",
      value: "is_customer",
    },
    {
      label: "ซัพพลายเออร์",
      value: "is_supplier",
    },
    {
      label: "ประเภทกิจการ",
      value: "business_type",
    },
    {
      label: "ชื่อประเภทกิจการ",
      value: "business_type_detail",
    },
    // {
    //   label: "คำนำหน้า",
    //   value: "title_name",
    // },
    {
      label: "ชื่อกิจการ/ชื่อจริง",
      value: "name",
    },
    // {
    //   label: "นามสกุล",
    //   value: "last_name",
    // },
    {
      label: "สถานะ",
      value: "status",
    },
    // {
    //   label: "หมายเหตุสถานะ",
    //   value: "remark_status",
    // }
  ];

  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);
  // const [contactChannelList, setContactChannelList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const { refetch: validateContact, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          contact_unique_id_list: contactUniqueIdList,
          import_mode: getValues("type"),
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportContactMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = column.filter((col) => !dataCols.includes(col));
      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const invalidTypeErrors: any[] = [];
      const groupDataErrors: any[] = [];
      const formattedDataSnapshot = [...data];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }

        const businessType = formattedDataSnapshot[i].business_type;
        const businessTypeDetail =
          formattedDataSnapshot[i].business_type_detail;
        const status = formattedDataSnapshot[i].status;
        if (findNoneBusinessType([businessType]).length === 0) {
          if (businessType === "นิติบุคคล") {
            const noneBusinessTypeDetail =
              findNoneBusinessTypeDetailForJuristicPerson([businessTypeDetail]);
            if (
              findNoneBusinessTypeDetail([businessTypeDetail]).length === 0 &&
              noneBusinessTypeDetail.length > 0
            ) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field: "ชื่อประเภทกิจการ",
                detail: businessTypeDetail,
              });
            }
          } else if (businessType === "บุคคลธรรมดา") {
            const noneBusinessTypeDetail =
              findNoneBusinessTypeDetailForIndividual([businessTypeDetail]);

            if (
              findNoneBusinessTypeDetail([businessTypeDetail]).length === 0 &&
              noneBusinessTypeDetail.length > 0
            ) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field: "ชื่อประเภทกิจการ",
                detail: businessTypeDetail,
              });
            }
            if (!formattedDataSnapshot[i].title_name) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "required",
                field: "คำนำหน้า",
              });
            }
            if (!formattedDataSnapshot[i].last_name) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "required",
                field: "นามสกุล",
              });
            }
          }
        }

        if (
          findNoneStatus([status]).length === 0 &&
          status !== "ใช้งาน" &&
          !formattedDataSnapshot[i].remark_status
        ) {
          groupDataErrors.push({
            unique_id: formattedDataSnapshot[i].unique_id,
            type: "invalid",
            field: "หมายเหตุสถานะ",
          });
        }

        // if (!/^CT\d{0,14}$/.test(formattedDataSnapshot[i].unique_id)) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "รหัสผู้ติดต่อ",
        //     detail: formattedDataSnapshot[i].unique_id,
        //   });
        // }

        // if ((formattedDataSnapshot[i].unique_id?.length > 15)) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "รหัสผู้ติดต่อ",
        //     detail: formattedDataSnapshot[i].unique_id,
        //   });
        // }

        // if (formattedDataSnapshot[i].name?.length > 100) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "ชื่อกิจการ/ชื่อจริง",
        //     detail: formattedDataSnapshot[i].name,
        //   });
        // }

        // if (formattedDataSnapshot[i].registered_capital < 0 || formattedDataSnapshot[i].registered_capital?.toString().length > 15) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "ทุนจดทะเบียน (บาท)",
        //     detail: formattedDataSnapshot[i].registered_capital,
        //   });
        // }

        // if (formattedDataSnapshot[i].taxpayer_id?.length > 15) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "เลขประจำตัวผู้เสียภาษี",
        //     detail: formattedDataSnapshot[i].taxpayer_id,
        //   });
        // }

        // if (formattedDataSnapshot[i].postal_code?.length > 10) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "รหัสไปรษณีย์",
        //     detail: formattedDataSnapshot[i].postal_code,
        //   });
        // }

        // if (formattedDataSnapshot[i].address_list?.length > 300) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "ที่อยู่",
        //     detail: formattedDataSnapshot[i].address_list,
        //   });
        // }

        // if (formattedDataSnapshot[i].remark?.length > 300) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "หมายเหตุ",
        //     detail: formattedDataSnapshot[i].remark,
        //   });
        // }

        // if (formattedDataSnapshot[i].contact_channel_info?.length > 100) {
        //   invalidTypeErrors.push({
        //     unique_id: formattedDataSnapshot[i].unique_id,
        //     type: "invalid",
        //     field: "ข้อมูลช่องทางการติดต่อ",
        //     detail: formattedDataSnapshot[i].contact_channel_info,
        //   });
        // }

        const validateIdField = [
          { field: "รหัสผู้ติดต่อ", value: formattedDataSnapshot[i].unique_id },
        ];
        const validateNumberField = [
          {
            field: "ทุนจดทะเบียน (บาท)",
            value: formattedDataSnapshot[i].registered_capital,
          },
          {
            field: "วงเงินเครดิตที่ต้องการขอ (บาท)",
            value: formattedDataSnapshot[i].credit_request,
          },
          {
            field: "ประมาณยอดซื้อต่อเดือน (บาท)",
            value: formattedDataSnapshot[i].purchase_amount_per_month,
          },
        ];

        const validateMaxLength = [
          {
            field: "ชื่อกิจการ/ชื่อจริง",
            value: formattedDataSnapshot[i].name,
          },
          { field: "นามสกุล", value: formattedDataSnapshot[i].last_name },
          {
            field: "เลขประจำตัวผู้เสียภาษี",
            value: formattedDataSnapshot[i].taxpayer_id,
          },
          {
            field: "ข้อมูลการติดต่อ",
            value: formattedDataSnapshot[i].contact_channel_info,
          },
          {
            field: "หมายเหตุสถานะ",
            value: formattedDataSnapshot[i].remark_status,
          },
          {
            field: "ที่อยู่จดทะเบียน",
            value: formattedDataSnapshot[i].address_list,
          },
          { field: "แขวง/ตำบล", value: formattedDataSnapshot[i].sub_district },
          { field: "เขต/อำเภอ", value: formattedDataSnapshot[i].district },
          { field: "จังหวัด", value: formattedDataSnapshot[i].province },
          {
            field: "รหัสไปรษณีย์",
            value: formattedDataSnapshot[i].postal_code,
          },
          { field: "ประเทศ", value: formattedDataSnapshot[i].country },
          {
            field: "บุคคลติดต่อชำระเงิน",
            value: formattedDataSnapshot[i].payment_contact_person,
          },
          {
            field: "เบอร์โทรศัพท์บุคคลติดต่อชำระเงิน",
            value: formattedDataSnapshot[i].payment_contact_phone,
          },
          {
            field: "บุคคลติดต่อรับวางบิล",
            value: formattedDataSnapshot[i].billing_note_contact_person,
          },
          {
            field: "เบอร์โทรศัพท์บุคคลติดต่อรับวางบิล",
            value: formattedDataSnapshot[i].billing_note_contact_phone,
          },
          {
            field: "บัญชีลูกหนี้",
            value: formattedDataSnapshot[i].account_debtor,
          },
          {
            field: "บัญชีเจ้าหนี้",
            value: formattedDataSnapshot[i].account_creditor,
          },
          {
            field: "หมายเหตุผู้ติดต่อ",
            value: formattedDataSnapshot[i].remark,
          },
        ];

        const validateIntFields = [
          {
            field: "วันชำระเงิน (วัน)",
            value: formattedDataSnapshot[i].payment_duration,
          },
          {
            field: "วันรับวางบิล (วัน)",
            value: formattedDataSnapshot[i].billing_note_duration,
          },
          {
            field: "จำนวนวันที่ต้องการขอ (วัน)",
            value: formattedDataSnapshot[i].credit_request_duration,
          },
        ];

        validateIntFields.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (
              !Number.isInteger(Number(value)) ||
              value < 0 ||
              value.length > 200
            ) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });

        validateMaxLength.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (value.length > 200) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });

        validateNumberField.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (!Number(value) || value < 0 || value.length > 200) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });

        validateIdField.forEach(({ field, value }) => {
          if (value !== null && value !== undefined) {
            if (value.length > 15) {
              invalidTypeErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field,
                detail: value,
              });
            }
          }
        });
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็น",
            error.field,
            [""]
          );
        });
      }
      if (groupDataErrors.length > 0) {
        groupDataErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "ข้อมูลชุดไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      if (invalidTypeErrors.length > 0) {
        invalidTypeErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "รูปแบบข้อมูลไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      let formattedData: any[] = [];

      const uniqueIdList: string[] = [];

      const customerList: string[] = [];
      const supplierList: string[] = [];
      const sourceList: string[] = [];
      const typeList: string[] = [];
      const contactChannelList: string[] = [];
      const businessTypeList: string[] = [];
      const businessTypeDetailList: string[] = [];
      const titleNameList: string[] = [];
      const nameList: string[] = [];
      const lastNameList: string[] = [];
      const statusList: string[] = [];
      const remarkStatusList: string[] = [];
      const chequeList: string[] = [];
      const cashList: string[] = [];
      const transferList: string[] = [];

      data.forEach((contact: any) => {
        const {
          unique_id,
          is_customer,
          is_supplier,
          source,
          type,
          contact_channel_list,
          business_type,
          business_type_detail,
          title_name,
          name,
          last_name,
          status,
          remark_status,
          is_cheque,
          is_cash,
          is_transfer,
        } = contact;

        //Validate on API
        if (unique_id) {
          uniqueIdList.push(unique_id);
        }

        //Validate own file
        if (is_customer) {
          customerList.push(is_customer);
        }
        if (is_supplier) {
          supplierList.push(is_supplier);
        }
        if (type) {
          typeList.push(type);
        }
        if (source) {
          sourceList.push(source);
        }
        if (business_type) {
          businessTypeList.push(business_type);
        }
        if (business_type_detail) {
          businessTypeDetailList.push(business_type_detail);
        }
        if (title_name) {
          titleNameList.push(title_name);
        }
        if (name) {
          nameList.push(name);
        }
        if (last_name) {
          lastNameList.push(last_name);
        }
        if (status) {
          statusList.push(status);
        }
        if (remark_status) {
          remarkStatusList.push(remark_status);
        }
        if (is_cheque) {
          chequeList.push(is_cheque);
        }
        if (is_cash) {
          cashList.push(is_cash);
        }
        if (is_transfer) {
          transferList.push(is_transfer);
        }

        const contact_channel: IContactChannel[] = [];
        if (contact.contact_channel_list) {
          switch (contact.contact_channel_list) {
            case "เบอร์โทรศัพท์":
              contact_channel.push({
                contact_channel_type: "phone",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "Email":
              contact_channel.push({
                contact_channel_type: "email",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "Fax":
              contact_channel.push({
                contact_channel_type: "fax",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "Line":
              contact_channel.push({
                contact_channel_type: "line",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "Facebook":
              contact_channel.push({
                contact_channel_type: "facebook",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "Website":
              contact_channel.push({
                contact_channel_type: "website",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "Instagram":
              contact_channel.push({
                contact_channel_type: "instagram",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "มือถือ":
              contact_channel.push({
                contact_channel_type: "mobile_phone",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            case "อื่นๆ":
              contact_channel.push({
                contact_channel_type: "other",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
            default:
              contact_channel.push({
                contact_channel_type: "",
                contact_channel_info: formatString(
                  contact.contact_channel_info
                ),
              });
              break;
          }
        } else if (contact.contact_channel_info !== null) {
          contact_channel.push({
            contact_channel_type: "",
            contact_channel_info: formatString(contact.contact_channel_info),
          });
        }
        if (contact_channel.length > 0) {
          contactChannelList.push(contact_channel_list);
        }
        const address_list: IAddress[] = [
          {
            address_type: "ที่อยู่จดทะเบียน",
            is_default: true,
            address_contact_name: "",
            address_contact_phone: "",
            address_contact_fax: "",
            name: formatString(contact.address_list),
            sub_district: formatString(contact.sub_district),
            district: formatString(contact.district),
            province: formatString(contact.phoneRegExprovince),
            postal_code: formatString(contact.postal_code),
            country: countryFormatter(formatString(contact.country), true),
          },
        ];

        const formatSource = sourceFormatter(
          formatString(contact.source),
          false
        );

        const formatBusinessType = businessTypeFormatter(
          formatString(contact.business_type),
          false
        );

        const formatBusinessTypeDetail = companyTypeFormatter(
          formatString(contact.business_type_detail),
          false
        );

        const payment_type_list: string[] = [];

        if (contact.is_cheque === "ใช่") {
          payment_type_list.push("is_cheque");
        }

        if (contact.is_transfer === "ใช่") {
          payment_type_list.push("is_transfer");
        }

        if (contact.is_cash === "ใช่") {
          payment_type_list.push("is_cash");
        }

        const created_by = {
          user_id: authUser?.id,
          user_unique_id: authUser?.unique_id,
          email: authUser?.email,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
        };

        const mappedData = {
          unique_id: formatString(contact.unique_id) || "",
          img_url: formatStringToArray(contact.img_url) || [],
          is_customer: contact.is_customer === "ใช่" ? true : false,
          is_supplier: contact.is_supplier === "ใช่" ? true : false,
          business_type: formatBusinessType || "",
          business_type_detail: formatBusinessTypeDetail || "",
          title_name: formatString(contact.title_name) || "",
          name: formatString(contact.name) || "",
          last_name: formatString(contact.last_name) || "",
          registered_capital: formatFloat(contact.registered_capital) || 0,
          taxpayer_id: formatString(contact.taxpayer_id) || "",
          source: formatSource || "",
          contact_channel_list: contact_channel || [],
          status: contact.status === "ใช้งาน" ? 1 : 0,
          remark_status: formatString(contact.remark_status) || "",
          address_list,
          payment_type_list,
          payment_duration: contact.payment_duration
            ? parseInt(contact.payment_duration)
            : 0,
          payment_contact_person:
            formatString(contact.payment_contact_person) || "",
          payment_contact_phone:
            formatString(contact.payment_contact_phone) || "",
          billing_note_duration: contact.billing_note_duration
            ? parseInt(contact.billing_note_duration)
            : undefined,
          billing_note_contact_person:
            formatString(contact.billing_note_contact_person) || "",
          billing_note_contact_phone:
            formatString(contact.billing_note_contact_phone) || "",
          credit_request: contact.credit_request
            ? formatFloat(contact.purchase_amount_per_month)
            : undefined,
          credit_request_duration: contact.credit_request_duration
            ? parseInt(contact.credit_request_duration)
            : undefined,
          account_debtor: formatString(contact.account_debtor) || "",
          account_creditor: formatString(contact.account_creditor) || "",
          purchase_amount_per_month: contact.purchase_amount_per_month
            ? formatFloat(contact.purchase_amount_per_month)
            : undefined,
          remark: formatString(contact.remark) || "",
          created_by,
        };

        formattedData.push(mappedData);
      });
      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const noneBusinessType = findNoneBusinessType(businessTypeList);
      const noneBusinessTypeDetail = findNoneBusinessTypeDetail(
        businessTypeDetailList
      );
      const noneTitleName = findNoneTitleName(titleNameList);
      const noneContactChannelList =
        findNoneContactChannelList(contactChannelList);
      const noneStatus = findNoneStatus(statusList);
      const noneCustomer = findNoneBoolean(customerList);
      const noneSupplier = findNoneBoolean(supplierList);
      const noneCheque = findNoneBoolean(chequeList);
      const noneTransfer = findNoneBoolean(transferList);
      const noneCash = findNoneBoolean(cashList);
      const noneSource = findNoneSource(sourceList);

      const allError = [
        duplicateUniqueId,
        noneCustomer,
        noneSupplier,
        noneBusinessType,
        noneBusinessTypeDetail,
        noneTitleName,
        noneContactChannelList,
        noneSource,
        noneStatus,
        noneCheque,
        noneTransfer,
        noneCash,
        groupDataErrors,
        requiredFieldErrors,
        invalidTypeErrors,
      ];
      addImporterErrorHandler(
        errorData,
        `${t(`contact.unique_id`)}ในไฟล์ซ้ำ`,
        `${t(`contact.unique_id`)}`,
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ลูกค้า",
        noneCustomer
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ซัพพลายเออร์",
        noneSupplier
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทกิจการ",
        noneBusinessType
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ชื่อประเภทกิจการ",
        noneBusinessTypeDetail
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "คำนำหน้า",
        noneTitleName
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทช่องทางติดต่อ",
        noneContactChannelList
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "แหล่งที่มาผู้ติดต่อ",
        noneSource
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เช็ค",
        noneCheque
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "โอนเงิน",
        noneTransfer
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เงินสด",
        noneCash
      );
      setErrorData(errorData);
      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setContactUniqueIdList(Array.from(new Set(uniqueIdList)));

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (data.length === 0) {
        message = "กรุณาระบุข้อมูล";
      }
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else if (tempDoc.length > 0) {
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
      }
      // else {
      //   enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
      //     variant: "error",
      //   });
      // }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateContact();
      const existingUniqueId =
        data?.ValidateContactImport.existing_contact_unique_id_list || [];
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];

      const arrays = [existingUniqueId, missingUniqueId];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        `${t(`contact.unique_id`)}ในระบบซ้ำ`,
        `${t(`contact.unique_id`)}`,
        existingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `${t(`contact.unique_id`)}`,
        missingUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไฟล์ผู้ติดต่อไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await createOrUpdate({
            importInput: {
              import_mode: getValues("type"),
              data: rowData,
              // priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าไฟล์ผู้ติดต่อสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไฟล์ผู้ติดต่อไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไฟล์ผู้ติดต่อไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
  return {
    rowData,
    isLoading: isValidating || isCreatingOrUpdating,
    validateHandler,
    importHandler,
  };
};
export default useContactImporter;
