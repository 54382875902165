import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import TraceEntryReportTable from "components/Table/Inventory/Reports/TraceEntryReport";
import HeaderLayout from "components/UI/HeaderLayout";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useInventoryReport } from "hooks/Inventory/use-inventory-report";
import { InventoryReportType } from "generated/wms";
import { Navigate } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

const TraceEntry = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: `${t("report")}${t("inventory.report.trace_entry")}`,
    },
  ];

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isExporting, isFetching } = useInventoryReport(
    gridRef,
    InventoryReportType.TraceEntry
  );

  if (permissions && !permissions.inventory_export?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{`${t("report")}${t(
          "inventory.report.trace_entry"
        )}`}</Typography>
        {permissions?.inventory_export?.export && (
          <CustomizedButton
            title={`${t("button.export")}`}
            variant="contained"
            onClick={onBtnExport}
            disabled={isExporting && isFetching}
          />
        )}
      </HeaderLayout>
      <TraceEntryReportTable gridRef={gridRef} />
    </>
  );
};

export default TraceEntry;
