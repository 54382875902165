import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IExpensesList } from "types/Logistic";

export const useExpensesSummary = () => {
  const { control, setValue } = useFormContext();
  const expensesList: IExpensesList[] = useWatch({
    control,
    name: "expenses_list",
  });

  const [summary, setSummary] = useState({
    expenses_post_discount_amount: 0,
    expenses_vat_exempted_amount: 0,
    expenses_vat_0_percent_amount: 0,
    expenses_vat_7_percent_amount: 0,
    expenses_vat_amount: 0,
    expenses_net_amount: 0,
    expenses_withholding_tax_amount: 0,
    expenses_total_amount: 0,
  });

  useEffect(() => {
    setSummary(
      ({
        expenses_post_discount_amount,
        expenses_vat_exempted_amount,
        expenses_vat_0_percent_amount,
        expenses_vat_7_percent_amount,
        expenses_vat_amount,
        expenses_net_amount,
        expenses_withholding_tax_amount,
        expenses_total_amount,
      }) => {
        const postDiscountAmount = expensesList.reduce(
          (acc, item) => acc + (item.pre_vat_amount || 0),
          0
        );
        expenses_post_discount_amount = postDiscountAmount;

        const vatExemptedAmount = expensesList
          .filter((item) => item?.vat_percentage === "ไม่มี")
          .reduce((acc, item) => {
            return acc + (item.pre_vat_amount || 0);
          }, 0);

        expenses_vat_exempted_amount = vatExemptedAmount;

        const vat0percentAmount = expensesList
          .filter((item) => item?.vat_percentage === "0")
          .reduce((acc, item) => {
            return acc + (item.pre_vat_amount || 0);
          }, 0);

        expenses_vat_0_percent_amount = vat0percentAmount;

        const vat7percentAmount = expensesList
          .filter((item) => item?.vat_percentage === "7")
          .reduce((acc, item) => {
            return acc + (item.pre_vat_amount || 0);
          }, 0);

        expenses_vat_7_percent_amount = vat7percentAmount;

        expenses_vat_amount = vat7percentAmount * 0.07;

        expenses_net_amount =
          expenses_vat_exempted_amount +
          expenses_vat_0_percent_amount +
          expenses_vat_7_percent_amount +
          expenses_vat_amount;

        const withholdingTaxAmount =
          expensesList
            .filter(
              (item) =>
                !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                  item?.withholding_tax_type
                )
            )
            .reduce((acc, item) => {
              return (
                acc +
                ((item.pre_vat_amount && item.pre_vat_amount < 0
                  ? 0
                  : item.pre_vat_amount) *
                  parseFloat(item?.withholding_tax_type || "0")) /
                  100
              );
            }, 0) || 0;

        expenses_withholding_tax_amount =
          expenses_post_discount_amount <= 0 ? 0 : withholdingTaxAmount;

        expenses_total_amount =
          expenses_net_amount - expenses_withholding_tax_amount;

        return {
          expenses_post_discount_amount,
          expenses_vat_exempted_amount,
          expenses_vat_0_percent_amount,
          expenses_vat_7_percent_amount,
          expenses_vat_amount,
          expenses_net_amount,
          expenses_withholding_tax_amount,
          expenses_total_amount,
        };
      }
    );
  }, [expensesList]);

  useEffect(() => {
    setValue(
      "expenses_post_discount_amount",
      summary.expenses_post_discount_amount
    );
    setValue(
      "expenses_vat_exempted_amount",
      summary.expenses_vat_exempted_amount
    );
    setValue(
      "expenses_vat_0_percent_amount",
      summary.expenses_vat_0_percent_amount
    );
    setValue(
      "expenses_vat_7_percent_amount",
      summary.expenses_vat_7_percent_amount
    );
    setValue("expenses_vat_amount", summary.expenses_vat_amount);
    setValue("expenses_net_amount", summary.expenses_net_amount);
    setValue(
      "expenses_withholding_tax_amount",
      summary.expenses_withholding_tax_amount
    );
    setValue("expenses_total_amount", summary.expenses_total_amount);
  }, [
    setValue,
    summary.expenses_net_amount,
    summary.expenses_post_discount_amount,
    summary.expenses_total_amount,
    summary.expenses_vat_0_percent_amount,
    summary.expenses_vat_7_percent_amount,
    summary.expenses_vat_amount,
    summary.expenses_vat_exempted_amount,
    summary.expenses_withholding_tax_amount,
  ]);

  return summary;
};
