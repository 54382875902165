import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";

import { useModal } from "hooks/use-modal";
import { useDisable } from "hooks/use-disable";
import { useConfirmation } from "hooks/use-confirmation";
import {
  usePurchaseOrderCreate,
  usePurchaseOrderOption,
} from "hooks/Purchase/PurchaseOrder/use-purchase-order-option";
import { useWarehouseOptions } from "hooks/Inventory/use-inventory-setting-option";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import LabelInput from "components/UI/LabelInput";
import EmployeeListForm from "components/Form/EmployeeList";
import Confirmation from "components/UI/Confirmation";
import ExternalReferenceModal from "./ExternalReferenceModal";
import CustomizedChips from "components/Custom/CustomizedChips";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import ControlledSelect from "components/Controller/ControlledSelect";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { IExternalProps } from "types/Sales";
import { IMenuOption } from "types/global";
import { IPurchaseItemList } from "types/Purchase";
import { IPurchaseOrder } from "types/Purchase/purchaseOrder";
import {
  PurchaseModelType,
  PurchaseUniqueIdGenerateQuery,
  usePurchaseUniqueIdGenerateQuery,
} from "generated/purchase";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import {
  copyPurchaseOrderFormatter,
  createGoodsReceiveFromPurchaseOrder,
  createPurchaseReturnFromPurchaseOrder,
} from "utils/Formatter/Purchase/PurchaseOrder";
import CancelConfirmation from "components/UI/CancelConfirmation";

type Props = {
  editClickHandler: () => void;
  editHandler?: (data: IPurchaseOrder, status?: string) => Promise<void>;
  cancelHandler?: () => Promise<void>;
  externalReferenceHandler: (data: IExternalProps) => void;
};

const PurchaseOrderHeader = ({
  editHandler,
  cancelHandler,
  editClickHandler,
  externalReferenceHandler,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled] = useDisable();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IPurchaseOrder>();

  const { options } = useWarehouseOptions();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const item_list = useWatch({
    control,
    name: "item_list",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("purchase.order.index")}`,
      disabled: !id,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const { refetch: refetchUniqueId } =
    usePurchaseUniqueIdGenerateQuery<PurchaseUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: PurchaseModelType.PurchaseOrder,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const isMaxQty = item_list?.every(
    (item) => (item.po_qty || 0) + (item.wait_receive_qty || 0) >= item.qty
  );

  const selectModifyOptions = usePurchaseOrderOption(
    status,
    disabled,
    permissions?.purchase_order
  );

  const { selectCreateOptions, showCreateOptions } = usePurchaseOrderCreate(
    status,
    isMaxQty,
    permissions
  );

  const cancelLeftPOQty = async () => {
    const order = getValues();

    if (editHandler) await editHandler(order, "fully_imported");
  };

  const copyDocumentHandler = async () => {
    const order = getValues();

    navigate("/purchase/order/add", {
      state: await copyPurchaseOrderFormatter(order, authUser),
    });
  };

  const removeReferenceList = (reference_unique_id: string) => {
    const itemList = getValues("item_list");
    const removeRefItemList = itemList.filter(
      (list: IPurchaseItemList) =>
        list.reference_unique_id !== reference_unique_id
    );

    const removeRefList = reference_document_list?.filter(
      (list: any) => list.document_unique_id !== reference_unique_id
    );
    setValue("item_list", removeRefItemList);
    setValue("reference_document_list", removeRefList);
  };

  const updateExternalReference = async (data: {
    external_reference_no: string;
  }) => {
    if (data.external_reference_no) {
      externalReferenceHandler(data);
    }
    closeModalExternalReference();
  };

  const {
    modal: modalExternalReference,
    openModalHandler: openModalExternalReference,
    closeModalHandler: closeModalExternalReference,
  } = useModal();

  const {
    confirmation: cancelQtyConfirmation,
    openConfirmationHandler: openCancelQtyConfirmation,
    closeConfirmationHandler: closeCancelQtyConfirmation,
    submitConfirmationHandler: submitCancelQtyConfirmation,
  } = useConfirmation(cancelLeftPOQty);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("purchase.order.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent="end">
              {showCreateOptions && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"พิมพ์เอกสาร"}
                    options={printOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      if (
                        value.innerText === `พิมพ์${t("purchase.order.index")}`
                      ) {
                        navigate(`/purchase/order/${id}/pdf`);
                      }
                    }}
                    disabled={
                      !id || status === "cancelled" || status === "not_approved"
                    }
                  />
                </Grid>
              )}
              {selectModifyOptions?.length > 0 && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "อ้างอิงเอกสารภายนอก":
                          openModalExternalReference();
                          break;
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "ยกเลิกจำนวนคงค้าง":
                          openCancelQtyConfirmation();
                          break;
                        case "คัดลอก":
                          openCopyConfirmation();
                          break;
                        case "ยกเลิก":
                          openCancelConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                </Grid>
              )}
              {selectCreateOptions?.length > 0 &&
                !["wait_ordered"].includes(status || "") && (
                  <Grid item sm={5.5} md={4}>
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={"สร้าง"}
                      options={selectCreateOptions}
                      variant="contained"
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        const order = getValues();
                        switch (value.innerText) {
                          case `${t("inventory.goods_receive.index")}`:
                            navigate("/inventory/goods-receive/add", {
                              state: createGoodsReceiveFromPurchaseOrder(
                                order,
                                authUser
                              ),
                            });
                            break;
                          case `${t("purchase.return.index")}`:
                            navigate("/purchase/return/add", {
                              state:
                                createPurchaseReturnFromPurchaseOrder(order),
                            });
                            break;

                          default:
                        }
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("purchase.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id)}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box>
                {reference_document_list && reference_document_list.length > 0
                  ? reference_document_list.map((reference: any) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          if (reference.document_type === "purchase_request") {
                            window.open(
                              `/purchase/request/${encodeURIComponent(
                                reference.document_id
                              )}`,
                              "_blank"
                            );
                          }
                        }}
                        handleDelete={
                          !disabled
                            ? () =>
                                removeReferenceList(
                                  reference.document_unique_id
                                )
                            : null
                        }
                        // value={reference.document_unique_id}
                        color="secondary"
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                viewMode={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>{t("warehouse.source")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              {disabled ? (
                <LabelInput
                  label={""}
                  value={
                    getValues("destination_warehouse")
                      ? getValues("destination_warehouse")?.unique_id +
                          " - " +
                          getValues("destination_warehouse")?.name || ""
                      : "-"
                  }
                />
              ) : (
                <ControlledSelect
                  control={control}
                  name="destination_warehouse_id"
                  placeholder={t("warehouse.index")}
                  options={options}
                  error={Boolean(errors.destination_warehouse)}
                  helperText={errors?.destination_warehouse?.message?.toString()}
                />
              )}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box>
                <EmployeeListForm />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="expected_date"
                control={control}
                label={t("date.purchase_expected_date")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="within_date"
                control={control}
                label={t("date.purchase_within_date")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                label={t("date.purchase_due_date")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                name="credit_day"
                control={control}
                label={t("purchase.credit_day")}
                disabled={disabled}
                viewMode={disabled}
                decimalScale={0}
                textAlign="left"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ExternalReferenceModal
        open={modalExternalReference}
        handleClose={closeModalExternalReference}
        action={updateExternalReference}
      />
      <CancelConfirmation
        uniqueId={getValues("unique_id") || ""}
        confirmation={cancelConfirmation}
        closeConfirmationHandler={closeCancelConfirmation}
        submitConfirmationHandler={submitCancelConfirmation}
      />
      <Confirmation
        title="ยกเลิกจำนวนคงค้าง"
        message="หากยกเลิกจำนวนคงค้างจะไม่สามารถแก้ไขได้"
        open={cancelQtyConfirmation}
        handleClose={closeCancelQtyConfirmation}
        action={submitCancelQtyConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
    </Box>
  );
};

export default PurchaseOrderHeader;
