import { Typography, Stack } from "@mui/material";
import LoadingAnimation from "components/Animation/Loading";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import LocationImportUploader from "components/Form/Logistic/Location/ImportUploader";
import LocationImporterTable from "components/Table/Logistic/Location/ImporterTable";
import LocationImporterErrorModal from "components/UI/Modal/LocationImporterErrorModal";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import useLocationImporter from "hooks/Importer/Location/use-location-importer";
import { useModal } from "hooks/use-modal";
import { useState, MouseEventHandler } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu } from "types/global";

const LocationImporter = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.location.index"),
      to: "/logistic/location",
    },
    {
      name: "นำเข้าข้อมูลสถานที่",
      to: "/logistic/location/import",
    },
  ];
  // const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const [errorData, setErrorData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const methods = useForm<any>({
    defaultValues: {
      type: "create",
      file: {},
      step: 0,
      errors: [],
    },
  });

  // const tabs: ITab[] = [
  //     {
  //         label: t("status.success"),
  //         path: `${pathname}`,
  //     },
  //     {
  //         label: t("status.failed"),
  //         path: `${pathname}?filter=failed`,
  //     },
  // ];

  const { watch, getValues, setValue } = methods;
  const activeStep = watch("step");
  const watchType = watch("type");

  // const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const { importHandler, isLoading, rowData, validateHandler } =
    useLocationImporter(
      watchType,
      getValues,
      setValue,
      setErrorData,
      openModalHandler
    );

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const renderTable = () => {
    if (isFilter)
      return <LocationImporterTable data={[]} watchType={watchType} />;
    return <LocationImporterTable data={rowData} watchType={watchType} />;
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <LocationImportUploader />;
    } else {
      return (
        <>
          {/* {step === 3 && (
                        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
                    )} */}
          {renderTable()}
        </>
      );
    }
  };

  const renderButton = (step: number) => {
    const getHandler = (
      handlerType: "validate" | "import"
    ): MouseEventHandler<HTMLButtonElement> | undefined => {
      if (handlerType === "validate") {
        return async () => {
          setLoading(true); // Set loading to true
          try {
            await validateHandler();
          } finally {
            setLoading(false); // Set loading to false after validation
          }
        };
      }
      return importHandler;
    };

    const validateLocationHandler = getHandler("validate");
    const importLocationHandler = getHandler("import");

    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateLocationHandler}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importLocationHandler}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูสถานที่ทั้งหมด"
            variant="contained"
            onClick={() => navigate("/logistic/location")}
          />
        );
      default:
        return null;
    }
  };

  if (permissions && !permissions.location?.create) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (isLoading || loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {"นำเข้าข้อมูลสถานที่"}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          {/* <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box> */}
          {renderContent(activeStep)}
        </CustomizedBox>
      </FormProvider>
      <BottomNavbar>
        <Stack direction={"row"} gap={1}>
          {renderButton(activeStep)}
        </Stack>
      </BottomNavbar>
      <LocationImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default LocationImporter;
