import { gql } from "graphql-request";

export const ROLE_VIEW = gql`
  query RoleViewsAggrid($aggridInput: AnyAggridInput) {
    RoleViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        name
        description
        total_users
      }
    }
  }
`;
