import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useStateContext } from "contexts/auth-context";
import { useDisable } from "hooks/use-disable";

import { Avatar, Box, Grid, Typography } from "@mui/material";

import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "components/Custom/CustomizedLetterAvatar";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import { useConfirmation } from "hooks/use-confirmation";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import NoSimOutlinedIcon from "@mui/icons-material/NoSimOutlined";

import { IGoodsReceive } from "types/Inventory/goodsReceive";
import { IMenuOption } from "types/global";

interface Props {
  cancelHandler: () => void;
}

const GoodsReceiveHeader = ({ cancelHandler }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelHandler);

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const { control, setValue, getValues } = useFormContext<IGoodsReceive>();

  const status = useWatch({ control, name: "aggrid_status" });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  useEffect(() => {
    if (!id && authUser) {
      setValue("created_by", {
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, id, setValue]);

  const isFinished = ["finished", "cancelled"].includes(status);

  const disabledMenuOption =
    !id || status === "finished" || status === "cancelled";

  const options: IMenuOption[] = [
    {
      value: t("status.cancelled"),
      disabled: false,
    },
  ];

  return (
    <>
      <Box maxWidth={1040} my={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography variant="h5">
                {id
                  ? getValues("unique_id")
                  : `${t("button.create")}${t(
                      "inventory.goods_receive.index"
                    )}`}
              </Typography>
              <CustomizedStatus status={status} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 1,
              }}
            > */}
            {!disabledMenuOption && (
              <Grid container justifyContent="end" spacing={1.5}>
                <Grid item xs={6} sm={6} md={4}>
                  {permissions?.goods_receive?.cancel && (
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={t("button.option")}
                      options={options}
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        switch (value.innerText) {
                          case `${t("status.cancelled")}`:
                            openConfirmationHandler();
                            break;
                          default:
                        }
                      }}
                      sx={{
                        "& .MuiButton-endIcon": {
                          marginLeft: "4px",
                        },
                        paddingX: "24px",
                        borderRadius: "8px",
                        fontSize: "13px",
                      }}
                      disabled={!id}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {/* </Box> */}
          </Grid>
        </Grid>
        <Grid container spacing={1.5} mt={1} alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
              <Typography fontWeight="bold">
                {t("sentence.created_by")}
              </Typography>
              <CustomizedTooltip
                title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
                enterNextDelay={200}
              >
                {watchCreatedBy?.img_url &&
                watchCreatedBy?.img_url.length > 0 ? (
                  <Avatar
                    alt={`avatar`}
                    src={
                      watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""
                    }
                  />
                ) : (
                  <CustomizedLetterAvatar
                    name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
                  />
                )}
              </CustomizedTooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent="end">
              <Grid item xs={6} sm={6} md={4}>
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  label={t("date.created_date")}
                  disabled={true}
                  viewMode={disabled || isFinished}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <ControlledDatePicker
                  name="posted_date"
                  control={control}
                  label={t("date.received_date")}
                  viewMode={disabled || isFinished}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        title="ยืนยันการยกเลิกเอกสาร"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <NoSimOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"ยืนยันการยกเลิกเอกสาร"}</Typography>
            <Typography fontWeight="bold">{getValues("unique_id")}</Typography>
            <Typography>{"หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"}</Typography>
          </Box>
        }
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
        noDivider
      />
    </>
  );
};

export default GoodsReceiveHeader;
