import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { theme } from "./theme";
import { ThemeProvider } from "@mui/material";
import CustomizedSnackbar from "./components/Custom/CustomizedSnackbar";

import { NavbarContextProvider } from "./contexts/navbar-context";
import { DisableContextProvider } from "./contexts/disable-context";
import { StateContextProvider } from "./contexts/auth-context";
import AuthMiddleware from "middleware/AuthMiddleware";
import ScrollToTop from "pages/ScrollToTop";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <CustomizedSnackbar>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StateContextProvider>
            <AuthMiddleware>
              <NavbarContextProvider>
                <DisableContextProvider>
                  <ScrollToTop />
                  <App />
                </DisableContextProvider>
              </NavbarContextProvider>
            </AuthMiddleware>
          </StateContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </CustomizedSnackbar>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
