import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import GoodsTransferHeader from "components/Form/Inventory/GoodsTransfer/Header";
import GoodsTransferInfo from "components/Form/Inventory/GoodsTransfer/Info";
import GoodsTransferItemList from "components/Form/Inventory/GoodsTransfer/itemList";
import {
  goodsTransferSchema,
  goodsTransferValidation,
} from "components/Form/Inventory/GoodsTransfer/schema";
import GoodsTransferHeaderBreadcrumbs from "components/Form/Inventory/InventoryControl/HeaderBreadcrumbs";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import { useStateContext } from "contexts/auth-context";
import { ActivityType } from "generated/general";
import {
  ActivityLogDocumentType,
  GoodTransferCreateInput,
  GoodTransferFindUniqueQuery,
  GoodTransferUpdateInput,
  InventoryControlDocumentType,
  ItemSkuQtysQuery,
  useGoodTransferCreateMutation,
  useGoodTransferFindUniqueQuery,
  useGoodTransferUpdateMutation,
  useInventoryDocumentCancelMutation,
  useItemSkuQtysQuery,
} from "generated/wms";
import { useInventoryError } from "hooks/Inventory/use-inventory-error";
import { useActivityLog } from "hooks/use-activity-log";
import { useConfirmation } from "hooks/use-confirmation";
import { useDisable } from "hooks/use-disable";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IGoodsTransfer } from "types/Inventory/goodsTransfer";
import {
  goodsTransferCreatePayloadFormatter,
  goodsTransferQueryFormatter,
  goodsTransferUpdatePayloadFormatter,
} from "utils/Formatter/Inventory/GoodsTransfer";
import { errorMessageFormatter } from "utils/Global";
import CheckIcon from "@mui/icons-material/Check";

const GoodsTransferContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [barcodeList, setBarcodeList] = useState<any[]>([]);
  const [, setDisabled] = useDisable();
  const methods = useForm<IGoodsTransfer>({
    defaultValues: goodsTransferSchema,
    resolver: yupResolver<any>(goodsTransferValidation),
  });
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
    reset,
    watch,
  } = methods;

  const { createActivityLog } = useActivityLog();

  const status = watch("aggrid_status");

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  useEffect(() => {
    if (
      id &&
      status &&
      (status === "finished" ||
        status === "cancelled" ||
        !permissions?.goods_transfer?.update)
    ) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status, permissions]);

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading, refetch, isSuccess } =
    useGoodTransferFindUniqueQuery<GoodTransferFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { data: allSkuQtys, isSuccess: isSkuSuccess } =
    useItemSkuQtysQuery<ItemSkuQtysQuery>(
      graphQLClient,
      {
        findManyInput: {
          where: {
            barcode: {
              in: barcodeList,
            },
          },
        },
      },
      {
        enabled: barcodeList.length > 0,
      }
    );

  useEffect(() => {
    if (id && isSuccess) {
      const { GoodTransferFindUnique: GoodsTransfer } = data;
      const traceEntryList = GoodsTransfer?.trace_entry_list;
      const allBarcode = traceEntryList?.map((trace) => trace?.barcode) || [];
      setBarcodeList(allBarcode);

      const goodsTransferType = GoodsTransfer as IGoodsTransfer;

      const formatGoodsTransfer = goodsTransferQueryFormatter(
        goodsTransferType,
        allSkuQtys
      );

      reset(formatGoodsTransfer);
    }
  }, [data, id, isSuccess, reset, isSkuSuccess, allSkuQtys]);

  const { mutateAsync: createGoodsTransfer, isLoading: isCreating } =
    useGoodTransferCreateMutation<Error>(graphQLClient);

  const { mutateAsync: updateGoodsTransfer, isLoading: isUpdating } =
    useGoodTransferUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancelGoodsTransfer, isLoading: isCancelling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const draftHandler = async (data: IGoodsTransfer) => {
    try {
      if (!id) {
        const payload = goodsTransferCreatePayloadFormatter(
          data,
          "draft"
        ) as GoodTransferCreateInput;

        const { GoodTransferCreate } = await createGoodsTransfer({
          data: payload,
        });

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: GoodTransferCreate?.id as number,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });

        navigate(`/inventory/goods-transfer/${GoodTransferCreate?.id}`);
      } else {
        const payload = goodsTransferUpdatePayloadFormatter(
          data,
          "draft",
          authUser
        ) as GoodTransferUpdateInput;
        const { GoodTransferUpdate } = await updateGoodsTransfer({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          data: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: GoodTransferUpdate?.id as number,
          activity_detail: {},
        });
        await refetch();
      }
      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const waitTransferHandler = async (data: IGoodsTransfer) => {
    try {
      if (!id) {
        const payload = goodsTransferCreatePayloadFormatter(
          data,
          "wait_transfer"
        ) as GoodTransferCreateInput;
        const { GoodTransferCreate } = await createGoodsTransfer({
          data: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: GoodTransferCreate?.id as number,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_transfer",
          },
        });
        navigate(`/inventory/goods-transfer/${GoodTransferCreate?.id}`);
      } else {
        const payload = goodsTransferUpdatePayloadFormatter(
          data,
          "wait_transfer",
          authUser
        ) as GoodTransferUpdateInput;
        const { GoodTransferUpdate } = await updateGoodsTransfer({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          data: payload,
        });

        await refetch();

        const isDirty = Object.keys(dirtyFields)?.length > 0;

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsTransfer,
          reference_id: GoodTransferUpdate?.id as number,
          activity_detail: {
            secondary_operation: isDirty ? ActivityType.Edit : undefined,
            prev_status: status,
            curr_status: "wait_transfer",
          },
        });
      }
      enqueueSnackbar(`${t("button.next")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(formatError || `${t("button.next")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const saveHandler = async (data: IGoodsTransfer) => {
    try {
      const payload = goodsTransferUpdatePayloadFormatter(
        data,
        status || "",
        authUser
      ) as GoodTransferUpdateInput;
      const { GoodTransferUpdate } = await updateGoodsTransfer({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: payload,
      });

      await refetch();

      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.GoodsTransfer,
        reference_id: GoodTransferUpdate?.id as number,
        activity_detail: {},
      });

      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const transferHandler = async (data: IGoodsTransfer) => {
    try {
      const payload = goodsTransferUpdatePayloadFormatter(
        data,
        "finished",
        authUser
      ) as GoodTransferUpdateInput;
      const { GoodTransferUpdate } = await updateGoodsTransfer({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: payload,
      });

      await refetch();

      const isDirty = Object.keys(dirtyFields)?.length > 0;

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsTransfer,
        reference_id: GoodTransferUpdate?.id as number,
        activity_detail: {
          secondary_operation: isDirty ? ActivityType.Edit : undefined,
          prev_status: status,
          curr_status: "finished",
        },
      });

      enqueueSnackbar(`${t("inventory.goods_transfer.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("inventory.goods_transfer.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancelGoodsTransfer({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryControlDocumentType.GoodTransfer,
      });

      await refetch();
      enqueueSnackbar(
        `${t("button.cancel")}${t("inventory.goods_transfer.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsTransfer,
        reference_id: id ? parseInt(id) : 0,
        activity_detail: {
          prev_status: status,
          curr_status: "cancelled",
        },
      });
    } catch (err) {
      enqueueSnackbar(
        `${t("button.cancel")}${t("inventory.goods_transfer.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const {
    confirmation: transferConfirmation,
    openConfirmationHandler: openTransferConfirmationHandler,
    closeConfirmationHandler: closeTransferConfirmationHandler,
    submitConfirmationHandler: submitTransferConfirmationHandler,
  } = useConfirmation(handleSubmit(transferHandler));

  const renderButton = (status?: string) => {
    switch (status) {
      case "draft":
        if (!permissions?.goods_transfer?.update) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(draftHandler)}
              disabled={isMutating}
            />
            <CustomizedButton
              variant="contained"
              title={t("button.next")}
              onClick={handleSubmit(waitTransferHandler)}
              disabled={isMutating}
            />
          </>
        );
      case "wait_transfer":
        if (!permissions?.goods_transfer?.update) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(saveHandler)}
              disabled={isMutating}
            />
            <CustomizedButton
              variant="contained"
              title={t("inventory.goods_transfer.index")}
              onClick={() => {
                let foundUnscanned = false;
                for (const trace of fields) {
                  if (foundUnscanned) {
                    break;
                  }
                  if (trace.status === "is_active") {
                    foundUnscanned = true;
                    break;
                  }
                  if (trace.serial_list) {
                    for (const serial of trace.serial_list) {
                      if (serial.status === "is_active") {
                        foundUnscanned = true;
                        break;
                      }
                    }
                  }
                }
                if (foundUnscanned) {
                  openTransferConfirmationHandler();
                } else {
                  handleSubmit(transferHandler)();
                }
              }}
              disabled={isMutating}
            />
          </>
        );
      case "finished":
        return;
      default:
        if (status && !permissions?.goods_transfer?.update) {
          return <></>;
        }
        if (!status && !permissions?.goods_transfer?.create) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(draftHandler)}
              disabled={isMutating}
            />
            <CustomizedButton
              variant="contained"
              title={t("button.next")}
              onClick={handleSubmit(waitTransferHandler)}
              disabled={isMutating}
            />
          </>
        );
    }
  };

  const isMutating = isCreating || isUpdating || isCancelling;

  useInventoryError(errors);

  if (id && (isLoading || isMutating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (permissions && !permissions.goods_transfer?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <FormProvider {...methods}>
      <GoodsTransferHeaderBreadcrumbs
        type={InventoryControlDocumentType.GoodTransfer}
      />
      <GoodsTransferHeader cancelHandler={cancelHandler} />
      <GoodsTransferInfo replace={replace} />
      <GoodsTransferItemList
        append={append}
        fields={fields}
        remove={remove}
        update={update}
      />
      <BottomNavbar>
        {status !== "finished" && status !== "cancelled" && (
          <Stack direction="row" spacing={1} alignItems="center">
            {renderButton(status)}
          </Stack>
        )}
      </BottomNavbar>
      <ConfirmationModal
        title="ยืนยันการโอนย้ายสินค้า"
        message={
          <Box textAlign="center">
            <CheckIcon
              sx={{
                fontSize: "4rem",
                color: "success.main",
                backgroundColor: "success.light",
                borderRadius: "100px",
              }}
            />
            <Typography textAlign="center">
              <span>{"ขณะนี้มีสินค้า"}</span>
              <span style={{ fontWeight: "bold" }}>{` "รอสแกน" `}</span>
              <span>{"อยู่"}</span>
            </Typography>
            <Typography textAlign="center">
              <span>{"หากโอนย้ายแล้วจะเปลี่ยนสถานะสินค้าเป็น"}</span>
              <span style={{ fontWeight: "bold" }}>{` "ยกเลิก"`}</span>
            </Typography>
            <Typography textAlign="center">
              {"และไม่สามารถแก้ไขเอกสารได้"}
            </Typography>
          </Box>
        }
        open={transferConfirmation}
        handleClose={closeTransferConfirmationHandler}
        action={submitTransferConfirmationHandler}
        noDivider
      />
    </FormProvider>
  );
};

export default GoodsTransferContainer;
