import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const useTireListHeader = (disabled: boolean, status?: string) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        label: "Serial ยาง",
        width: 150,
        align: "left",
      },
      {
        label: "ยี่ห้อ",
        width: 150,
        align: "left",
      },
      {
        label: "รุ่น",
        width: 100,
        align: "left",
      },
      {
        label: "ตำแหน่ง",
        width: 100,
        align: "left",
      },
      {
        label: "เลขกิโลเมตรยาง",
        width: 120,
        align: "right",
      },
      {
        label: "ระยะครบกำหนด",
        width: 120,
        align: "right",
      },
    ];

    if (!disabled) {
      const headers = [
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ];
      setHeaders(headers);
    } else {
      const headers = defaultHeaders;
      setHeaders(headers);
    }
  }, [disabled, status]);

  return headers;
};

export const useTireListSwapPositionHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        label: "Serial ยาง",
        width: 150,
        align: "left",
      },
      {
        label: "ยี่ห้อ",
        width: 150,
        align: "left",
      },
      {
        label: "รุ่น",
        width: 100,
        align: "left",
      },
      {
        label: "ตำแหน่ง",
        width: 100,
        align: "left",
      },
      {
        label: "เลขกิโลเมตรยาง",
        width: 120,
        align: "right",
      },
      {
        label: "ระยะครบกำหนด",
        width: 120,
        align: "right",
      },
      {
        label: "หมายเหตุ",
        width: 120,
        align: "left",
      },
    ];

    setHeaders(defaultHeaders);
  }, []);

  return headers;
};
