import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import {
  useLocation,
  useParams,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateContext } from "contexts/auth-context";

import { useActivityLog } from "hooks/use-activity-log";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import { ITire } from "types/Logistic/tire";
import { IBreadcrumbsAndMenu, ITab } from "types/global";
import { ActivityLogDocumentType } from "generated/vehicle";
import { TireFineUniqueQuery, useTireFineUniqueQuery } from "generated/sales";

import HistoryTab from "./HistoryTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { ActivityLog } from "components/UI/ActivityLog";
import CustomizedTab from "components/Custom/CustomizedTab";
import {
  tireSchema,
  tireValidation,
} from "components/Form/Logistic/Tire/schema";
import BreadcrumbsLayout from "../../../components/UI/BreadcrumbsLayout";

const TireContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<ITire>({
    defaultValues: { ...tireSchema },
    resolver: yupResolver<any>(tireValidation),
  });

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  const tabs: ITab[] = [
    {
      label: t("logistic.tire.info"),
      path: `${pathname}`,
    },
    {
      label: t("logistic.tire.history.index"),
      path: `${pathname}?tab=history`,
    },
  ];

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const { data } = useTireFineUniqueQuery<TireFineUniqueQuery>(
    graphQLClient,
    {
      tireFineUniqueId: id ? parseInt(id) : -1,
    },
    {
      enabled: !!id,
      cacheTime: 0,
    }
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.tire.index"),
      to: "/logistic/tire",
    },
    {
      name: id
        ? data?.TireFineUnique?.serial || "-"
        : "เพิ่ม" + t("logistic.tire.index"),
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "history":
        return <HistoryTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  if (permissions && !permissions.tire?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        onActivityLogOpen={onActivityLogOpen}
      />
      {id && <CustomizedTab tabs={tabs} currentTab={currentTab} divider />}
      {renderTab(tab)}
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={ActivityLogDocumentType.Tire}
        />
      )}
    </FormProvider>
  );
};

export default TireContainer;
