import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

import { Box, Typography } from "@mui/material";

import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import PurchaseOrderTable from "components/Table/Purchase/Order";

import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "types/global";
import { useModal } from "hooks/use-modal";
import ExporterPurchaseModal from "components/UI/Modal/ExporterPurchaseModal";
import { PurchaseExportType } from "generated/purchase";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import HeaderLayout from "components/UI/HeaderLayout";

const PurchaseOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const {
    state: { permissions },
  } = useStateContext();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_ordered"),
      path: `${pathname}?filter=wait_ordered`,
    },
    {
      label: t("status.fully_ordered"),
      path: `${pathname}?filter=fully_ordered`,
    },
    {
      label: t("status.partially_imported"),
      path: `${pathname}?filter=partially_imported`,
    },
    {
      label: t("status.fully_imported"),
      path: `${pathname}?filter=fully_imported`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  // const onFilterReset = () => {
  //   if (gridRef) {
  //     gridRef.current?.api.setFilterModel({});
  //   }
  // };

  const options: IMenuOption[] = [
    {
      value: t("purchase.export"),
    },
  ];

  if (permissions && !permissions.purchase_order?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5" marginY={"auto"}>
          {t("purchase.order.index")}
        </Typography>
        <Box display="flex">
          <Box ml="auto">
            {permissions?.purchase_order?.create && (
              <CustomizedButton
                title={`${t("button.create")}${t("purchase.order.index")}`}
                variant="contained"
                onClick={() => navigate(`${pathname}/add`)}
                startAddIcon
              />
            )}
            {permissions?.purchase_order?.export && (
              <CustomizedMenuOptionsMeatball
                isIcon
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("purchase.export")}`:
                      openModalHandler();
                      break;
                    default:
                  }
                }}
                sx={{
                  width: "46px",
                  minWidth: "46px",
                }}
                disableRipple={true}
              />
            )}
          </Box>
        </Box>
      </HeaderLayout>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <PurchaseOrderTable isFilter={isFilter} />
      <ExporterPurchaseModal
        open={modal}
        closeModalHandler={closeModalHandler}
        documentType={PurchaseExportType.PurchaseOrderExport}
      />
    </>
  );
};

export default PurchaseOrder;
