import { GraphQLClient } from "graphql-request";
import { Cookies } from "react-cookie";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

// Define endpoint URLs for different services
const endpoints: Record<string, string> = {
  user: "user",
  contact: "contact",
  wms: "wms",
  sales: "sales",
  purchase: "purchase",
  logistic: "logistic",
  general: "general",
  crm: "crm",
  manufacture: "manufacture",
};

// Define the types for query and variables
type GraphQLQuery = string;
type GraphQLVariables = Record<string, any>;

// Create a custom GraphQLClient with middleware
class GraphQLClientWithMiddleware extends GraphQLClient {
  constructor(endpoint: string) {
    super(endpoint, {
      credentials: "include",
      mode: "cors",
    });
  }

  async requestWithMiddleware(
    query: GraphQLQuery,
    variables: GraphQLVariables
  ): Promise<any> {
    try {
      const data = await super.request(query, variables);
      //   Sentry.addBreadcrumb({
      //     category: "graphql-query",
      //     message: "GraphQL Query",
      //     data: {
      //       query,
      //       variables,
      //     },
      //   });
      //   Sentry.addBreadcrumb({
      //     category: "graphql-response",
      //     message: "GraphQL Response",
      //     data: {
      //       response: data,
      //     },
      //   });
      return data;
    } catch (error) {
      //   Sentry.captureException(error);
      //   throw error;
    }
  }
}

// Modified createGraphQLClient function with middleware
export const createGraphQLClientWithMiddleware = (
  service: string,
  includeAuthorizationHeader: boolean = true
): GraphQLClientWithMiddleware => {
  let baseEndpoint =
    ENVIRONMENT === "development"
      ? "https://appa-dev-api.npr.digital"
      : "https://appa-api.npr.digital";
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");

  const endpoint = `${baseEndpoint}/${endpoints[service]}/graphql`;

  // if (endpoints[service] === "sales") {
  //   endpoint = `http://localhost:3000/${endpoints[service]}/graphql`;
  // }

  // if (endpoints[service] === "general") {
  //   endpoint = `http://localhost:3002/${endpoints[service]}/graphql`;
  // }

  const client = new GraphQLClientWithMiddleware(endpoint);

  if (includeAuthorizationHeader) {
    client.setHeader("authorization", `Bearer ${access_token}`);
  }

  return client;
};
