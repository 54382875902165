import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { IAccessPermission } from "types/Auth/role";

export const useDeliveryTripOption = (
  status?: string | null,
  isEditing?: boolean,
  deliveryTripPermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        deliveryTripPermissions?.update && {
          value: "แก้ไข",
          disabled: isEditing,
        },
        deliveryTripPermissions?.create && {
          value: "คัดลอก",
          disabled: false,
        },
        deliveryTripPermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
      ] as IMenuOption[],
    [isEditing, deliveryTripPermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions(
          [defaultOptions[1], defaultOptions[2]].filter(Boolean)
        ); // ยกเลิก, คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // คัดลอก
        break;
      case "finished":
        setSelectedModifyOptions(
          [defaultOptions[0], defaultOptions[1]].filter(Boolean)
        ); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
