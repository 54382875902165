import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "types/global";

import HeaderLayout from "components/UI/HeaderLayout";
import TireTable from "components/Table/Logistic/Tire";
import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import LoadingAnimation from "components/Animation/Loading";
import ExporterMaintenanceModal from "components/UI/Modal/ExporterMaintenanceModal";
import {
  TireUsageGenerateExportInput,
  TireUsageGenerateExportQuery,
  useTireUsageGenerateExportQuery,
} from "generated/sales";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useModal } from "hooks/use-modal";

const Tire = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("status");
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.tire.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("logistic.tire.stock"),
      path: `${pathname}?status=stock`,
    },
    {
      label: t("status.active"),
      path: `${pathname}?status=active`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?status=in_active`,
    },
  ];

  const currentTab = pathname + (filter ? `?status=${filter}` : "");

  const options: IMenuOption[] = [
    {
      value: t("purchase.export"),
    },
  ];

  const {
    modal: exportModal,
    openModalHandler: openExportModalHandler,
    closeModalHandler: closeExportModalHandler,
  } = useModal();

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const [exportInput, setExportInput] =
    useState<TireUsageGenerateExportInput | null>(null);

  const { refetch: refetchData, isLoading: isExporting } =
    useTireUsageGenerateExportQuery<TireUsageGenerateExportQuery>(
      graphQLClientWithHeader,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: any) => {
    if (data) {
      const { end_date, start_date } = data;
      setExportInput({
        start_date,
        end_date,
      });
    } else {
      enqueueSnackbar("Export data is required", { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        if (exportInput) {
          const { data: exportData } = await refetchData();

          if (exportData?.TireUsageGenerateExport) {
            const url = exportData?.TireUsageGenerateExport;
            window.open(url, "_blank");
            enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
              variant: "success",
            });
          } else {
            throw new Error("No data received");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [exportInput, refetchData, t]);

  if (permissions && !permissions.tire?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (exportInput && isExporting) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("logistic.tire.index")}</Typography>
        <Box display="flex">
          <Box ml="auto">
            {permissions?.tire?.create && (
              <CustomizedButton
                title={`${t("button.create")}${t("logistic.tire.index")}`}
                variant="contained"
                onClick={() => navigate(`${pathname}/add`)}
                startAddIcon
              />
            )}
            {permissions?.tire?.export && (
              <CustomizedMenuOptionsMeatball
                isIcon
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("purchase.export")}`:
                      openExportModalHandler();
                      break;
                    default:
                  }
                }}
                sx={{
                  width: "46px",
                  minWidth: "46px",
                }}
                disableRipple={true}
              />
            )}
          </Box>
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <TireTable status={filter} />
      <ExporterMaintenanceModal
        open={exportModal}
        closeModalHandler={closeExportModalHandler}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default Tire;
