import dayjs from "dayjs";
import { VehicleStatus, VehicleType } from "generated/vehicle";
import { FieldErrors } from "react-hook-form";
import { IVehicle, IVehicleImage, IVehicleTire } from "types/Logistic/vehicle";
import { uploadFileToS3 } from "utils/s3";

export const typeValueFormatter = (type: string) => {
  switch (type) {
    case "head":
      return "หัวลาก";
    case "four_wheel":
      return "4 ล้อ";
    case "six_wheel":
      return "6 ล้อ";
    case "ten_wheel":
      return "10 ล้อ";
    case "trailer":
      return "หางลาก";
    default:
      return "";
  }
};

export const typeStatusValueFormatter = (value: string): string => {
  switch (value) {
    case "normal":
      return "ปกติ";
    case "approaching":
      return "ใกล้ครบกำหนด";
    case "overdue":
      return "เกินกำหนด";
    default:
      return "ไม่มีข้อมูล";
  }
};

export const vehicleCreateFormatter = async (
  data: IVehicle,
  id?: string | undefined
) => {
  const {
    created_by,
    image,
    main_image_index,
    trailer,
    trailer_unique_id,
    trailer_id,
    created_date,
    last_updated_date,
    last_updated_by,
    related_user_list,
    main_tire,
    spare_tire,
    uninstall_tire,
    driver,
    ...rest
  } = data;

  const imageUrlList = image as any[];

  let uploded_img_list: string[] = [];

  if (imageUrlList && imageUrlList.length > 0) {
    for (const file of imageUrlList) {
      if (typeof file === "object") {
        const { Location } = await uploadFileToS3(
          file,
          "vehicle",
          id?.toString() || ""
        );
        uploded_img_list.push(Location);
      } else {
        uploded_img_list.push(file);
      }
    }
  } else {
    uploded_img_list = imageUrlList;
  }

  const formatImageUrlList = uploded_img_list.map((img, index) => {
    return {
      file_path: img,
      is_primary: main_image_index === index,
    };
  }) as IVehicleImage[];

  const formatTire = [...(main_tire || []), ...(spare_tire || [])]?.map(
    ({ action_date, position, remark, tire_id }) => ({
      action_date,
      position,
      remark,
      tire_id,
    })
  );

  const formatData = {
    ...rest,
    driver:
      related_user_list?.map((driver) => ({
        user_id: driver.user_unique_id || "",
        email: driver.email || "",
        first_name: driver.first_name || "",
        last_name: driver.last_name || "",
        img_url: driver.img_url || [],
      })) || [],
    created_by: {
      user_id: created_by?.user_unique_id || "",
      email: created_by?.email || "",
      first_name: created_by?.first_name || "",
      last_name: created_by?.last_name || "",
      img_url: created_by?.img_url || [],
    },
    image: formatImageUrlList,
    status: rest.status || VehicleStatus.Active,
    vehicle_id: trailer_id,
    type: rest.type || VehicleType.Head,
    tire: formatTire,
  };

  return formatData;
};

export const vehicleQueryFormatter = (data: any) => {
  const formatData = {
    unique_id: data.unique_id,
    register_id: data.register_id,
    type: data.type,
    driver: data.driver,
    related_user_list: data.driver?.map((driver: any) => ({
      user_unique_id: driver?.unique_id || "",
      email: driver?.email || "",
      first_name: driver?.first_name || "",
      last_name: driver?.last_name || "",
      img_url: driver?.img_url || [],
    })),
    current_kilometer: parseFloat(data.current_kilometer),
    due_distance_kilometer: parseFloat(data.due_distance_kilometer),
    brand: data.brand,
    model: data.model,
    detail: data.detail,
    act_id: data.act_id,
    act_end_date: data.act_end_date ? dayjs(data.act_end_date) : undefined,
    status: data.status,
    status_note: data.status_note,
    created_date: data.created_date,
    trailer: data.trailer,
    trailer_unique_id: data.trailer?.unique_id,
    trailer_id: data.trailer?.id,
    main_image_index: data.image?.findIndex((img: any) => img.is_primary) || 0,
    image: data.image,
    insurance_product: data.insurance_product
      ? {
          ...data.insurance_product,
          protection_value: data.insurance_product.protection_value
            ? parseFloat(data.insurance_product.protection_value)
            : undefined,
          start_date: data.insurance_product.start_date
            ? dayjs(data.insurance_product.start_date)
            : undefined,
          end_date: data.insurance_product.end_date
            ? dayjs(data.insurance_product.end_date)
            : undefined,
        }
      : undefined,
    insurance_vehicle: data.insurance_vehicle
      ? {
          ...data.insurance_vehicle,
          protection_value: data.insurance_vehicle.protection_value
            ? parseFloat(data.insurance_vehicle.protection_value)
            : undefined,
          start_date: data.insurance_vehicle.start_date
            ? dayjs(data.insurance_vehicle.start_date)
            : undefined,
          end_date: data.insurance_vehicle.end_date
            ? dayjs(data.insurance_vehicle.end_date)
            : undefined,
        }
      : undefined,
    main_tire:
      data.main_tire?.sort((a: IVehicleTire, b: IVehicleTire) => {
        const parsePosition = (pos: string) =>
          pos === "spare" ? Infinity : parseInt(pos, 10);
        return (
          parsePosition(a.position || "") - parsePosition(b.position || "")
        );
      }) || [],
    spare_tire: data.spare_tire,
  } as IVehicle;

  return formatData;
};

export const vehicleUpdateFormatter = async (data: IVehicle) => {
  const {
    created_by,
    image,
    main_image_index,
    trailer,
    trailer_unique_id,
    trailer_id,
    created_date,
    last_updated_date,
    last_updated_by,
    related_user_list,
    type,
    driver,
    main_tire,
    spare_tire,
    uninstall_tire,
    ...rest
  } = data;

  const imageUrlList = image as any[];

  let uploded_img_list: string[] = [];

  if (imageUrlList && imageUrlList.length > 0) {
    for (const file of imageUrlList) {
      if (typeof file === "object" && !file?.file_path) {
        const { Location } = await uploadFileToS3(
          file,
          "vehicle",
          data.id?.toString() || ""
        );
        uploded_img_list.push(Location);
      } else {
        uploded_img_list.push(file);
      }
    }
  } else {
    uploded_img_list = imageUrlList;
  }

  const formatImageUrlList = uploded_img_list.map((img: any, index) => {
    return {
      file_path: typeof img !== "object" ? img : img.file_path,
      is_primary: main_image_index === index,
    };
  }) as IVehicleImage[];

  const formatTire = [
    ...(main_tire || []),
    ...(spare_tire || []),
    ...(uninstall_tire || []),
  ]
    ?.filter((tire) => tire.action_type)
    .map(({ action_date, position, remark, action_type, tire_id = 0 }) => ({
      action_date,
      action_type,
      position,
      remark,
      tire_id,
    }));

  const changedTire = [
    ...(main_tire || []),
    ...(spare_tire || []),
    ...(uninstall_tire || []),
  ]
    .filter((tire) => tire.is_change_position)
    .map(({ position, previous_position, remark, tire_id = 0 }) => ({
      current_position: position,
      change_date: dayjs(),
      previous_position,
      remark,
      tire_id,
    }));

  const formatData = {
    ...rest,
    driver:
      related_user_list?.map((driver) => ({
        user_id: driver.user_unique_id || "",
        email: driver.email || "",
        first_name: driver.first_name || "",
        last_name: driver.last_name || "",
        img_url: driver.img_url || [],
      })) || [],
    image: formatImageUrlList,
    status: rest.status || VehicleStatus.Active,
    vehicle_id: trailer_id,
    tire: formatTire,
    tire_position: changedTire,
  };

  return formatData;
};

export const vehicleFormFormatter = (errors: FieldErrors<IVehicle>) => {
  const errors_message: string[] = [];
  Object.keys(errors).forEach(function (key) {
    const errorKey = key as keyof typeof errors;
    const message = errors[errorKey]?.message?.toString();
    if (
      message &&
      message !== "เลขกิโลเมตรปัจจุบันไม่สามารถเกิน 6 หลักได้" &&
      message !== "เลขกิโลเมตรเช็คระยะไม่สามารถเกิน 6 หลักได้" &&
      !message.includes("ไม่เกิน") &&
      message !== "กรุณาระบุหมายเหตุสถานะ"
    ) {
      errors_message.push(message);
    }
  });
  return errors_message;
};
