import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { IRole } from "types/Auth/role";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { Box, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

const RolePermissions = () => {
  const { control } = useFormContext<IRole>();

  const watchName = useWatch({
    control,
    name: "name",
  });

  const viewRolePermissionHandler = () => {
    const filePath = `/static/role-permission-info/${watchName}.pdf`;
    window.open(filePath, "_blank");
  };

  const { t } = useTranslation();
  return (
    <CustomizedBox>
      <Typography fontSize={16} fontWeight={600} mb={1}>
        {t("user.rbac.permission")}
      </Typography>
      <Box
        onClick={viewRolePermissionHandler}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          color: "primary.main",
          cursor: "pointer",
        }}
      >
        <LaunchIcon
          sx={{
            fontSize: 18,
          }}
        />
        <Typography
          color="primary.main"
          ml={1}
        >{`ดูรายละเอียดสิทธิ์ ${watchName}`}</Typography>
      </Box>
    </CustomizedBox>
  );
};

export default RolePermissions;
