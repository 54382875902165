import { Box, Grid, Typography } from "@mui/material";

import { IMenuOption } from "types/global";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";

import { useDisable } from "hooks/use-disable";
import { useConfirmation } from "hooks/use-confirmation";

import CustomizedChips from "components/Custom/CustomizedChips";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import DeleteConfirmation from "components/UI/Modal/DeleteConfirmation";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";

import { formatDate } from "utils/Date";

type Props = {
  data?: any;
  editClickHandler: () => void;
  deleteHandler: () => Promise<void>;
};

const TireHeader = ({ data, editClickHandler, deleteHandler }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { permissions },
  } = useStateContext();

  const [disable] = useDisable();

  const selectModifyOptions: IMenuOption[] = [
    permissions?.tire?.update && {
      value: "แก้ไข",
      disabled: Boolean(!disable && id) || false,
    },
    permissions?.tire?.delete && {
      value: "ลบ",
      disabled: data?.status === "active",
    },
  ].filter(Boolean) as IMenuOption[];

  const {
    confirmation: deleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmationHandler,
    openConfirmationHandler: openDeleteConfirmationHandler,
    submitConfirmationHandler: submitDeleteConfirmationHandler,
  } = useConfirmation(deleteHandler);

  const chipData = [
    {
      title: t("date.created_date"),
      value: data?.created_date ? formatDate(data?.created_date) : "",
    },
    {
      title: t("date.new_last_updated_date"),
      value: data?.last_updated_date
        ? formatDate(data?.last_updated_date)
        : "-",
    },
  ];

  return (
    <>
      <Box maxWidth={1040}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                mt: !id ? 2 : undefined,
              }}
            >
              <Typography variant="h5">{t("logistic.tire.index")}</Typography>
              {Boolean(id) && <CustomizedStatus status={data?.status} />}
            </Box>
          </Grid>
          {id && (permissions?.tire?.update || permissions?.tire?.delete) && (
            <Grid item sm={12} md={6}>
              <Grid container justifyContent="end">
                <Grid item sm={5.5} md={4}>
                  {selectModifyOptions?.length > 0 && (
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={"ตัวเลือก"}
                      options={selectModifyOptions}
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        switch (value.innerText) {
                          case "แก้ไข":
                            editClickHandler();
                            break;
                          case "ลบ":
                            openDeleteConfirmationHandler();
                            break;
                          default:
                            break;
                        }
                      }}
                      disabled={!Boolean(id)}
                    ></CustomizedMenuOptions>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {id && disable && (
          <Grid container spacing={1} mt={1}>
            {chipData.map((chip) => (
              <Grid item key={chip.title}>
                <CustomizedChips
                  variant="filled"
                  noneColor
                  label={
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">{chip.title}</Typography>
                      <Typography>{chip.value}</Typography>
                    </Box>
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <DeleteConfirmation
        noDivider
        title={t("logistic.tire.sentence.delete_header")}
        message={
          <Box>
            <Typography>
              {t("logistic.tire.sentence.delete_message")}
            </Typography>
            <Typography fontWeight="bold">{data?.serial}</Typography>
          </Box>
        }
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmationHandler}
        action={submitDeleteConfirmationHandler}
      />
    </>
  );
};

export default TireHeader;
