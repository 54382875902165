import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { useTranslation } from "react-i18next";
import { IAccessPermission, IPermissionContext } from "types/Auth/role";

export const usePurchaseOrderOption = (
  status?: string | null,
  disabled?: boolean,
  purchaseOrderPermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        purchaseOrderPermissions?.update && {
          value: "อ้างอิงเอกสารภายนอก",
          disabled: false,
        },
        purchaseOrderPermissions?.update && {
          value: "แก้ไข",
          disabled: !disabled || false,
        },
        purchaseOrderPermissions?.create && {
          value: "คัดลอก",
          disabled: false,
        },
        purchaseOrderPermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
        purchaseOrderPermissions?.update && {
          value: "ยกเลิกจำนวนคงค้าง",
          disabled: false,
        },
      ] as IMenuOption[],
    [disabled, purchaseOrderPermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions(
          [defaultOptions[2], defaultOptions[3]].filter(Boolean)
        ); // ยกเลิก, คัดลอก
        break;
      case "wait_ordered":
        setSelectedModifyOptions(
          [
            defaultOptions[0],
            defaultOptions[1],
            defaultOptions[2],
            defaultOptions[3],
          ].filter(Boolean)
        ); // คัดลอก
        break;
      case "partially_imported":
        setSelectedModifyOptions(
          [defaultOptions[0], defaultOptions[4], defaultOptions[2]].filter(
            Boolean
          )
        ); // คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[2]].filter(Boolean)); // คัดลอก
        break;
      case "fully_ordered":
      case "fully_imported":
        setSelectedModifyOptions(
          [defaultOptions[0], defaultOptions[2]].filter(Boolean)
        ); // อ้างอิงเอกสารภายนอก , คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};

export const usePurchaseOrderCreate = (
  status?: string | null,
  isMaxQty?: boolean,
  permissions?: IPermissionContext | null
) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        permissions?.goods_receive?.create && {
          value: t("inventory.goods_receive.index"),
          disabled: isMaxQty,
        },
        permissions?.purchase_return?.create && {
          value: t("purchase.return.index"),
          disabled: false,
        },
      ] as IMenuOption[],
    [isMaxQty, permissions, t]
  );

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "wait_ordered":
      case "fully_ordered":
      case "partially_imported":
      case "fully_imported":
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
    }
  }, [status]);

  useEffect(() => {
    switch (status) {
      case "fully_ordered":
        setOptions([defaultOptions[0], defaultOptions[1]].filter(Boolean));
        break;
      case "partially_imported":
        setOptions([defaultOptions[0], defaultOptions[1]].filter(Boolean));
        break;
      case "fully_imported":
        setOptions([defaultOptions[1]].filter(Boolean));
        break;
      default:
        setOptions(defaultOptions.filter(Boolean));
    }
  }, [defaultOptions, status]);

  return {
    selectCreateOptions: options,
    showCreateOptions: showOptions,
  };
};
