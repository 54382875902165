import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

// import { IApprovalTemplate } from "types/global";
import { IPurchaseReturn } from "types/Purchase/purchaseReturn";
import {
  PurchaseReturnFindUniqueQuery,
  PurchaseReturnCreateInput,
  PurchaseReturnUpdateInput,
  usePurchaseReturnCreateMutation,
  usePurchaseReturnFindUniqueQuery,
  usePurchaseReturnUpdateMutation,
  usePurchaseOrderFindUniqueQuery,
  PurchaseOrderFindUniqueQuery,
} from "generated/purchase";
import { ActivityType, ActivityLogDocumentType } from "generated/general";

import { useDisable } from "hooks/use-disable";
import { useActivityLog } from "hooks/use-activity-log";

import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import { Stack } from "@mui/material";
import LoadingUI from "components/UI/LoadingUI";
import PurchaseFooter from "components/Form/Purchase/Footer";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import SupplierInfo from "components/Form/Purchase/SupplierInfo";
import CustomizedButton from "components/Custom/CustomizedButton";
import ReasonReturn from "components/Form/Purchase/Return/ReasonReturnComponent";
import PurchaseReturnHeader from "components/Form/Purchase/Return/Header";
import AddressInfo from "components/Form/Purchase/SupplierInfo/AddressInfo";
import PurchaseItemList from "components/Table/Purchase/ItemList/PurchaseItemList";

import {
  purchaseReturnQueryFormatter,
  purchaseReturnCreatePayloadFormatter,
  purchaseReturnUpdatePayloadFormatter,
} from "utils/Formatter/Purchase/PurchaseReturn";
import { errorMessageFormatter } from "utils/Global";
import { purchaseOrderQueryFormatter } from "utils/Formatter/Purchase/PurchaseOrder";

import { IPurchaseItemList } from "types/Purchase";
import { IPurchaseOrder } from "types/Purchase/purchaseOrder";
import { usePurchaseError } from "hooks/Purchase/use-purchase-error";
// import { errorMessageFormatter } from "utils/Global";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const documentType = "purchase_return";
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const { createActivityLog } = useActivityLog();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [referenceItemList, setReferenceItemList] = useState<
    IPurchaseItemList[]
  >([]);
  const {
    state: { permissions },
  } = useStateContext();

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const {
    reset,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IPurchaseReturn>();

  const status = watch("aggrid_status");

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseReturnFindUniqueQuery<PurchaseReturnFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { data: poData } =
    usePurchaseOrderFindUniqueQuery<PurchaseOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: data?.purchaseReturnFindUnique?.reference_document_list?.[0]
            ?.document_id,
        },
      },
      {
        enabled:
          !!data?.purchaseReturnFindUnique?.reference_document_list?.[0]
            ?.document_id,
        cacheTime: 0,
      }
    );

  const formatPO = purchaseOrderQueryFormatter(
    poData?.PurchaseOrderFindUnique as IPurchaseOrder
  );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { purchaseReturnFindUnique } = data;

      const getPurchaseRequestData = async () => {
        const formattedPurchaseRequest = await purchaseReturnQueryFormatter(
          purchaseReturnFindUnique
        );
        reset(formattedPurchaseRequest);
        setIsLoadingData(false);
      };
      getPurchaseRequestData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id && (status !== "draft" || !permissions?.purchase_return?.update)) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status, permissions]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, created_by, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        issue_date: dayjs(),
        delivery_date: dayjs(),
      });
      setReferenceItemList(otherState.item_list);
    }
  }, [reset, state]);

  useEffect(() => {
    if (formatPO?.item_list && formatPO.item_list.length > 0) {
      const formmatItem = formatPO.item_list.map((item) => ({
        ...item,
        po_qty: item.qty,
      }));
      setReferenceItemList(formmatItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatPO?.unique_id]);

  const { mutateAsync: create, isLoading: isCreating } =
    usePurchaseReturnCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    usePurchaseReturnUpdateMutation<Error>(graphQLClient);

  const isNotValidQty = (item_list: IPurchaseItemList[]) => {
    const isNotValid = item_list.some((item) => item.qty > (item.po_qty || 0));
    return isNotValid;
  };

  const draftHandler = async (data: IPurchaseReturn) => {
    if (isNotValidQty(data.item_list)) return;

    if (!id) {
      try {
        const formatData = purchaseReturnCreatePayloadFormatter(data, "draft");
        const { PurchaseReturnCreate } = await create({
          data: formatData as PurchaseReturnCreateInput,
        });
        enqueueSnackbar(t("snack_bar.purchase_return.create.success"), {
          variant: "success",
        });
        navigate(`/purchase/return/${PurchaseReturnCreate?.id}`);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseReturn,
          reference_id: PurchaseReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(
          formatError || t("snack_bar.purchase_return.create.fail"),
          {
            variant: "error",
          }
        );
      }
    } else {
      try {
        const formatData = await purchaseReturnUpdatePayloadFormatter(
          data,
          "draft"
        );
        const { PurchaseReturnUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          data: formatData as PurchaseReturnUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.PurchaseReturn,
          reference_id: PurchaseReturnUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitReturnHandler = async (data: IPurchaseReturn) => {
    if (isNotValidQty(data.item_list)) return;
    if (!id) {
      //guarantee no approval
      try {
        const formatData = purchaseReturnCreatePayloadFormatter(
          data,
          "wait_returned"
        );

        const { PurchaseReturnCreate } = await create({
          data: formatData as PurchaseReturnCreateInput,
        });
        enqueueSnackbar(t("snack_bar.purchase_return.create.success"), {
          variant: "success",
        });
        navigate(`/purchase/return/${PurchaseReturnCreate?.id}`);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseReturn,
          reference_id: PurchaseReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_returned",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(
          formatError || t("snack_bar.purchase_return.create.fail"),
          {
            variant: "error",
          }
        );
      }
    } else {
      try {
        let changingStatus = "wait_returned";
        const formatData = await purchaseReturnUpdatePayloadFormatter(
          data,
          changingStatus
        );
        const { PurchaseReturnUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          data: formatData as PurchaseReturnUpdateInput,
        });
        enqueueSnackbar(t("snack_bar.purchase_return.create.success"), {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseReturn,
          reference_id: PurchaseReturnUpdate?.id,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: "wait_returned",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(
          formatError || t("snack_bar.purchase_return.create.fail"),
          {
            variant: "error",
          }
        );
      }
    }
  };

  const editHandler = async (data: IPurchaseReturn, status?: string) => {
    if (isNotValidQty(data.item_list))
      return enqueueSnackbar(t(`purchase.return.exceed_po_qty`), {
        variant: "error",
      });

    try {
      const formatData = await purchaseReturnUpdatePayloadFormatter(
        data,
        status || data?.main_status || ""
      );
      const { PurchaseReturnUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: formatData as PurchaseReturnUpdateInput,
      });

      setDisabled(true);

      await refetch();

      if (status === "finished")
        enqueueSnackbar(t("snack_bar.purchase_return.finished.success"), {
          variant: "success",
        });
      else
        enqueueSnackbar(t("snack_bar.purchase_return.draft.success"), {
          variant: "success",
        });

      if (status) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseReturn,
          reference_id: PurchaseReturnUpdate?.id,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: status,
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.PurchaseReturn,
          reference_id: PurchaseReturnUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      }
    } catch (err) {
      console.error(err);

      if (status === "finished")
        enqueueSnackbar(t("snack_bar.purchase_return.finished.fail"), {
          variant: "error",
        });
      else
        enqueueSnackbar(t("snack_bar.purchase_return.draft.fail"), {
          variant: "error",
        });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const { PurchaseReturnUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: {
          flag_status: flag_status,
        } as PurchaseReturnUpdateInput,
      });

      enqueueSnackbar(t("snack_bar.purchase_return.cancel.success"), {
        variant: "success",
      });

      await refetch();

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.PurchaseReturn,
        reference_id: PurchaseReturnUpdate?.id,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("snack_bar.purchase_return.cancel.fail"), {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        if (!permissions?.purchase_return?.update) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("purchase.return.name")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(waitReturnHandler)}
            />
          </>
        );
      case "wait_returned":
        if (!permissions?.purchase_return?.update) {
          return <></>;
        }
        if (!disabled)
          return (
            <>
              <CustomizedButton
                title={t("button.save_draft")}
                variant="outlined"
                onClick={() => handleSubmit((data) => editHandler(data))()}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("status.finished")}
                variant="contained"
                onClick={() =>
                  handleSubmit((data) => editHandler(data, "finished"))()
                }
                disabled={isUpdating}
              />
            </>
          );
        if (disabled)
          return (
            <>
              <CustomizedButton
                title={t("status.finished")}
                variant="contained"
                onClick={() =>
                  handleSubmit((data) => editHandler(data, "finished"))()
                }
                disabled={isUpdating}
              />
            </>
          );
        return;
      case "finished":
      case "cancelled":
        break;
      default:
        if (status && !permissions?.purchase_return?.update) {
          return <></>;
        }
        if (!status && !permissions?.purchase_return?.create) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("purchase.return.name")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(waitReturnHandler)}
            />
          </>
        );
    }
  };

  usePurchaseError(errors);

  if (id && (isLoadingData || isLoading || isCreating || isUpdating)) {
    return <LoadingUI />;
  }

  return (
    <>
      <PurchaseReturnHeader
        editClickHandler={editClickHandler}
        cancelHandler={cancelHandler}
      />
      <ReasonReturn />
      <SupplierInfo />
      <AddressInfo documentType={documentType} />
      <PurchaseItemList
        documentType={documentType}
        referenceItemList={referenceItemList}
      />
      <PurchaseFooter documentType={documentType} />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
