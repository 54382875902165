import { yupResolver } from "@hookform/resolvers/yup";
import { maintenanceValidation } from "components/Form/Logistic/Maintenance/schema";
import { ActivityLog } from "components/UI/ActivityLog";
import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import { useActivityLog } from "hooks/use-activity-log";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams, Navigate } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu, ITab } from "types/global";
import CustomizedTab from "components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import LoadingUI from "components/UI/LoadingUI";
import { IMaintenance } from "types/Logistic/maintenance";
import { maintenanceSchema } from "components/Form/Logistic/Maintenance/schema";
import { useMaintenanceFindUniqueQuery } from "generated/sales";
import { maintenanceDataFormatter } from "utils/Formatter/Logistic/Maintenance";

const MaintenanceContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const {
    state: { permissions },
  } = useStateContext();

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess, refetch } = useMaintenanceFindUniqueQuery(
    graphqlClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const tabs: ITab[] = [
    {
      label: t("document_info"),
      path: `${pathname}`,
    },
  ];

  const methods = useForm<IMaintenance>({
    defaultValues: { ...maintenanceSchema },
    resolver: yupResolver<any>(maintenanceValidation),
  });

  const {
    watch,
    formState: { errors },
    reset,
  } = methods;

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  useEffect(() => {
    const errorHandler = (error: any) => {
      if (Object.keys(error).length <= 0) return;

      var maItemListQtyError: string = "";
      var maItemListPricePerUnitError: string = "";

      if (error.ma_item_list?.length > 0) {
        error?.ma_item_list?.forEach(
          (item: {
            price_per_unit: { message: string };
            qty: { message: string };
          }) => {
            if (item.qty?.message) {
              maItemListQtyError = item.qty?.message;
            }
            if (item.price_per_unit?.message) {
              maItemListPricePerUnitError = item.price_per_unit?.message;
            }
          }
        );
      } else if (error?.ma_item_list?.message) {
        enqueueSnackbar(error?.ma_item_list?.message, {
          variant: "error",
        });
      }

      if (maItemListQtyError.length > 0) {
        if (maItemListQtyError === "กรุณาระบุจำนวนมากกว่า 0") {
          enqueueSnackbar("กรุณาระบุจำนวนมากกว่า 0", {
            variant: "error",
          });
        }
        if (maItemListQtyError === "กรุณาระบุจำนวนสินค้า") {
          enqueueSnackbar("กรุณาระบุจำนวนสินค้า", {
            variant: "error",
          });
        }
      }

      if (maItemListPricePerUnitError.length > 0) {
        if (
          maItemListPricePerUnitError === "กรุณาระบุราคาต่อหน่วยที่มากกว่า 0"
        ) {
          enqueueSnackbar("กรุณาระบุราคาต่อหน่วยที่มากกว่า 0", {
            variant: "error",
          });
        }
        if (maItemListPricePerUnitError === "กรุณาระบุราคาต่อหน่วย") {
          enqueueSnackbar("กรุณาระบุราคาต่อหน่วย", {
            variant: "error",
          });
        }
      }

      delete error?.ma_item_list;

      if (Object.keys(error).length > 0) {
        enqueueSnackbar("กรุณาระบุข้อมูลที่จำเป็น", {
          variant: "error",
        });
      }
    };
    errorHandler(errors);
  }, [enqueueSnackbar, errors]);

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const renderTab = (tab: string | null) => {
    return <DocumentInfoTab data={data} refetch={refetch} />;
  };

  useEffect(() => {
    if (isSuccess) {
      const { MaintenanceFindUnique } = data;
      const formatData = maintenanceDataFormatter(
        MaintenanceFindUnique
      ) as IMaintenance;
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.maintenance.index"),
      to: "/logistic/maintenance",
    },
    {
      name: id
        ? data?.MaintenanceFindUnique?.unique_id || "-"
        : t("button.create") + t("logistic.maintenance.index"),
    },
  ];

  if (permissions && !permissions.maintenance?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (id && isLoading) {
    return <LoadingUI />;
  }

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        onActivityLogOpen={onActivityLogOpen}
      />
      {status === "finished" && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={"maintenance"}
        />
      )}
    </FormProvider>
  );
};

export default MaintenanceContainer;
