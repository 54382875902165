import { useEffect } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";

import CustomizedTab from "components/Custom/CustomizedTab";
import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import { IBreadcrumbsAndMenu, ITab } from "types/global";

import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useActivityLog } from "hooks/use-activity-log";
import {
  ActivityLogDocumentType,
  QuotationFindUniqueQuery,
  useQuotationFindUniqueQuery,
} from "generated/sales";
import { ActivityLog } from "components/UI/ActivityLog";
import { useStateContext } from "contexts/auth-context";

import { IQuotation } from "types/Sales/quotation";
import {
  quotationSchema,
  quotationValidation,
} from "components/Form/Sales/Quotation/schema";
import LoadingUI from "components/UI/LoadingUI";

import DocumentInfoTab from "./DocumentInfoTab";
import DeliveryTripTab from "./DeliveryTripTab";

const QuotationContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IQuotation>({
    defaultValues: { ...quotationSchema },
    resolver: yupResolver<any>(quotationValidation),
  });

  const {
    formState: { errors },
  } = methods;

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const { item_list, ...otherErrors } = errors;

      if (Object.keys(otherErrors).length > 0) {
        enqueueSnackbar("กรุณาระบุข้อมูลที่จำเป็น", {
          variant: "error",
        });
      }

      if (item_list) {
        if (Array.isArray(item_list)) {
          item_list.forEach((item) => {
            if (item) {
              enqueueSnackbar("กรุณาระบุข้อมูลสินค้าให้ถูกต้อง", {
                variant: "error",
              });
            }
          });
        } else {
          if (item_list.root) {
            enqueueSnackbar(item_list.root.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(item_list.message, {
              variant: "error",
            });
          }
        }
      }
    }
  }, [enqueueSnackbar, errors]);

  const { data, isLoading } =
    useQuotationFindUniqueQuery<QuotationFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { control } = methods;

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    status === "finished" && {
      label: t("logistic.delivery_trip.index"),
      path: `${pathname}?tab=delivery-trip`,
    },
  ].filter(Boolean) as ITab[];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "delivery-trip":
        return <DeliveryTripTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
    },
    {
      name: id
        ? data?.QuotationFindUnique.unique_id || "-"
        : t("button.create") + t("sales.quotation.index"),
    },
  ];

  if (permissions && !permissions.quotation?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (id && isLoading) {
    return <LoadingUI />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <BreadcrumbsLayout
          breadcrumbs={breadcrumbs}
          onActivityLogOpen={onActivityLogOpen}
        />
        {(tab || status) && (
          <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
        )}
        {renderTab(tab)}
        {id && (
          <ActivityLog
            open={openActivityLog}
            onClose={onActivityLogClose}
            documentId={id!}
            documentType={ActivityLogDocumentType.Quotation}
          />
        )}
      </FormProvider>
    </>
  );
};

export default QuotationContainer;
