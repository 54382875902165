import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { GraphQLClient } from "graphql-request";
import {
  ActivityLogDocumentType,
  ActivityType,
  GeneralModelType,
  GeneralUniqueIdGenerateQuery,
  UserQuery,
  useGeneralUniqueIdGenerateQuery,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserQuery,
  useUserSelfUpdateMutation,
  useUserUpdateMutation,
} from "generated/general";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, IconButton, Typography } from "@mui/material";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import {
  userSchema,
  userValidation,
  userValidationEditSelf,
} from "components/Form/User/schema";
import LoadingUI from "components/UI/LoadingUI";
import UserAccountForm from "components/Form/User";
import UserPermission from "components/Form/User/Permission";
import UserHeader from "components/Form/User/Header";
import HeaderLayout from "components/UI/HeaderLayout";
import ButtonLayout from "components/UI/ButtonLayout";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import CustomizedButton from "components/Custom/CustomizedButton";
import DeleteConfirmation from "components/UI/Modal/DeleteConfirmation";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import { useModal } from "hooks/use-modal";
import { useDisable } from "hooks/use-disable";

import { errorMessageFormatter } from "utils/Global";
import {
  userCreateFormatter,
  userQueryFormatter,
  userUpdateFormatter,
} from "utils/Formatter/User";

import { IUser } from "types/Auth/user";
import { useStateContext } from "contexts/auth-context";
import { ActivityLog } from "components/UI/ActivityLog";
import { useActivityLog } from "hooks/use-activity-log";

const UserAccountContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const [prevStatus, setPrevStatus] = useState<number | null>();

  const {
    createActivityLog,
    openActivityLog,
    onActivityLogOpen,
    onActivityLogClose,
  } = useActivityLog();

  const navigate = useNavigate();

  const setting = pathname.split("/")[2] === "setting";

  const {
    state: { authUser, permissions },
    dispatch,
  } = useStateContext();

  const methods = useForm<IUser>({
    defaultValues: userSchema,
    resolver: yupResolver<any>(
      id && setting ? userValidationEditSelf : userValidation
    ),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = methods;

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess } = useUserQuery<UserQuery>(
    graphqlClientGeneral,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphqlClientGeneral,
      {
        modelType: GeneralModelType.User,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(
    async (button: boolean) => {
      const { data } = await refetchUniqueId();
      if (!id || button) setValue("unique_id", data?.UniqueIdGenerate ?? "");
    },
    [id, refetchUniqueId, setValue]
  );

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
      to: "/user/account",
    },
    {
      name:
        id && data && data.UserFindUnique?.unique_id
          ? `${data.UserFindUnique?.first_name} ${data.UserFindUnique?.last_name}`
          : t("user.account.add_new_account"),
    },
  ];

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    generateUniqueId(false);
  }, [generateUniqueId]);

  useEffect(() => {
    if (isSuccess) {
      const { UserFindUnique } = data;
      setPrevStatus(UserFindUnique?.status);
      const userType = userQueryFormatter(UserFindUnique as IUser);
      reset(userType);
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [data, isSuccess, reset, setDisabled, setting]);

  const { mutateAsync: create, isLoading: isCreating } =
    useUserCreateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: update, isLoading: isUpdating } =
    useUserUpdateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: updateSelf, isLoading: isSelfUpdating } =
    useUserSelfUpdateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: deleteUser, isLoading: isDeleting } =
    useUserDeleteMutation<Error>(graphqlClientGeneral);

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: false,
    },
    ...(!setting
      ? [
          {
            value: "ลบ",
            disabled: false,
          },
        ]
      : []),
  ];

  const userCreateHandler = async (data: IUser) => {
    try {
      await generateUniqueId(false);
      const formatData = await userCreateFormatter(data, id);
      const { UserCreate } = await create({
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.User,
        reference_id: UserCreate?.id || 0,
        activity_detail: {},
      });
      if (UserCreate?.status === 0) {
        await createActivityLog({
          activity_type: ActivityType.InActive,
          document_type: ActivityLogDocumentType.User,
          reference_id: UserCreate?.id || 0,
          activity_detail: {},
        });
      }
      setPrevStatus(UserCreate?.status);
      enqueueSnackbar("สร้างผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate(`/user/account/${UserCreate?.id}`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "สร้างผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const userUpdateHandler = async (data: IUser) => {
    try {
      const { id, old_password, ...otherData } = data;
      const { ...formatData } = await userUpdateFormatter(otherData);
      let resData = null;
      if (setting) {
        const { UserSelfUpdate } = await updateSelf({
          validatePassword:
            old_password && old_password !== "" ? old_password : undefined,
          data: formatData,
        });
        resData = UserSelfUpdate;
      } else {
        const { UserUpdate } = await update({
          uniqueInput: {
            id: id,
          },
          data: formatData,
        });
        resData = UserUpdate;
      }
      if (isDirty) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.User,
          reference_id: resData?.id || 0,
          activity_detail: {},
        });
        if (
          (old_password &&
            old_password !== "" &&
            otherData.confirm_new_password &&
            otherData.confirm_new_password !== "") ||
          (otherData.new_password &&
            otherData.new_password !== "" &&
            otherData.confirm_new_password &&
            otherData.confirm_new_password !== "")
        ) {
          await createActivityLog({
            activity_type: ActivityType.ChangePassword,
            document_type: ActivityLogDocumentType.User,
            reference_id: resData?.id || 0,
            activity_detail: {},
          });
        }
        if (prevStatus === 1) {
          if (resData?.status === 0) {
            await createActivityLog({
              activity_type: ActivityType.InActive,
              document_type: ActivityLogDocumentType.User,
              reference_id: resData?.id || 0,
              activity_detail: {},
            });
          }
        } else {
          if (resData?.status === 1) {
            await createActivityLog({
              activity_type: ActivityType.Active,
              document_type: ActivityLogDocumentType.User,
              reference_id: resData?.id || 0,
              activity_detail: {},
            });
          }
        }
      }
      enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      if (authUser?.id === resData?.id) {
        dispatch({ type: "SET_USER", payload: resData as IUser });
      }
      const updatedData = userQueryFormatter(resData as IUser);
      setPrevStatus(updatedData.status);
      reset(updatedData);
      setDisabled(true);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "แก้ไขผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const userDeleteHandler = async () => {
    try {
      await deleteUser({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar("ลบผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate("/user/account");
    } catch (err) {
      enqueueSnackbar("ลบผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(userDeleteHandler);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  if (!setting && permissions && !permissions.user_account?.view) {
    return <Navigate replace to="/unauthorized" />;
  }

  if (
    id &&
    (isLoading || isCreating || isUpdating || isDeleting || isSelfUpdating)
  ) {
    return <LoadingUI />;
  }

  return (
    <Box maxWidth={1040}>
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={ActivityLogDocumentType.User}
        />
      )}
      <Box display="flex">
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <Box marginLeft="auto">
            <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
              <IconButton
                onClick={onActivityLogOpen}
                sx={{
                  color: "primary.main",
                  padding: 0,
                  marginLeft: "4px",
                }}
              >
                <RestoreOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Box>
        )}
      </Box>
      <HeaderLayout marginBottom={0}>
        <Typography variant="h5">
          {setting ? t("user.setting.index") : t("user.account.index")}
        </Typography>
        <CustomizedMenuOptions
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              case "ลบ":
                openConfirmationHandler();
                break;

              default:
                break;
            }
          }}
          disabled={!id}
        />
      </HeaderLayout>
      <FormProvider {...methods}>
        <UserHeader />
        <UserAccountForm />
        <UserPermission />
        {!disabled && (
          <BottomNavbar>
            <ButtonLayout>
              {!!id && (
                <CustomizedButton
                  variant="outlined"
                  title={t("button.cancel")}
                  onClick={editCancelHandler}
                  disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(
                  id ? userUpdateHandler : userCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </ButtonLayout>
          </BottomNavbar>
        )}
      </FormProvider>
      <DeleteConfirmation
        title={`${t("user.title.delete_user")}`}
        message={
          <Box>
            <Typography>ยืนยันการ{t("user.title.delete_user")}</Typography>
            <Typography fontWeight="bold">
              {data?.UserFindUnique?.first_name +
                " " +
                data?.UserFindUnique?.last_name}
            </Typography>
          </Box>
        }
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </Box>
  );
};

export default UserAccountContainer;
