import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useStateContext } from "contexts/auth-context";

import GoodsReceiveTab from "./GoodsReceiveTab";
import PurchaseReturnTab from "./PurchaseReturnTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { ActivityLog } from "components/UI/ActivityLog";
import CustomizedTab from "components/Custom/CustomizedTab";
import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import {
  purchaseOrderSchema,
  purchaseOrderValidation,
} from "components/Form/Purchase/Order/schema";

import { useActivityLog } from "hooks/use-activity-log";
import {
  ActivityLogDocumentType,
  PurchaseOrderFindUniqueQuery,
  usePurchaseOrderFindUniqueQuery,
} from "generated/purchase";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

const PurchaseOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const graphQLClient = createGraphQLClientWithMiddleware("purchase");
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IPurchaseOrder>({
    defaultValues: { ...purchaseOrderSchema },
    resolver: yupResolver<any>(purchaseOrderValidation),
  });

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  const { data, isLoading } =
    usePurchaseOrderFindUniqueQuery<PurchaseOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { control } = methods;

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const showTabStatus = [
    "wait_ordered",
    "fully_ordered",
    "partially_imported",
    "fully_imported",
  ];

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    showTabStatus.includes(data?.PurchaseOrderFindUnique.aggrid_status || "")
      ? {
          label: "ใบ" + t("inventory.goods_receive.index"),
          path: `${pathname}?tab=goods_receive`,
        }
      : null,
    showTabStatus.includes(data?.PurchaseOrderFindUnique.aggrid_status || "")
      ? {
          label: t("purchase.return.index"),
          path: `${pathname}?tab=purchase_return`,
        }
      : null,
  ].filter(Boolean) as ITab[];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "goods_receive":
        return (
          <GoodsReceiveTab
            referenceUniqueId={data?.PurchaseOrderFindUnique.unique_id}
            isLoading={isLoading}
          />
        );
      case "purchase_return":
        return (
          <PurchaseReturnTab
            referenceUniqueId={data?.PurchaseOrderFindUnique.unique_id}
            isLoading={isLoading}
          />
        );
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
      to: "/purchase/order",
    },
    {
      name: id
        ? data?.PurchaseOrderFindUnique.unique_id || "-"
        : t("button.create") + t("purchase.order.index"),
    },
  ];

  if (permissions && !permissions.purchase_order?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        onActivityLogOpen={onActivityLogOpen}
      />
      {(tab || data?.PurchaseOrderFindUnique.aggrid_status) && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={ActivityLogDocumentType.PurchaseOrder}
        />
      )}
    </FormProvider>
  );
};

export default PurchaseOrderContainer;
