import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { IAccessPermission } from "types/Auth/role";

export const useQuotationOption = (
  status?: string | null,
  disabled?: boolean,
  quotationPermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        quotationPermissions?.update && {
          value: "แก้ไข",
          disabled: !disabled || false,
        },
        quotationPermissions?.create && {
          value: "คัดลอก",
          disabled: false,
        },
        quotationPermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
        quotationPermissions?.update && {
          value: "ไม่ตอบรับใบเสนอราคา",
          disabled: false,
        },
      ] as IMenuOption[],
    [disabled, quotationPermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "not_accepted":
        setSelectedModifyOptions(
          [defaultOptions[1], defaultOptions[2]].filter(Boolean)
        ); // คัดลอก, ยกเลิก
        break;
      case "cancelled":
      case "accepted":
      case "finished":
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
