import { yupResolver } from "@hookform/resolvers/yup";
import GoodsAdjustHeader from "components/Form/Inventory/GoodsAdjust/Header";
import {
  goodsAdjustSchema,
  goodsAdjustValidation,
} from "components/Form/Inventory/GoodsAdjust/schema";
import {
  CreateGoodAdjustInput,
  GoodAdjustFindUniqueQuery,
  InventoryControlDocumentType,
  ItemSkuQtysQuery,
  UpdateGoodAdjustInput,
  useGoodAdjustCreateMutation,
  useGoodAdjustFindUniqueQuery,
  useGoodAdjustUpdateMutation,
  useInventoryDocumentCancelMutation,
  useItemSkuQtysQuery,
} from "generated/wms";
import { useDisable } from "hooks/use-disable";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";
import {
  goodsAdjustCreatePayloadFormatter,
  goodsAdjustQueryFormatter,
  goodsAdjustUpdatePayloadFormatter,
} from "utils/Formatter/Inventory/GoodsAdjust";
import GoodsAdjustHeaderBreadcrumbs from "components/Form/Inventory/InventoryControl/HeaderBreadcrumbs";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import CustomizedButton from "components/Custom/CustomizedButton";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { Box, CircularProgress, Stack } from "@mui/material";
import GoodsAdjustInfo from "components/Form/Inventory/GoodsAdjust/Info";
import GoodsAdjustItemList from "components/Form/Inventory/GoodsAdjust/ItemList";
import { ActivityLogDocumentType, ActivityType } from "generated/general";
import { useActivityLog } from "hooks/use-activity-log";
import { useStateContext } from "contexts/auth-context";
import { useInventoryError } from "hooks/Inventory/use-inventory-error";
import { errorMessageFormatter } from "utils/Global";

const GoodsAdjustContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDisabled] = useDisable();
  const [barcodeList, setBarcodeList] = useState<any[]>([]);
  const methods = useForm<IGoodsAdjust>({
    defaultValues: goodsAdjustSchema,
    resolver: yupResolver<any>(goodsAdjustValidation),
  });
  const {
    state: { authUser, permissions },
  } = useStateContext();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
    reset,
    watch,
  } = methods;

  const { createActivityLog } = useActivityLog();

  const status = watch("aggrid_status");

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  useEffect(() => {
    if (
      id &&
      status &&
      (status === "finished" ||
        status === "cancelled" ||
        !permissions?.goods_adjust?.update)
    ) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status, permissions]);

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading, refetch, isSuccess } =
    useGoodAdjustFindUniqueQuery<GoodAdjustFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  const isFreezeStockQty = ["finished", "cancelled"].includes(status || "");

  const { data: allSkuQtys, isSuccess: isSkuSuccess } =
    useItemSkuQtysQuery<ItemSkuQtysQuery>(
      graphQLClient,
      {
        findManyInput: {
          where: {
            barcode: {
              in: barcodeList,
            },
          },
        },
      },
      {
        enabled: barcodeList.length > 0 && !isFreezeStockQty,
      }
    );

  useEffect(() => {
    if (id && isSuccess) {
      const { GoodAdjustFindUnique: GoodsAdjust } = data;
      const traceEntryList = GoodsAdjust?.trace_entry_list;
      const allBarcode = traceEntryList?.map((trace) => trace?.barcode) || [];
      setBarcodeList(allBarcode);

      const goodsAdjustType = GoodsAdjust as IGoodsAdjust;

      const formatGoodsAdjust = goodsAdjustQueryFormatter(
        goodsAdjustType,
        allSkuQtys
      );

      reset(formatGoodsAdjust);
    }
  }, [data, id, isSuccess, reset, isSkuSuccess, allSkuQtys]);

  const { mutateAsync: createGoodsAdjust, isLoading: isCreating } =
    useGoodAdjustCreateMutation<Error>(graphQLClient);

  const { mutateAsync: updateGoodsAdjust, isLoading: isUpdating } =
    useGoodAdjustUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancelGoodsAdjust, isLoading: isCancelling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const draftHandler = async (data: IGoodsAdjust) => {
    try {
      if (!id) {
        const payload = goodsAdjustCreatePayloadFormatter(
          data,
          "draft"
        ) as CreateGoodAdjustInput;
        const { GoodAdjustCreate } = await createGoodsAdjust({
          createGoodAdjustInput: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: GoodAdjustCreate.id as number,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
        navigate(`/inventory/goods-adjust/${GoodAdjustCreate.id}`);
      } else {
        const payload = goodsAdjustUpdatePayloadFormatter(
          data,
          "draft",
          authUser
        ) as UpdateGoodAdjustInput;
        const { GoodAdjustUpdate } = await updateGoodsAdjust({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateGoodAdjustInput: payload,
        });

        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: GoodAdjustUpdate.id as number,
          activity_detail: {},
        });
        await refetch();
      }

      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const adjustHandler = async (data: IGoodsAdjust) => {
    try {
      if (!id) {
        const payload = goodsAdjustCreatePayloadFormatter(
          data,
          "finished"
        ) as CreateGoodAdjustInput;
        const { GoodAdjustCreate } = await createGoodsAdjust({
          createGoodAdjustInput: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: GoodAdjustCreate.id as number,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "finished",
          },
        });
        navigate(`/inventory/goods-adjust/${GoodAdjustCreate.id}`);
      } else {
        const payload = goodsAdjustUpdatePayloadFormatter(
          data,
          "finished",
          authUser
        ) as UpdateGoodAdjustInput;
        const { GoodAdjustUpdate } = await updateGoodsAdjust({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateGoodAdjustInput: payload,
        });

        await refetch();

        const isDirty = Object.keys(dirtyFields)?.length > 0;

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: GoodAdjustUpdate.id as number,
          activity_detail: {
            secondary_operation: isDirty ? ActivityType.Edit : undefined,
            prev_status: status,
            curr_status: "finished",
          },
        });
      }
      enqueueSnackbar(`${t("inventory.goods_adjust.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(
        formatError || `${t("inventory.goods_adjust.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const cancelHandler = async () => {
    try {
      await cancelGoodsAdjust({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryControlDocumentType.GoodAdjust,
      });

      await refetch();
      enqueueSnackbar(
        `${t("button.cancel")}${t("inventory.goods_adjust.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsAdjustment,
        reference_id: id ? parseInt(id) : 0,
        activity_detail: {
          prev_status: status,
          curr_status: "cancelled",
        },
      });
    } catch (err) {
      enqueueSnackbar(
        `${t("button.cancel")}${t("inventory.goods_adjust.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const isMutating = isCreating || isUpdating || isCancelling;

  useInventoryError(errors);

  if (id && (isLoading || isMutating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (permissions && !permissions.goods_adjust?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <FormProvider {...methods}>
      <GoodsAdjustHeaderBreadcrumbs
        type={InventoryControlDocumentType.GoodAdjust}
      />
      <GoodsAdjustHeader cancelHandler={cancelHandler} />
      <GoodsAdjustInfo replace={replace} />
      <GoodsAdjustItemList
        fields={fields}
        append={append}
        remove={remove}
        update={update}
      />
      <BottomNavbar>
        {((!status && permissions?.goods_adjust?.create) ||
          (status !== "finished" &&
            status !== "cancelled" &&
            permissions?.goods_adjust?.update)) && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(draftHandler)}
              disabled={isMutating}
            />
            <CustomizedButton
              variant="contained"
              title={t("inventory.goods_adjust.index")}
              onClick={handleSubmit(adjustHandler)}
              disabled={isMutating}
            />
          </Stack>
        )}
      </BottomNavbar>
    </FormProvider>
  );
};

export default GoodsAdjustContainer;
