import dayjs from "dayjs";
import { FieldArrayWithId, UseFormReset } from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";
import { useTireListHeader } from "hooks/Logistic/use-tire-list-table-header";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { IVehicle } from "types/Logistic/vehicle";
import { formatNumber } from "utils/Global";

interface Props {
  fields: FieldArrayWithId<IVehicle, "main_tire" | "spare_tire", "id">[];
  handlerRemoveModal: () => void;
  reset: UseFormReset<any>;
}

const TireList = ({ fields, handlerRemoveModal, reset }: Props) => {
  const [disabled] = useDisable();
  const headers = useTireListHeader(disabled);

  return (
    <Box sx={{ breakAfter: "auto" }} maxWidth={1650} my={2}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    px: 1,
                    py: 1.5,
                    backgroundColor: "secondary.main",
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: header.align || "center",
                      padding: "none",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      {header.label}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {fields.map((field, index) => (
              <TableRow
                key={index}
                sx={{
                  breakInside: "avoid",
                }}
              >
                <TableCell align="left">{field.serial || "-"}</TableCell>
                <TableCell align="left">{field.band || "-"}</TableCell>
                <TableCell align="left">{field.model || "-"}</TableCell>
                <TableCell align="left">
                  {field.position === "spare" ? "สำรอง" : field.position}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ paddingRight: "1rem !important" }}
                >
                  {formatNumber(field.current_distance, true)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ paddingRight: "1rem !important" }}
                >
                  {formatNumber(field.due_distance, true)}
                </TableCell>
                {!disabled && (
                  <TableCell
                    align="center"
                    sx={{
                      px: "2rem",
                      py: "1.5rem",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                      onClick={() => {
                        reset({
                          ...field,
                          action_date: dayjs(),
                          remark: "",
                          index,
                        });
                        handlerRemoveModal();
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TireList;
