import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useStateContext } from "contexts/auth-context";
import { IBreadcrumbsAndMenu } from "types/global";
import {
  RoleFindUniqueQuery,
  useRoleFindUniqueQuery,
  ActivityType,
  ActivityLogDocumentType,
  useRoleUpdateMutation,
} from "generated/general";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { IRole } from "types/Auth/role";

import { useDisable } from "hooks/use-disable";
import { useActivityLog } from "hooks/use-activity-log";
import { ActivityLog } from "components/UI/ActivityLog";
import { roleUpdateFormatter } from "utils/Formatter/Role";

import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import CustomizedChips from "components/Custom/CustomizedChips";
import CustomizedButton from "components/Custom/CustomizedButton";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import LoadingUI from "components/UI/LoadingUI";
import RoleDetail from "components/Form/User/Role/Detail";
import RolePermissions from "components/Form/User/Role/Permissions";
import RoleEmployee from "components/Form/User/Role/Employee";

const RoleContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [disabled, setDisabled] = useDisable();
  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IRole>({
    defaultValues: {},
    // resolver: yupResolver<any>(roleValidation),
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = methods;

  const {
    openActivityLog,
    onActivityLogOpen,
    onActivityLogClose,
    createActivityLog,
  } = useActivityLog();

  const { data, isLoading, isSuccess, refetch } =
    useRoleFindUniqueQuery<RoleFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { mutateAsync: update, isLoading: isUpdating } =
    useRoleUpdateMutation<Error>(graphQLClient);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
      to: "/user/role",
    },
    {
      name: id ? data?.RoleFindUnique?.unique_id || "-" : "-",
    },
  ];

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: !disabled,
    },
  ];

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    if (isSuccess) {
      const { RoleFindUnique } = data;

      const getQuotationData = async () => {
        const formatted = RoleFindUnique ?? {};
        reset(formatted);
      };
      getQuotationData();
    }
  }, [data, isSuccess, reset]);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  const roleUpdateHandler = async (data: IRole) => {
    try {
      const formattedData = roleUpdateFormatter(data);

      const { RoleUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: {
          ...formattedData,
        },
      });

      await refetch();

      if (isDirty && Object.keys(dirtyFields).length > 0) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.Role,
          reference_id: RoleUpdate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      }
      enqueueSnackbar(`${t("user.rbac.edit")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("user.rbac.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
    setDisabled(true);
  };

  if (permissions && !permissions.role?.view) {
    return <Navigate replace to={"/unauthorized"} />;
  }

  if (id && isLoading) {
    return <LoadingUI />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <BreadcrumbsLayout
          breadcrumbs={breadcrumbs}
          onActivityLogOpen={onActivityLogOpen}
        />
        <Box
          display="flex"
          justifyContent={"space-between"}
          maxWidth={1040}
          alignItems={"center"}
          mt={2}
        >
          <Typography variant="h5">{t("user.rbac.index")}</Typography>
          <CustomizedMenuOptions
            size="medium"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "แก้ไข":
                  setDisabled(false);
                  break;
                default:
                  break;
              }
            }}
            disabled={!id}
          />
        </Box>
        <Box sx={{ display: "inline-block", mr: 1, mt: 2 }}>
          <CustomizedChips
            variant="filled"
            sx={{
              backgroundColor: "#F2F2F2",
              color: "#737373",
            }}
            label={
              <>
                <span
                  style={{
                    color: "#333333",
                    fontWeight: 600,
                  }}
                >
                  {t("user.rbac.role_id")}
                </span>{" "}
                {data?.RoleFindUnique?.unique_id ?? "-"}
              </>
            }
          />
        </Box>
        <RoleDetail />
        <RolePermissions />
        <RoleEmployee />
        {!disabled && (
          <BottomNavbar>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => {
                  editCancelHandler();
                }}
                disabled={isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.confirm")}
                onClick={handleSubmit(roleUpdateHandler)}
                disabled={isUpdating}
              />
            </Stack>
          </BottomNavbar>
        )}
        {id && (
          <ActivityLog
            open={openActivityLog}
            onClose={onActivityLogClose}
            documentId={id!}
            documentType={ActivityLogDocumentType.Role}
          />
        )}
      </FormProvider>
    </>
  );
};

export default RoleContainer;
