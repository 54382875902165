import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "types/global";

import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import CustomizedTab from "components/Custom/CustomizedTab";
import VehicleTable from "components/Table/Logistic/Vehicle";
import HeaderLayout from "components/UI/HeaderLayout";
import ExporterVehicleModal from "components/UI/Modal/ExporterVehicleModal";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { enqueueSnackbar } from "notistack";
import LoadingAnimation from "components/Animation/Loading";
import {
  useVehicleGenerateExportQuery,
  VehicleGenerateExportInput,
  VehicleGenerateExportQuery,
  VehicleTypeInput,
} from "generated/vehicle";

const Vehicle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const typeFilter = searchParams.get("type");
  const {
    modal: exportModal,
    openModalHandler: openExportModalHandler,
    closeModalHandler: closeExportModalHandler,
  } = useModal();
  const {
    state: { permissions },
  } = useStateContext();

  const vehicleTypeOptions = [
    {
      label: "ทั้งหมด",
      value: "default",
    },
    {
      label: "รถทั้งหมด",
      value: "all_vehicle",
    },
    {
      label: "หางลาก",
      value: "trailers",
    },
    {
      label: "4 ล้อ",
      value: "four_wheels",
    },
    {
      label: "6 ล้อ",
      value: "six_wheels",
    },
    {
      label: "10 ล้อ",
      value: "ten_wheels",
    },
    {
      label: "หัวลาก",
      value: "head",
    },
    {
      label: "ใกล้ครบกำหนด",
      value: "approaching",
    },
    {
      label: "เกินกำหนด",
      value: "overdue",
    },
  ];

  const options: IMenuOption[] = [
    {
      value: t("purchase.export"),
    },
  ];

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.vehicle.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("logistic.vehicle.index"),
      path: `${pathname}?type=head`,
    },
    {
      label: t("logistic.vehicle.tail"),
      path: `${pathname}?type=trailer`,
    },
    {
      label: t("logistic.vehicle.approaching"),
      path: `${pathname}?type=approaching`,
    },
    {
      label: t("logistic.vehicle.overdue"),
      path: `${pathname}?type=overdue`,
      errors: true,
    },
  ];

  const currentTab = pathname + (typeFilter ? `?type=${typeFilter}` : "");

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const [exportInput, setExportInput] =
    useState<VehicleGenerateExportInput | null>(null);

  const { refetch: refetchData, isLoading: isExporting } =
    useVehicleGenerateExportQuery<VehicleGenerateExportQuery>(
      graphQLClientWithHeader,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: any) => {
    if (data) {
      const { type } = data;
      setExportInput({
        vehicle_type: type as VehicleTypeInput,
      });
    } else {
      enqueueSnackbar("Export data is required", { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        if (exportInput) {
          const { data: exportData } = await refetchData();

          if (exportData?.VehicleGenerateExport) {
            const url = exportData.VehicleGenerateExport;
            window.open(url, "_blank");
            enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
              variant: "success",
            });
          } else {
            throw new Error("No data received");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [exportInput, refetchData, t]);

  if (permissions && !permissions.vehicle?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (exportInput && isExporting) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("logistic.vehicle.index")}</Typography>
        <Box display="flex">
          <Box ml="auto">
            {permissions?.vehicle?.create && (
              <CustomizedButton
                title={`${t("button.create")}${t("logistic.vehicle.index")}`}
                variant="contained"
                onClick={() => navigate(`${pathname}/add`)}
                startAddIcon
              />
            )}
            {permissions?.vehicle?.export && (
              <CustomizedMenuOptionsMeatball
                isIcon
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("purchase.export")}`:
                      openExportModalHandler();
                      break;
                    default:
                  }
                }}
                sx={{
                  width: "46px",
                  minWidth: "46px",
                }}
                disableRipple={true}
              />
            )}
          </Box>
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <VehicleTable type={typeFilter} />

      <ExporterVehicleModal
        open={exportModal}
        closeModalHandler={closeExportModalHandler}
        typeOptions={vehicleTypeOptions}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default Vehicle;
