import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import { useStateContext } from "contexts/auth-context";
import { Navigate } from "react-router-dom";

const QuotationReports = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.quotation.index"),
      to: "quotation",
    },
  ];

  if (permissions && !permissions.sales_export?.view) {
    return <Navigate to="/unauthorized" replace />;
  }
  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default QuotationReports;
