import { Box, Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedTab from "components/Custom/CustomizedTab";
import MaintenanceTable from "components/Table/Logistic/Maintenance";
import HeaderLayout from "components/UI/HeaderLayout";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import {
  IBreadcrumbsAndMenu,
  ITab,
  IMenuOption,
  IExporter,
} from "types/global";
import { useModal } from "hooks/use-modal";
import ExporterMaintenanceModal from "components/UI/Modal/ExporterMaintenanceModal";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  useGenerateMaintenanceExportQuery,
  GenerateMaintenanceExportQuery,
  GeneralExportInput,
} from "generated/sales";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

const options: IMenuOption[] = [
  {
    value: "นำออกใบแจ้งซ่อม",
  },
];

const Maintenance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get("filter");
  const {
    state: { permissions },
  } = useStateContext();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const [exportInput, setExportInput] = useState<GeneralExportInput | null>(
    null
  );

  const { refetch: refetchData } =
    useGenerateMaintenanceExportQuery<GenerateMaintenanceExportQuery>(
      graphqlClient,
      {
        exportInput: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { start_date, end_date } = data;
      setExportInput({
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        if (exportInput) {
          const { data: exportData } = await refetchData();
          if (exportData?.GenerateMaintenanceExport) {
            const url = exportData.GenerateMaintenanceExport;
            window.open(url, "_blank");
            enqueueSnackbar(`นำออกไฟล์ใบแจ้งซ่อมสำเร็จ`, {
              variant: "success",
            });
          } else {
            throw new Error("No data received");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`นำออกไฟล์ใบแจ้งซ่อมไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.maintenance.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_maintenance"),
      path: `${pathname}?filter=wait_maintenance`,
    },
    {
      label: t("status.maintenancing"),
      path: `${pathname}?filter=maintenancing`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (statusFilter ? `?filter=${statusFilter}` : "");

  if (permissions && !permissions.maintenance?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("logistic.maintenance.index")}</Typography>
        <Box display="flex" alignItems="center">
          <Box ml="auto">
            {permissions?.maintenance?.create && (
              <CustomizedButton
                startAddIcon
                title={`${t("button.create")}${t(
                  "logistic.maintenance.index"
                )}`}
                variant="contained"
                onClick={() => navigate(`/logistic/maintenance/add`)}
              />
            )}
            {permissions?.maintenance?.export && (
              <CustomizedMenuOptionsMeatball
                isIcon
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "นำออกใบแจ้งซ่อม":
                      openModalHandler();
                      break;
                    default:
                  }
                }}
                sx={{
                  width: "44px",
                  minWidth: "44px",
                  height: "33px",
                  minHeight: "33px",
                }}
                disableRipple={true}
              />
            )}
          </Box>
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <MaintenanceTable status={statusFilter} />
      <ExporterMaintenanceModal
        open={modal}
        closeModalHandler={closeModalHandler}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default Maintenance;
