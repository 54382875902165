import { useStateContext } from "contexts/auth-context";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import InventoryDashboard from "components/UI/Dashboard/InventoryDashboard";
import LogisticDashboard from "components/UI/Dashboard/LogisticDashboard";
import PurchaseDashboard from "components/UI/Dashboard/PurchaseDashboard";
import SalesDashboard from "components/UI/Dashboard/SalesDashboard";
import { IBreadcrumbsAndMenu } from "types/global";

const Dashboard = () => {
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: "หน้าแรก",
    },
  ];
  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      {permissions?.logistic?.view && <LogisticDashboard />}
      {permissions?.sales?.view && <SalesDashboard />}
      {permissions?.inventory?.view && <InventoryDashboard />}
      {permissions?.purchase?.view && <PurchaseDashboard />}
    </>
  );
};

export default Dashboard;
