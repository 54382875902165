import dayjs from "dayjs";
import * as Yup from "yup";
import { stringMaxInput } from "utils/Validate";
import { IMaintenance } from "types/Logistic/maintenance";
import {
  MaintenanceDiscountType,
  MaintenanceType,
  PriceVatType,
} from "generated/sales";
import { itemListSchema } from "components/Form/Purchase/ItemListSchema";

export const maintenanceSchema: IMaintenance = {
  unique_id: "",
  type: MaintenanceType.Normal,
  reference_no: "",
  created_by: null,
  last_updated_by: null,
  driver_list: [],
  created_date: dayjs(),
  issue_date: dayjs(),
  ma_start_date: dayjs(),
  ma_end_date: dayjs(),
  vehicle_details: {
    vehicle_no: "",
    license_plate: "",
    brand: "",
    model: "",
    current_kilometer: "",
  },
  has_trailer: false,
  ma_details: {
    garage_type: "อู่ภายใน",
    garage_name: "",
    billing_address: {
      name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  price_vat_type: PriceVatType.ExcludedVat,
  ma_type: [],
  ma_list: [],
  ma_item_list: [],
  pre_vat_amount: 0,
  additional_discount: 0,
  additional_discount_type: MaintenanceDiscountType.Baht,
  pre_discount_amount: 0,
  post_discount_amount: 0,
  vat_exempted_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  net_amount: 0,
  withholding_tax_amount: 0,
  total_amount: 0,
  attachment_list: [],
  claim_attachment_list: [],
  main_status: "",
  sub_status: "",
  flag_status: [],
  aggrid_status: "",
  reference_document_list: [],
};

const yupNumber = Yup.number().transform((value) =>
  isNaN(value) || value === null || value === undefined ? 0 : value
);

export const maintenanceValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true),
  reference_no: stringMaxInput(200, undefined, false),
  issue_date: Yup.date().required("กรุณาระบุ"),
  type: Yup.string().required("กรุณาระบุ"),
  vehicle_id: yupNumber.required("กรุณาระบุ"),
  vehicle_details: Yup.object().shape({
    vehicle_no: Yup.string().required("กรุณาระบุ"),
    license_plate: Yup.string().required("กรุณาระบุ"),
    current_kilometer: yupNumber
      .min(0, "กรุณาระบุข้อมูลให้ถูกต้อง")
      .required("กรุณาระบุ"),
  }),
  trailer_id: yupNumber.when("has_trailer", {
    is: true,
    then: (schema) => schema.required("กรุณาระบุ"),
    otherwise: (schema) => schema.notRequired(),
  }),
  trailer_details: Yup.object().when("has_trailer", {
    is: true,
    then: (schema) =>
      schema.shape({
        vehicle_no: Yup.string().required("กรุณาระบุ"),
        license_plate: Yup.string().required("กรุณาระบุ"),
        current_kilometer: yupNumber
          .min(0, "กรุณาระบุข้อมูลให้ถูกต้อง")
          .required("กรุณาระบุ"),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  main_status: Yup.string(),
  aggrid_status: Yup.string(),
  ma_start_date: Yup.date().required("กรุณาระบุ"),
  ma_end_date: Yup.date()
    .required("กรุณาระบุ")
    .min(Yup.ref("ma_start_date"), "กรุณาระบุข้อมูลให้ถูกต้อง"),
  ma_details: Yup.object().shape({
    garage_type: Yup.string().required("กรุณาระบุ"),
    garage_name: Yup.string().required("กรุณาระบุ"),
  }),
  ma_item_list: Yup.array().of(itemListSchema),
  additional_discount: yupNumber,
  pre_discount_amount: yupNumber,
  post_discount_amount: yupNumber,
  vat_exempted_amount: yupNumber,
  vat_0_percent_amount: yupNumber,
  vat_7_percent_amount: yupNumber,
  vat_amount: yupNumber,
  net_amount: yupNumber,
  withholding_tax_amount: yupNumber,
  total_amount: yupNumber,
});
