import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { IAccessPermission } from "types/Auth/role";

export const useBillingNoteOption = (
  status?: string | null,
  disabled?: boolean,
  billingNotePermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        billingNotePermissions?.update && {
          value: "แก้ไข",
          disabled: !disabled || false,
        },
        billingNotePermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
      ] as IMenuOption[],
    [disabled, billingNotePermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); //  ยกเลิก
        break;
      case "cancelled":
      case "fully_payment":
        setSelectedModifyOptions([]); // none
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
