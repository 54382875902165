import { useTranslation } from "react-i18next";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import { useFormContext } from "react-hook-form";
import { IRole } from "types/Auth/role";
import { useDisable } from "hooks/use-disable";

const RoleDetail = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IRole>();
  const [disabled] = useDisable();

  return (
    <CustomizedBox>
      <Typography fontSize={16} fontWeight={600} mb={1}>
        {t("user.rbac.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="name"
            label={t("user.rbac.name")}
            viewMode={disabled}
            disabled
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="description"
            label={t("user.rbac.description")}
            viewMode={disabled}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default RoleDetail;
